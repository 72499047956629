import type { ComputedRef } from "vue";
import { computed, reactive, ref } from "vue";
import type { Activity, HydraItem, Report, Task, User } from "@verbleif/lib";
import { ActivityChangeTypeEnum, ActivityOriginContextEnum, ActivityTypeEnum, useMercure, useModal } from "@verbleif/lib";
import { UserRole, useAuthStore, useConfigStore, useLocationStore } from "@verbleif/shared";
import { useRightsStore } from "@/core/store/RightStore";
import api from "@/core/api";
import refreshToken from "@/core/refreshToken";

interface ActivitiesOptions {
  type?: ActivityEntityType | null
  item?: HydraItem<Report> | HydraItem<Task> | null
}

export enum ActivityEntityType {
  TASK = 1,
  REPORT = 2,
}

export function useActivities({ type = null, item = null }: ActivitiesOptions) {
  const items = reactive<Record<string, HydraItem<Activity>>>({});
  const queued = ref<Activity[]>([]);
  const authStore = useAuthStore();
  const locationStore = useLocationStore();
  const { hasRole } = useRightsStore();
  const configStore = useConfigStore();
  const modalManager = useModal();

  const url: ComputedRef<string> = computed(() => {
    if (!item || !("id" in item)) {
      return "";
    }

    switch (type) {
      case ActivityEntityType.TASK:
        return `/api/v1/tasks/${item.id}/activities`;
      case ActivityEntityType.REPORT:
        return `/api/v1/reports/${item.id}/activities`;
      default:
        return "";
    }
  });

  if (item) {
    const encoded = encodeURIComponent(`${url.value}/{id}`);

    if (!authStore.user.value) {
      throw new Error("Trying to initialize mercure while no user is authenticated.");
    }

    let topic = `/locations/${locationStore.getSelectedLocationId.value}/users/${authStore.user.value.id}/?topic=${encoded}`;
    if (hasRole(UserRole.ROLE_FRONT_DESK)) {
      topic = `/locations/${locationStore.getSelectedLocationId.value}/?topic=${encoded}`;
    }

    const mercure = useMercure<Activity>({
      hubUrlGetter: () => configStore.config.value?.mercureUrl || "",
      refreshToken,
      tokenGetter: () => authStore.token.value || "",
      topic,
      onAddOrUpdate: (activity) => {
        items[activity["@id"]] = activity;
      },
      onDelete: (activity) => {
        items[activity["@id"]] = {
          ...items[activity["@id"]],
          deletedAt: (new Date()).toISOString(),
        };
      },
    });

    // When modal closed, stop connection.
    watch(modalManager.visible, (visible) => {
      if (visible) {
        return;
      }

      mercure.close();
    });
  }

  const hasItems = computed(() => {
    return !!Object.keys(items).length;
  });

  function groupBy(array: Array<object>, key: string) {
    return array.reduce((objectsByKeyValue: Record<string, any>, obj: Record<string, any>) => {
      const dateResettedTime = getDateResettedTime(obj[key]);
      objectsByKeyValue[dateResettedTime] = (
        objectsByKeyValue[dateResettedTime] || []
      ).concat(obj);
      return objectsByKeyValue;
    }, {});
  }

  const grouped = computed(() => {
    const result: Record<string, any> = groupBy(Object.values(items), "createdAt");
    if (Object.keys(result).length === 0) {
      const now = new Date();
      const resettedDate = getDateResettedTime(now.toISOString());
      result[resettedDate] = [];
    }
    return result;
  });

  function getDateResettedTime(isoString: string) {
    const date = new Date(isoString);
    date.setUTCHours(0);
    date.setUTCMinutes(0);
    date.setUTCSeconds(0);
    date.setUTCMilliseconds(0);
    return date.toISOString();
  }

  function setParams(params: URLSearchParams): void {
    params.set("deletedAt", ""); // Hack to also get deleted items
    params.set("order[createdAt]", "ASC");
  }

  function commentCreated({ index, activity }: { index: number; activity: HydraItem<Activity> }) {
    queued.value.splice(index, 1);
    items[activity["@id"]] = activity;
  }

  async function queuePostComment(message: string) {
    if (!authStore.user.value) {
      throw new Error("No authenticated user");
    }

    const now = new Date().toISOString();
    const activity: Activity = {
      id: new Date().getTime(),
      type: ActivityTypeEnum.TYPE_COMMENT,
      originContext: ActivityOriginContextEnum.WEB,
      changeType: ActivityChangeTypeEnum.CREATED,
      location: locationStore.selectedLocation.value,
      task: null,
      report: null,
      createdBy: authStore.user.value as HydraItem<User>,
      createdAt: now,
      updatedAt: now,
      deletedAt: null,
      meta: {
        message,
      },
    };

    if (item) {
      switch (type) {
        case ActivityEntityType.TASK:
          activity.task = item["@id"];
          break;
        case ActivityEntityType.REPORT:
          activity.report = item["@id"];
          break;
        default:
          break;
      }
    }

    queued.value.push(activity);
  }

  function updateQueued(index: number, activity: Activity) {
    queued.value.splice(index, 1, activity);
  }

  function removeQueued(index: number) {
    queued.value.splice(index, 1);
  }

  function setItems(inputItems: (HydraItem<Activity>)[]) {
    inputItems.forEach((r) => {
      items[r["@id"]] = r;
    });
  }

  async function getActivities(options: Record<string, any> = {}) {
    return api.get(url.value, options);
  }

  function optimisticUpdateActivity(activity: HydraItem<Activity>) {
    if (!items?.[activity["@id"]]) {
      return;
    }
    Object.assign(items[activity["@id"]], activity);
  }

  return {
    setItems,
    setParams,
    commentCreated,
    queuePostComment,
    getActivities,
    optimisticUpdateActivity,
    updateQueued,
    removeQueued,
    hasItems,
    grouped,
    queued,
    url,
  };
}
