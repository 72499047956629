import { ref } from "vue";

function useImagePreviewer() {
  const imageIndex = ref(0);
  const lightboxVisible = ref(false);
  function showImg(index) {
    imageIndex.value = index;
    lightboxVisible.value = true;
  }
  function handleHide() {
    lightboxVisible.value = false;
  }
  return {
    imageIndex,
    lightboxVisible,
    showImg,
    handleHide,
  };
}
export default useImagePreviewer;
