import { useI18n } from "vue-i18n";
import { useToast } from "vue-toastification";
import * as Sentry from "@sentry/vue";
import { api } from "@/core/api";

export function useCreateTask({
  component,
  endpoint,
  translationKey,
  modalManager,
  load = () => {},
  transformer = async input => input,
}) {
  const { t } = useI18n();
  const { success, error } = useToast();

  function showCreate() {
    modalManager.open({
      component,
      opts: {
        okIcon: ["fas", "plus"],
        okText: t("base.create"),
        cancelText: t("base.cancel"),
        onOk: input => create(input),
      },
    });
  }

  async function createComments({ taskIri, activities }) {
    for (const activity of activities) {
      const clone = { ...activity };
      delete clone.id;
      await api
        .post("/activities", { ...clone, task: taskIri })
        .catch(e => console.error(e));
    }
  }

  async function create(input) {
    modalManager.setLoading(true);

    const output = await transformer(input);
    let toastId = "1";
    return api
      .post(endpoint, output)
      .then(async (r) => {
        await createComments({
          taskIri: r.data["@id"],
          activities: input.activities || [],
        });
        toastId = success(t(`${translationKey}.create_success`));
        modalManager.close();
        load();
      })
      .catch((e) => {
        let msg = "base.unknown_error";
        if (e.response) {
          msg = `base.${e.response.status}`;
        }
        error(t(msg, { errorCode: 0 }), { id: toastId });
        Sentry.setContext("error", e);
        Sentry.captureException(e);
      })
      .finally(() => {
        modalManager.setLoading(false);
      });
  }

  return {
    showCreate,
    create,
  };
}

export default useCreateTask;
