<script>
export default {
  name: "ActionsList",
};
</script>

<template>
  <VPopper
    root-class="is-default"
    trigger="clickToToggle"
    stop-propagation
    append-to-body
    visible-arrow
    :options="{ placement: 'bottom' }"
    transition="fade"
    singleton-group="report-item"
  >
    <div class="options">
      <slot />
    </div>
    <template #reference>
      <FontAwesomeIcon class="options-trigger" icon="ellipsis-h" />
    </template>
  </VPopper>
</template>

<style lang="scss" scoped>
.options-trigger {
  color: var(--grey-3);
  cursor: pointer;
}
</style>
