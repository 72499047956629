import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16",
  viewBox: "0 0 512 512"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#408EC6",
      "fill-rule": "nonzero",
      d: "M256 0C114.58 0 0 114.58 0 256s114.58 256 256 256 256-114.58 256-256S397.42 0 256 0m95.474 323.097-20.645 25.806c-3.029 3.786-7.686 6.212-12.905 6.212a16.47 16.47 0 0 1-10.311-3.631l-69.161-51.324c-9.454-7.57-15.484-19.196-15.484-32.238V107.355c0-9.116 7.4-16.516 16.516-16.516h33.032c9.116 0 16.516 7.4 16.516 16.516V256l59.871 43.871c3.784 3.027 6.21 7.684 6.21 12.903 0 3.902-1.373 7.495-3.64 10.323"
    }, null, -1)
  ])))
}
export default { render: render }