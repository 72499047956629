<script>
import { useStringToHsl } from "@verbleif/lib";
import Avatar from "@/features/Avatar/Avatar.vue";

export default {
  name: "AvatarWithName",
  components: { Avatar },
  props: {
    name: {
      type: String,
      default: "",
    },
    subtext: {
      type: String,
      default: "",
    },
    active: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: [Array, String, Boolean],
      default: false,
    },
    inactiveIcon: {
      type: [Array, String, Boolean],
      default: false,
    },
    size: {
      type: String,
      default: "is-large",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    avatarIri: {
      type: [Boolean, String],
      default: false,
    },
  },
  setup() {
    const { stringToHslColor } = useStringToHsl();

    return {
      stringToHslColor,
    };
  },
};
</script>

<template>
  <div class="avatar-with-name-container">
    <Avatar :loading="loading" v-bind="$props" />
    <span v-if="loading" class="text-wrapper">
      <VSkeleton :height="16" />
    </span>
    <div v-else class="text-wrapper">
      <span class="text">{{ name }}</span>
      <span v-if="subtext !== ''" class="subtext">{{ subtext }}</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.avatar-with-name-container {
  display: flex;
  align-items: center;
  width: 100%;
  color: var(--text);

  .text-wrapper {
    flex: 0 2 100%;
    margin-left: 10px;
    display: flex;
    align-items: center;
    flex-direction: row;

    .text {
      font-size: 13px;
      margin-right: 10px;
    }

    .subtext {
      margin-right: 0;
      margin-left: auto;
      line-height: 5px;
      font-size: 10px;
      text-transform: uppercase;
      color: #c5c5c5;
    }
  }
}
</style>
