<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useFilters } from "@/features/Tasks/Overview/useFilters";

const { t } = useI18n();
const {
  conditionHasRightHand,
  getConditionValuesByLeftHandKey,
  getPresetByLeftHandKey,
  onUpdateLeftHand,
  filterItemPresets,
  removeFilter,
  state,
  isFilterActive,
  addFilter,
  countFilters,
} = useFilters();
</script>

<template>
  <VPopper
    tag="div"
    class="overview-settings"
    root-class="is-default"
    trigger="clickToToggle"
    transition="fade"
    visible-arrow
    :options="{ placement: 'bottom-start' }"
  >
    <div class="filters">
      <div class="title">
        {{ $t('tasks.filters.title') }}
      </div>
      <div class="items">
        <div
          v-for="(filterItem, index) in state.filters"
          :key="index"
          class="item"
        >
          <VModernSelect
            v-model="filterItem.leftHand"
            has-new-style
            :placeholder="t('tasks.choose_filter')"
            key-field-name="leftHandKey"
            search-field-name="leftHandLabel"
            text-align="is-text-align-left"
            :items="filterItemPresets"
            @update:model-value="(v:any) => onUpdateLeftHand(index, v)"
          />
          <div class="condition">
            <VModernSelect
              v-if="typeof filterItem?.rightHand !== 'undefined' && filterItem.leftHand"
              v-model="filterItem.condition"
              has-new-style
              key-field-name="value"
              :placeholder="t('tasks.choose_filter')"
              text-align="is-text-align-left"
              :items="getConditionValuesByLeftHandKey(filterItem.leftHand)"
            />
            <VTag
              v-else
              class="no-filter"
              :text="t('tasks.choose_filter')"
              :closeable="false"
              variant="is-primary"
            />
          </div>
          <div v-if="filterItem.condition && typeof filterItem?.rightHand !== 'undefined' && filterItem.leftHand && conditionHasRightHand(filterItem.condition)" class="right-hand">
            <component
              v-bind="getPresetByLeftHandKey(filterItem.leftHand).rightHandComponentProps"
              :is="getPresetByLeftHandKey(filterItem.leftHand).rightHandComponent"
              v-model="filterItem.rightHand"
              :no-results-text="t('base.empty')"
              :no-options-text="t('base.empty')"
              :loading-text="t('base.loading')"
              :refresh-text="t('base.refresh')"
              :append-to-body="false"
              has-new-style
              text-align="is-text-align-left"
            />
          </div>
          <div>
            <FontAwesomeIcon
              icon="times"
              class="close"
              @click.stop.prevent="removeFilter(index)"
            />
          </div>
        </div>
      </div>
      <div>
        <span class="add_filter" @click="addFilter">
          + {{ $t('tasks.filters.add_filter') }}
        </span>
      </div>
    </div>
    <template #reference>
      <div class="filter-icon" :class="[{ filtered: isFilterActive }]">
        <FontAwesomeIcon class="settings" :icon="['fa', 'filter']" />
        <div v-if="countFilters" class="count">
          {{ countFilters }}
        </div>
        <div v-else class="text">
          {{ $t('tasks.filters.title') }}
        </div>
        <FontAwesomeIcon
          class="dropdown"
          :icon="['fa', 'caret-down']"
        />
      </div>
    </template>
  </VPopper>
</template>

<style lang="scss" scoped>
.filter-icon {
  height: 40px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  color: var(--text);
  margin-right: 15px;
  cursor: pointer;
  gap: 10px;
  padding: 10px;
  background: var(--input-background);

  .text {
    color: var(--input-placeholder);
  }

  .count {
    background: white;
    font-size: 12px;
    font-weight: bold;
    color: var(--primary);
    border-radius: 50px;
    padding: 2px 8px;
  }

  &.filtered {
    background: var(--primary);

    .dropdown {
      &:hover {
        color: white;
      }

      &.active {
        color: white;
      }
    }

    .dropdown,
    .settings {
      color: white;
    }
  }

  &:hover {
    .dropdown,
    .settings {
      opacity: 0.8;
    }
  }
}

.filters {
  display: flex;
  flex-direction: column;
  gap: 15px;
  min-height: 75px;
  min-width: 500px;
  padding: 13px; // Popper already has 7px padding default.

  .no-filter {
    opacity: 0.5;
  }

  .condition {
    display: flex;
    width: 100%;

    .input-wrapper {
      max-width: 150px;
    }
  }

  .right-hand {
    max-width: 400px;
  }

  .add_filter {
    margin-top: auto;
    color: #BDBDBD;

    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }

  .items {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    .item {
      display: grid;
      grid-template-columns: 1fr 150px 1fr 10px;
      grid-template-rows: repeat(auto-fill, auto);
      width: 100%;
      gap: 10px;
      align-items: center;

      &::v-deep(.input-wrapper) {
        flex: 1 1 35%;
      }
    }
  }

  .title {
    font-size: 18px !important;
    font-weight: bold;
    color: var(--text);
  }

  .buttons {
    display: flex;
  }

  .close {
    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }
}
</style>
