import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16",
  viewBox: "0 0 512 512"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      fill: "none",
      "fill-rule": "evenodd"
    }, [
      _createElementVNode("path", { d: "M0 0h512v512H0z" }),
      _createElementVNode("path", {
        fill: "#408EC6",
        "fill-rule": "nonzero",
        d: "M32 464c0 26.5 21.5 48 48 48h351.999c26.5 0 48-21.5 48-48V192H32zm320-196c0-6.6 5.4-12 12-12h40c6.599 0 12 5.4 12 12v40c0 6.6-5.401 12-12 12h-40c-6.6 0-12-5.4-12-12zm0 128c0-6.6 5.4-12 12-12h40c6.599 0 12 5.4 12 12v40c0 6.6-5.401 12-12 12h-40c-6.6 0-12-5.4-12-12zM224 268c0-6.6 5.4-12 12-12h40c6.599 0 12 5.4 12 12v40c0 6.6-5.401 12-12 12h-40c-6.6 0-12-5.4-12-12zm0 128c0-6.6 5.4-12 12-12h40c6.599 0 12 5.4 12 12v40c0 6.6-5.401 12-12 12h-40c-6.6 0-12-5.4-12-12zM96 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12zM431.999 64h-48V16.002c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H192V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H80C53.5 64 32 85.5 32 112v48h447.999v-48c0-26.5-21.5-48-48-48"
      })
    ], -1)
  ])))
}
export default { render: render }