<script setup lang="ts">
import { useI18n } from "vue-i18n";
import type { TaskStatus } from "@verbleif/lib";
import StatusItem from "@/features/Status/StatusItem.vue";
import { useStatus } from "@/features/Status/useStatus";

withDefaults(defineProps<{
  propertyName: string
  fromValue: TaskStatus
  toValue: TaskStatus
  createdBySelf: boolean
}>(), {
  createdBySelf: false,
});

const { statusToText } = useStatus();
const { t } = useI18n();
</script>

<template>
  <span class="other-text">
    {{ createdBySelf ? t('activities.self_status_changed') : t('activities.others_status_changed') }}
  </span>
  <span class="other-text">{{ t('activities.from') }}</span>
  <span class="item-with-text">
    <StatusItem
      :model-value="fromValue"
      :is-changeable="false"
      :loading="false"
      inline
    />
    <span>
      {{ t(statusToText(fromValue)) }}
    </span>
  </span>
  <span class="other-text">
    {{ t('activities.to') }}
  </span>
  <span class="item-with-text">
    <StatusItem
      :model-value="toValue"
      :is-changeable="false"
      :loading="false"
      inline
    />
    <span>
      {{ t(statusToText(toValue)) }}
    </span>
  </span>
</template>

<style scoped lang="scss">
span {
  display: flex;
  align-items: center;
}
.item-with-text {
  gap: 5px;
}
.other-text {
  color: var(--text-2);
}
</style>
