import { inject, provide, reactive, watchEffect } from "vue";
import moment from "moment";

const KEY = Symbol("TaskOverviewSettings");

export function createTaskOverviewSettings() {
  const overviewSettings = reactive({
    showStatusDone: localStorage.getItem("showStatusDone") === "true",
    onlyTillToday: localStorage.getItem("onlyTillToday") === "true",
    showStatusCompleted: localStorage.getItem("showStatusCompleted") === "true",
    hideSearchAndSort: localStorage.getItem("hideSearchAndSort") === "true",
  });

  watchEffect(() => {
    localStorage.setItem(
      "showStatusDone",
      `${overviewSettings.showStatusDone}`,
    );
    localStorage.setItem("onlyTillToday", `${overviewSettings.onlyTillToday}`);
    localStorage.setItem(
      "showStatusCompleted",
      `${overviewSettings.showStatusCompleted}`,
    );
    localStorage.setItem(
      "hideSearchAndSort",
      `${overviewSettings.hideSearchAndSort}`,
    );
  });

  function getParams(params: URLSearchParams) {
    if (!overviewSettings.showStatusDone) {
      params.append("filter[where][and][][status][neq]", "4");
    }

    if (!overviewSettings.showStatusCompleted) {
      params.append("filter[where][and][][status][neq]", "5");
    }

    const todayEnd = moment().endOf("day");
    if (overviewSettings.onlyTillToday) {
      params.append("filter[where][and][][deadlineAt][lte]", todayEnd.toISOString(true).replace(/\.\d+/, ""));
    }
  }

  return {
    getParams,
    overviewSettings,
  };
}

export function provideTaskOverviewSettings() {
  const inst = createTaskOverviewSettings();
  provide(KEY, inst);
  return inst;
}

type UseTaskOverviewSettings = ReturnType<typeof createTaskOverviewSettings>;
export function useTaskOverviewSettings() {
  const inst = inject<UseTaskOverviewSettings>(KEY);

  if (!inst) {
    throw new Error(
      "Run provideTaskOverviewSettings before useTaskOverviewSettings.",
    );
  }

  return inst;
}
