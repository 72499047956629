import { computed, inject, provide, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Feature, UserRole, useAuthStore } from "@verbleif/shared";
import type { HydraItem, Sort } from "@verbleif/lib";
import { useRightsStore } from "@/core/store/RightStore";
import { api } from "@/core/api";
import { useSystemStore } from "@/features/Store/useSystemStore";
import { ReportListType } from "@/features/Reports/ReportsRoutes";

const ReportListKey = Symbol("ReportListKey");

function mapToIri(item: string | HydraItem<any>): string {
  return typeof item === "string" ? item : item["@id"];
}

export function createReportList() {
  const { hasFeature, hasRole } = useRightsStore();
  const reportLists = ref<any>([]);
  const route = useRoute();
  const router = useRouter();
  const systemStore = useSystemStore();
  const authStore = useAuthStore();

  const filteredSorts = computed<HydraItem<Sort>[]>(() => {
    if (hasRole(UserRole.ROLE_FRONT_DESK)) {
      return systemStore.sorts.value;
    }

    // Returns nothing when ROLE_USER
    return systemStore.sorts.value.filter((sort) => {
      if (!authStore.user.value) {
        return false;
      }
      function applyFilter(dpIri: string) {
        return sort.department && mapToIri(sort.department) === dpIri;
      }

      return (
        authStore.user.value.managerDepartments
          .map(d => mapToIri(d))
          .filter(applyFilter).length > 0
      );
    });
  });

  const currentListType = computed(() => {
    return route.params.type as ReportListType || null;
  });

  const currentListId = computed(() => {
    return route.params.id as string || null;
  });

  const currentListRouteId = computed(() => {
    return `${currentListType.value}_${currentListId.value}`;
  });

  const currentList = computed(() => {
    switch (currentListType.value) {
      case ReportListType.TYPE_UNASSIGNED_ALL:
      case ReportListType.TYPE_UNASSIGNED_LOW_PRIO:
      case ReportListType.TYPE_UNASSIGNED_MED_PRIO:
      case ReportListType.TYPE_UNASSIGNED_HIGH_PRIO:
      case ReportListType.TYPE_THIRD_PARTY:
      case ReportListType.TYPE_CREATED_BY_ME:
        return null;
      case ReportListType.TYPE_SORT:
        return filteredSorts.value.find((sort: HydraItem<Sort>) => {
          return `${ReportListType.TYPE_SORT}_${sort.id}` === currentListRouteId.value;
        });
      case ReportListType.TYPE_CUSTOM:
      default:
        return reportLists.value.find((reportList: any) => {
          return `${ReportListType.TYPE_CUSTOM}_${reportList.id}` === currentListRouteId.value;
        });
    }
  });

  const loading = computed(() => {
    return !!currentList.value;
  });

  const currentListName = computed(() => {
    if (currentList.value) {
      return currentList.value.name;
    }

    return "";
  });

  const currentListTranslation = computed(() => {
    switch (currentListType.value) {
      case ReportListType.TYPE_UNASSIGNED_ALL:
        return "reports.type.unassigned_all";
      case ReportListType.TYPE_UNASSIGNED_NONE_PRIO:
        return "reports.type.unassigned_none_prio";
      case ReportListType.TYPE_UNASSIGNED_LOW_PRIO:
        return "reports.type.unassigned_low_prio";
      case ReportListType.TYPE_UNASSIGNED_MED_PRIO:
        return "reports.type.unassigned_med_prio";
      case ReportListType.TYPE_UNASSIGNED_HIGH_PRIO:
        return "reports.type.unassigned_high_prio";
      case ReportListType.TYPE_UNASSIGNED_URGENT_PRIO:
        return "reports.type.unassigned_urgent_prio";
      case ReportListType.TYPE_CREATED_BY_ME:
        return "reports.type.created_by_me";
      case ReportListType.TYPE_THIRD_PARTY:
        return "reports.type.third_party";
      case ReportListType.TYPE_CUSTOM:
      default:
        return "reports.type.custom";
    }
  });

  async function createReportList(name: string) {
    const reportList = {
      name,
      active: 1,
    };
    const index = reportLists.value.push({
      ...reportList,
      id: Math.random(),
      isPlaceholder: true,
    });
    api.post("/report_lists", reportList).then((response) => {
      reportLists.value.splice(index - 1, 1, {
        ...response.data,
        editMode: false,
      });
    });
  }

  watchEffect(() => {
    if (!hasFeature(Feature.CUSTOM_REPORT_LISTS)) {
      return;
    }
    if (!authStore.user.value) {
      return;
    }
    api
      .get(`/users/${authStore.user.value.id}/created_report_lists`)
      .then(({ data }) => {
        reportLists.value = data["hydra:member"];
      });
  });

  async function deleteReportList(id: number, index: number) {
    const listToBeRemoved = reportLists.value[index];
    await router.push({
      name: "report-list",
      params: { list: ReportListType.TYPE_UNASSIGNED_ALL },
    });
    reportLists.value.splice(index, 1);
    return api
      .delete(`/report_lists/${id}`)
      .then(() => true)
      .catch(() => {
        reportLists.value.splice(index, 0, listToBeRemoved);
        return false;
      });
  }

  async function updateReportList(id: number, index: number, data: any) {
    const beforeEdit = { ...reportLists.value[index] };
    Object.assign(reportLists.value[index], data);
    return api
      .patch(`/report_lists/${id}`, data)
      .then(() => true)
      .catch(() => {
        Object.assign(reportLists.value[index], beforeEdit);
        return false;
      });
  }

  return {
    currentListName,
    updateReportList,
    currentList,
    currentListId,
    currentListType,
    currentListTranslation,
    reportLists,
    loading,
    filteredSorts,
    createReportList,
    deleteReportList,
  };
}

export function provideReportList() {
  const inst = createReportList();
  provide(ReportListKey, inst);
  return inst;
}

type UseReportList = ReturnType<typeof createReportList>;
export function useReportList() {
  const inst = inject<UseReportList>(ReportListKey);

  if (!inst) {
    throw new Error("Run provideReportList before useReportList");
  }

  return inst;
}
