<script>
import moment from "moment";

export default {
  name: "ActivityDateItem",
  props: {
    date: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const parsedDate = moment(props.date)
      .utc()
      .format("D MMMM YYYY");
    return { parsedDate };
  },
};
</script>

<template>
  <div class="activity-date-item">
    {{ parsedDate }}
  </div>
</template>

<style scoped lang="scss">
.activity-date-item {
  background-color: var(--primary);
  color: var(--text-invert);
  width: auto;
  padding: 1px 10px;
  min-height: 16px;
  font-size: 12px;
  font-weight: 500;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  text-transform: capitalize;
}
</style>
