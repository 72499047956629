<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import type { TaskStatus } from "@verbleif/lib";
import StatusBox from "@/features/Status/StatusBox.vue";

withDefaults(defineProps<{
  modelValue: TaskStatus
  singletonGroup?: string
}>(), {
  singletonGroup: undefined,
});
const emit = defineEmits<{
  (e: "update:modelValue", status: TaskStatus): void
}>();
const { t } = useI18n();
const { statusToClass, statusToText } = useStatus();

function changeStatus(status: TaskStatus) {
  emit("update:modelValue", status);
}
</script>

<template>
  <StatusBox
    :model-value="modelValue"
    :loading="false"
    @update:model-value="(d) => changeStatus(d)"
  >
    <div class="status" :class="[statusToClass(modelValue)]">
      {{ t(statusToText(modelValue)) }}
      <div class="arrow-indicator">
        <FontAwesomeIcon icon="caret-down" />
      </div>
    </div>
  </StatusBox>
</template>

<style scoped lang="scss">
.status {
  display: inline-flex;
  border-color: transparent;
  color: white;
  font-size: 14px;
  align-items: center;
  padding: 0 10px;
  height: 32px;
  border-radius: 4px;
  gap: 5px;
}

.is-open {
  background-color: #32c5ff;
}

.is-in_progress {
  background-color: #979797;
}

.is-waiting {
  background-color: #ff003c;
}

.is-done {
  background-color: var(--primary);
}

.is-completed {
  background-color: var(--primary);
}
</style>
