import { ref } from "vue";

function createLoader() {
  const loading = ref(true);

  function isLoading() {
    return loading;
  }

  function setLoading(value: boolean) {
    loading.value = value;
  }

  function hide() {
    loading.value = false;
  }

  function show() {
    loading.value = true;
  }

  return { loading, show, hide, setLoading, isLoading };
}

export const loader = createLoader();
export default loader;

export function useLoader() {
  return loader;
}
