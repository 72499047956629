import type { RouteRecordRaw } from "vue-router";
import ReportsContainer from "./ReportsContainer.vue";

export enum ReportListType {
  TYPE_UNASSIGNED_ALL = "aall",
  TYPE_UNASSIGNED_NONE_PRIO = "an",
  TYPE_UNASSIGNED_LOW_PRIO = "al",
  TYPE_UNASSIGNED_MED_PRIO = "am",
  TYPE_UNASSIGNED_HIGH_PRIO = "ah",
  TYPE_UNASSIGNED_URGENT_PRIO = "au",
  TYPE_CREATED_BY_ME = "cbm",
  TYPE_SORT = "s",
  TYPE_CUSTOM = "c",
  TYPE_THIRD_PARTY = "tp",
}

const FALLBACK_TYPE = ReportListType.TYPE_CREATED_BY_ME;

export const routes = <RouteRecordRaw[]>[
  {
    path: "/r",
    name: "reports",
    component: ReportsContainer,
    redirect: (props) => {
      return {
        name: "report-list",
        params: { type: FALLBACK_TYPE, ...props?.params },
      };
    },
    children: [
      {
        path: "",
        name: "reports-redirect",
        redirect: {
          name: "report-list",
          params: { type: FALLBACK_TYPE },
        },
      },
      {
        path: "/:catchAll(.*)",
        redirect: { name: "report-list", params: { type: FALLBACK_TYPE } },
      },
      {
        path: "li/:type/:id?",
        name: "report-list",
        component: () => import("./Overview/ReportsView.vue"),
        beforeEnter: (to, _, next) => {
          const type = <ReportListType>to.params.type;
          if (!Object.values(ReportListType).includes(type)) {
            return next({
              name: "report-list",
              params: { type: FALLBACK_TYPE },
            });
          }
          return next();
        },
      },
    ],
  },
];

export default routes;
