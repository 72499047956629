<script setup lang="ts">
import { TaskStatus } from "@verbleif/lib";
import ChangeableItem from "@/features/ChangeableItem/ChangeableItem.vue";
import { useStatus } from "@/features/Status/useStatus";

withDefaults(defineProps<{
  modelValue: TaskStatus
  isChangeable: boolean
  loading: boolean
  isClearable?: boolean
}>(), {
  isChangeable: true,
  loading: true,
  isClearable: false,
});

const emit = defineEmits<{
  "update:modelValue": [TaskStatus]
}>();

const { statusToText, statusToClass, statusToInlineIcon } = useStatus();

function update(v: TaskStatus) {
  emit("update:modelValue", v);
}
</script>

<template>
  <ChangeableItem
    :item-to-text="statusToText"
    :item-to-class="statusToClass"
    :items="Object.values(TaskStatus).filter(value => typeof value === 'number') as number[]"
    :model-value="modelValue"
    :loading="loading"
    :is-changeable="isChangeable"
    class="status-changeable-item"
    :inline="true"
    :item-to-inline-icon="statusToInlineIcon"
    :item-to-stroked-icon="statusToInlineIcon"
    :is-clearable="isClearable"
    @update:model-value="update"
  />
</template>

<style lang="scss" scoped>
.status-changeable-item {
  width: 20px;
  shape-rendering: crispEdges;
}
</style>

<style lang="scss">
.status-changeable-item .item .preview {
  width: 20px;
  shape-rendering: crispEdges;
}
</style>
