import { useI18n } from "vue-i18n";
import { computed } from "vue";
import * as yup from "yup";
import { Feature } from "@verbleif/shared";
import { useRightsStore } from "@/core/store/RightStore";
import {
  TASK_TYPE_OBJECT,
} from "@/features/Tasks/useTaskType";
import useSystemStore from "@/features/Store/useSystemStore";

export default function useTaskSchema(sortField) {
  const { t } = useI18n();
  const { hasFeature } = useRightsStore();
  const systemStore = useSystemStore();

  const schema = computed(() => {
    const baseSchema = {
      sort: yup
        .string()
        .typeError(t("validation.required"))
        .required(t("validation.required")),
      object: yup.string().nullable().typeError(t("validation.required")),
      status: yup.number().nullable().typeError(t("validation.required")),
      priority: yup.number().nullable().typeError(t("validation.required")),
      inside: yup.boolean().nullable().typeError(t("validation.required")),
      timeSensitive: yup.boolean(),
      quantity: yup
        .number()
        .transform(value => (Number.isNaN(value) ? 0 : value))
        .nullable(),
      date: yup.string(),
      time: yup.string(),
      comments: yup.string(),
      assignTo: yup.array().required(t("validation.required")),
      description: yup.string().required(t("validation.required")),
    };

    if (
      !hasFeature(Feature.HIDE_QUANTITY)
      && hasFeature(Feature.BOOKING_EXPERTS)
    ) {
      baseSchema.quantity = yup
        .number()
        .min(1, t("validation.min_one"))
        .transform(value => (Number.isNaN(value) ? 0 : value))
        .nullable();
    }

    if (!sortField) {
      return yup.object().shape(baseSchema);
    }

    const sort = systemStore.sorts.value.find(s => s["@id"] === sortField.value);
    if (
      sort
      && [
        TASK_TYPE_OBJECT,
      ].includes(sort.type)
    ) {
      baseSchema.object = yup
        .object()
        .typeError(t("validation.required"))
        .required(t("validation.required"));
    }

    return yup.object().shape(baseSchema);
  });

  return {
    schema,
  };
}
