<script setup lang="ts">
import { ref, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import moment from "moment";

const props = withDefaults(defineProps<{
  deadlineAt?: string | null
  timeSensitive?: boolean
  loading?: boolean
  singleLine?: boolean
}>(), {
  deadlineAt: null,
  timeSensitive: false,
  loading: true,
  singleLine: false,
});

const { t } = useI18n();
const deadlineFormatted = ref<string | null>(null);

watchEffect(() => {
  if (props.loading) {
    return;
  }
  if (!props.deadlineAt) {
    return;
  }

  const momentDeadline = moment(props.deadlineAt);
  if (!momentDeadline.isValid()) {
    return;
  }

  const isPast = momentDeadline.isBefore(moment(), "day");
  const sameElseClass = isPast ? "is-past" : "is-future";
  const breakIfNotSingleLine = props.singleLine ? "&nbsp;" : "<br />";
  const getTime = props.timeSensitive ? "HH:mm" : "";

  deadlineFormatted.value = momentDeadline.calendar(null, {
    sameDay: `[<div class="is-today">][${t("base.today")}${breakIfNotSingleLine}]${getTime}[</div>]`,
    nextDay: `[<div class="is-future">]ddd ll[${breakIfNotSingleLine}]${getTime}[</div>]`,
    nextWeek: `[<div class="is-future">]ddd ll[${breakIfNotSingleLine}]${getTime}[</div>]`,
    lastDay: `[<div class="is-past">]ddd ll[${breakIfNotSingleLine}]${getTime}[</div>]`,
    lastWeek: `[<div class="is-past">]ddd ll[${breakIfNotSingleLine}]${getTime}[</div>]`,
    sameElse: `[<div class="${sameElseClass}">]ddd ll[${breakIfNotSingleLine}]${getTime}[</div>]`,
  });
});
</script>

<template>
  <div v-if="loading || deadlineAt" class="deadline-container" :class="{ 'is-single-line': singleLine }">
    <div v-if="loading" class="skeleton">
      <VSkeleton
        :width="75"
        width-unit="px"
        :dynamic-width="false"
        :height="10"
      />
      <VSkeleton
        :width="50"
        width-unit="px"
        :dynamic-width="false"
        :height="10"
      />
    </div>
    <template v-else>
      <div class="deadline" v-html="deadlineFormatted" />
    </template>
  </div>
</template>

<style lang="scss" scoped>
.deadline-container {
  &:not(.is-single-line) {
    width: 100px;
  }
  pointer-events: none;

  .skeleton {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
  }

  .deadline {
    font-size: 11px;
    font-weight: bold;

    ::v-deep(div) {
      text-align: center;
      line-height: 1.2;

      &.is-future {
        color: var(--text);
        font-weight: 400;
      }
      &.is-today {
        color: #00a8c6;
      }
      &.is-past {
        color: #ff003c;
      }
    }
  }
}
</style>
