import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "235",
  height: "48"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", { "fill-rule": "nonzero" }, [
      _createElementVNode("g", { fill: "#4CAF50" }, [
        _createElementVNode("path", { d: "m20.406 36.382 10.021-33.31H40.57L25.174 47.355h-9.515L.327 3.073H10.45zM59.275 47.973q-7.226 0-11.768-4.442-4.541-4.441-4.539-11.83v-.853a19.9 19.9 0 0 1 1.914-8.866 14.05 14.05 0 0 1 5.418-6.024 15.2 15.2 0 0 1 8.004-2.112q6.747 0 10.61 4.255 3.864 4.256 3.874 12.092v3.59H51.87q.425 3.224 2.564 5.171t5.426 1.93q5.061 0 7.926-3.68l4.312 4.837a13.2 13.2 0 0 1-5.352 4.377 17.5 17.5 0 0 1-7.47 1.555m-1.012-27.042a5.48 5.48 0 0 0-4.238 1.774q-1.623 1.772-2.078 5.07h12.207v-.702q-.06-2.93-1.579-4.537-1.518-1.605-4.302-1.609zM96.228 22.692a24 24 0 0 0-3.158-.244q-4.98 0-6.53 3.38v21.537h-8.772V14.45h8.29l.24 3.92q2.641-4.525 7.318-4.529a9.2 9.2 0 0 1 2.733.396zM130.056 31.207q0 7.91-3.374 12.338t-9.414 4.425q-5.344 0-8.533-4.107l-.391 3.502h-7.895V.639h8.772v16.76q3.037-3.554 7.987-3.557 6.012 0 9.427 4.425t3.418 12.456zm-8.773-.64q0-4.988-1.579-7.284t-4.706-2.298q-4.19 0-5.77 3.438v12.99q1.61 3.468 5.83 3.468 4.221 0 5.588-4.198.62-2.005.62-6.112zM144.723 47.365h-8.806V.639h8.806zM167.07 47.973q-7.228 0-11.766-4.442-4.538-4.441-4.542-11.83v-.853a19.9 19.9 0 0 1 1.914-8.866 14.1 14.1 0 0 1 5.422-6.024 15.2 15.2 0 0 1 8-2.112q6.748 0 10.614 4.255 3.867 4.256 3.87 12.092v3.59h-20.919a8.18 8.18 0 0 0 2.568 5.171q2.142 1.947 5.419 1.947 5.061 0 7.925-3.68l4.312 4.836a13.2 13.2 0 0 1-5.348 4.361 17.5 17.5 0 0 1-7.47 1.555m-1.013-27.042a5.47 5.47 0 0 0-4.234 1.774q-1.627 1.772-2.082 5.07h12.207v-.702q-.06-2.93-1.579-4.537-1.518-1.605-4.302-1.609zM185.502 5.932a4.33 4.33 0 0 1 1.322-3.255q1.32-1.278 3.597-1.278t3.583 1.278a4.65 4.65 0 0 1-.017 6.541q-1.35 1.278-3.566 1.278-2.217 0-3.566-1.261a4.3 4.3 0 0 1-1.353-3.303m9.319 41.433h-8.806V14.45h8.806zM204.45 47.365V20.897h-4.889V14.45h4.89v-2.8q0-5.536 3.17-8.595 3.173-3.06 8.885-3.043c1.506.039 3.002.243 4.464.609l-.091 6.815a11.2 11.2 0 0 0-2.673-.274q-4.95 0-4.95 4.654v2.647h6.533v6.433h-6.532v26.468z" })
      ]),
      _createElementVNode("path", {
        fill: "#F44336",
        d: "M225.003 42.52a4.16 4.16 0 0 1 1.416-3.264c2.062-1.675 5.09-1.675 7.151 0 .95.838 1.47 2.03 1.427 3.264a4.1 4.1 0 0 1-1.406 3.245c-2.08 1.647-5.092 1.647-7.172 0a4.1 4.1 0 0 1-1.416-3.245"
      })
    ], -1)
  ])))
}
export default { render: render }