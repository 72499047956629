import { useI18n } from "vue-i18n";
import { useToast } from "vue-toastification";
import * as Sentry from "@sentry/vue";
import type { Component } from "vue";
import type { HydraItem, MediaObject, useModal } from "@verbleif/lib";
import { api } from "@/core/api";
import { useMediaObject } from "@/features/FileUpload/useMediaObject";

interface EditTaskArguments {
  component: Component
  endpoint: string
  translationKey: string
  modalManager: ReturnType<typeof useModal>
  transformer: (input: any) => Promise<any>
  load: () => Promise<any>
}

export function useEditTask({
  component,
  endpoint,
  translationKey,
  modalManager,
  transformer = async input => input,
  load,
}: EditTaskArguments) {
  const { t } = useI18n();
  const { deleteFile } = useMediaObject();
  const { success, error } = useToast();

  function showEdit(item: any, initialMediaObjects: HydraItem<MediaObject>[]) {
    const unreactiveItem = JSON.parse(JSON.stringify(item));

    modalManager.open({
      component,
      opts: {
        okIcon: ["fas", "plus"],
        okText: t("base.save"),
        cancelText: t("base.cancel"),
        onOk: (input: any) => edit(input, item, initialMediaObjects),
      },
      componentProps: {
        item: unreactiveItem,
        mediaObjects: initialMediaObjects,
      },
    });
  }

  async function edit(input: any, item: any, initialMediaObjects: HydraItem<MediaObject>[]): Promise<void> {
    const newMediaObjects = input.mediaObjects.map((m: any) => m.id);
    const filesToBeDeleted = initialMediaObjects.filter((m: any) => {
      return !newMediaObjects.includes(m.id);
    });

    modalManager.setLoading(true);

    const output = await transformer(input);

    const oldItem = Object.assign({}, item);
    modalManager.close();
    Object.assign(item, input);
    const toastId = success(t(`${translationKey}.edit_success`));

    api
      .patch(`${endpoint}/${item.id}`, output)
      .then((r: any) => {
        Object.assign(item, r.data);
      })
      .catch((e: any) => {
        let msg = "base.unknown_error";
        if (e.response) {
          msg = `base.${e.response.status}`;
        }
        error(t(msg, { errorCode: 0 }), { id: toastId });
        Object.assign(item, oldItem);
        Sentry.setContext("error", e);
        Sentry.captureException(e);
      })
      .finally(() => {
        modalManager.setLoading(false);
        load();
      });
    for (const fileToBeDeleted of filesToBeDeleted) {
      deleteFile(fileToBeDeleted);
    }
  }

  return { showEdit };
}

export default useEditTask;
