import { computed, ref, watch } from "vue";
import { useMediaObject } from "@/features/FileUpload/useMediaObject";

function useFileUpload({ files, editable = true, emit }) {
  const { deleteFile } = useMediaObject();

  const fileList = ref([]);
  const inputEl = ref(null);
  const hasFiles = computed(() => {
    return fileList.value.length + files.value.length;
  });

  function addFiles(files) {
    fileList.value = [...fileList.value, ...files];
  }

  function onDeleteFile({ file }) {
    if (!editable) {
      return;
    }
    fileList.value = fileList.value.filter(f => f !== file);
    inputEl.value.value = null;
  }

  function onUpload({ mediaObject, file, index }) {
    if (!editable) {
      return;
    }
    onDeleteFile({ file, index });
    mediaObject.isUploaded = true;
    files.value.push(mediaObject);
  }

  function onDelete({ file, index }) {
    if (!editable) {
      return;
    }
    deleteFile(file);
    files.value.splice(index, 1);
  }

  watch(
    hasFiles,
    () => {
      emit && emit("countChanged", hasFiles.value);
    },
    { immediate: true },
  );

  return {
    onDelete,
    onUpload,
    inputEl,
    onDeleteFile,
    addFiles,
    fileList,
    hasFiles,
  };
}
export default useFileUpload;
