<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { Feature } from "@verbleif/shared";
import AssignTo from "@/features/AssignTo/AssignTo.vue";
import { useMultiSelect } from "@/features/Tasks/Overview/useMultiSelect";
import StatusChangeButton from "@/features/Tasks/EditTask/StatusChangeButton.vue";
import PriorityItem from "@/features/Priority/PriorityItem.vue";
import InsideItem from "@/features/Inside/InsideItem.vue";
import DeadlineItem from "@/features/Deadline/DeadlineItem.vue";
import DeleteIcon from "@/assets/DeleteIcon.svg?component";
import useRightsStore from "@/core/store/RightStore";
import { DeadlineSymbol } from "@/features/Deadline/DeadlineSymbol";

const { hasFeature } = useRightsStore();
const { t } = useI18n();

const {
  resetModelValues,
  onMultiAssignTo,
  onMultiScalar,
  onMultiDelete,
  multiSelectLoading,
  modelValues,
  multiSelectIris,
  progress: { maxProgress, progress, details },
} = useMultiSelect();

const { value: timeSensitive } = useField("timeSensitive");

function toggleTimeSensitive() {
  timeSensitive.value = !timeSensitive.value;
}

provide(DeadlineSymbol, {
  timeSensitive,
  toggleTimeSensitive,
});
</script>

<template>
  <transition name="slide-down">
    <div v-if="multiSelectIris.length > 0" class="top-bar">
      <div class="top-bar-left">
        <div class="top-bar-tasks">
          <div class="select-box-top" @click="resetModelValues" />
          {{ multiSelectIris.length }}
          {{ t("tasks.selected_tasks", { count: multiSelectIris.length }) }}
        </div>
      </div>
      <div class="top-bar-middle">
        <div class="actions">
          <StatusChangeButton
            :model-value="modelValues.status"
            @update:model-value="(v: number) => onMultiScalar({ status: v })"
          />
          <AssignTo
            :loading="false"
            :model-value="[...modelValues.departments, ...modelValues.users]"
            @update:model-value="onMultiAssignTo"
          />
          <DeadlineItem
            :time-sensitive="!!timeSensitive"
            :loading="false"
            :model-value="modelValues.deadlineAt"
            @update:model-value="(v: any, timeSensitive: boolean) => onMultiScalar({ deadlineAt: v, timeSensitive })"
          />
          <PriorityItem
            v-if="!hasFeature(Feature.HIDE_PRIORITY)"
            :loading="false"
            :inline="false"
            is-clearable
            :model-value="modelValues.priority"
            @update:model-value="(v) => onMultiScalar({ priority: v })"
          />
          <InsideItem
            v-if="!hasFeature(Feature.HIDE_INSIDE)"
            :loading="false"
            :inline="false"
            is-clearable
            :model-value="modelValues.inside"
            @update:model-value="(v) => onMultiScalar({ inside: v })"
          />
          <div class="dangerous-actions">
            <VTooltip :content="t('tasks.remove_selection')">
              <template #reference>
                <div class="delete-icon" @click="onMultiDelete">
                  <DeleteIcon />
                </div>
              </template>
            </VTooltip>
          </div>
        </div>
        <div class="dismiss" @click="resetModelValues">
          <FontAwesomeIcon
            v-if="multiSelectLoading"
            class="v-loader"
            spin
            icon="spinner"
          />
          <div v-else>
            {{ t('base.stop') }}
          </div>
        </div>
      </div>
      <div class="top-bar-right">
        <div v-if="multiSelectLoading" class="progress-container">
          <div class="progress-wrapper">
            <progress class="progress" :value="progress" :max="maxProgress" />
            <div class="details">
              {{ details }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
.top-bar {
  position: fixed;

  display: flex;
  flex-direction: row;
  background: var(--background);
  border-bottom: 2px solid var(--primary-light);
  z-index: 5;
  left: 0;
  top: 0;
  right: 0;

  .delete-icon {
    cursor: pointer;
    width: 32px;
    height: 32px;
  }

  .top-bar-middle {
    padding: 10px;
    .actions {
      display: flex;
      align-items: center;
      gap: 10px;

      .assign-to {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        width: 28px;
      }

      .assign-placeholder {
        cursor: pointer;

        &:hover {
          opacity: 0.5;
        }
      }
    }
    .dismiss {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 25px;
      position: absolute;
      padding: 10px 20px;
      border-radius: 0 0 10px 10px;
      color: var(--text-invert);
      background: var(--primary);
      left: 50%;
      bottom: -25px;
      font-size: 14px;

      &:hover {
        cursor: pointer;
        opacity: 0.5;
      }

      transform: translate(-50%, 0);
    }

    position: relative;
    flex-shrink: 0;
  }

  .top-bar-right {
    padding: 10px;
    width: 50%;
    flex-shrink: 1;
    flex-direction: row-reverse;

    .progress-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: end;
      height: 100%;
      width: 100%;
      flex: 1;

      .progress-wrapper {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .details {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          color: var(--text);
          font-size: 14px;
        }

        .progress {
          -webkit-appearance: none;
          appearance: none;

          height: 25px;
          border-radius: 5px;
        }

        .progress[value]::-webkit-progress-bar {
          background-color: var(--input-background);
          border-radius: 5px;
          border: 1px solid $grey-1;
        }
        .progress[value]::-webkit-progress-value {
          background-color: $primary;
        }
      }
    }
  }

  .top-bar-left {
    color: var(--text);
    display: flex;
    align-items: center;
    padding: 10px;
    width: 50%;
    flex-shrink: 1;
    font-size: 15px;
    margin-left: 20px;

    .top-bar-tasks {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .select-box-top {
      &:hover {
        cursor: pointer;
      }
      margin-right: 10px;
      display: flex;
      padding: 5px;
      box-shadow: inset 0 0 0 3px var(--input-background);
      width: 18px;
      height: 18px;
      border-radius: 50%;
      border: 1px solid var(--grey-1);
      justify-content: center;
      align-items: center;
      color: white;
      background-color: var(--primary);
    }
  }
}
</style>
