import type { HydraItem } from "@verbleif/lib";

export default function useReportTransformer() {
  function mapToIri(item: string | HydraItem<any>) {
    return typeof item === "string" ? item : item["@id"];
  }

  async function transformer(input: any) {
    const data: any = {
      object: null,
      space: null,
      mean: null,
      sort: mapToIri(input.sort),
      inside: input.inside,
      description: input.description,
      priority: input?.priority,
      location: input.location,
    };

    if (input?.meta) {
      data.meta = input.meta;
    }

    if (input?.client) {
      data.client = input.client;
    }

    if (input.object) {
      data.object = mapToIri(input.object);
    }

    if (input.space) {
      data.space = mapToIri(input.space);
    }

    if (input.mean) {
      data.mean = mapToIri(input.mean);
    }

    if (input?.mediaObjects) {
      data.mediaObjects = input.mediaObjects.map(mapToIri);
    }

    return data;
  }

  return {
    transformer,
  };
}
