import { useConfigStore } from "@verbleif/shared";

const config = useConfigStore();

config.setInitialConfig({
  apiUrl: import.meta.env.VITE_API_URL,
  appEnv: import.meta.env.VITE_ENV_NAME,
  mercureUrl: import.meta.env.VITE_MERCURE_URL,
  cdnUrl: import.meta.env.VITE_CDN_URL,
});
