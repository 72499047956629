<script>
import { useActivityEventItem } from "./useActivityEventItem";

export default {
  name: "ActivityEvent",
  props: {
    activity: {
      type: Object,
      default: () => {
        return {};
      },
    },
    time: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const { message } = useActivityEventItem({ activity: props.activity });

    return {
      message,
    };
  },
};
</script>

<template>
  <div class="activity-event">
    <div class="message">
      <span class="what">
        {{ message }}
      </span>
      <span class="when" :title="activity.createdAt">
        {{ time }}
      </span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.activity-event {
  color: var(--text-2);
  font-size: 13px;
  display: flex;

  .message {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .what {
      display: inline;
    }

    .when {
      font-size: 12px;
      color: var(--text-2);
    }
  }
}
</style>
