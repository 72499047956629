import { TaskStatus } from "@verbleif/lib";
import StatusBoxOpen from "@/features/Status/StatusBoxOpen.svg";
import StatusBoxInProgress from "@/features/Status/StatusBoxInProgress.svg";
import StatusBoxWaitingFor from "@/features/Status/StatusBoxWaitingFor.svg";
import StatusBoxFinished from "@/features/Status/StatusBoxFinished.svg";
import StatusBoxChecked from "@/features/Status/StatusBoxChecked.svg";

export const STATUSES_TEXTS: Record<TaskStatus, string> = {
  [TaskStatus.OPEN]: "tasks.status.open",
  [TaskStatus.IN_PROGRESS]: "tasks.status.in_progress",
  [TaskStatus.WAITING_FOR]: "tasks.status.waiting",
  [TaskStatus.FINISHED]: "tasks.status.done",
  [TaskStatus.CHECKED]: "tasks.status.completed",
};
export const STATUSES_CLASSES: Record<TaskStatus, string> = {
  [TaskStatus.OPEN]: "is-open",
  [TaskStatus.IN_PROGRESS]: "is-in_progress",
  [TaskStatus.WAITING_FOR]: "is-waiting",
  [TaskStatus.FINISHED]: "is-done",
  [TaskStatus.CHECKED]: "is-completed",
};

export function useStatus() {
  function statusToInlineIcon(status: TaskStatus) {
    switch (status) {
      case TaskStatus.OPEN:
        return StatusBoxOpen;
      case TaskStatus.IN_PROGRESS:
        return StatusBoxInProgress;
      case TaskStatus.WAITING_FOR:
        return StatusBoxWaitingFor;
      case TaskStatus.FINISHED:
        return StatusBoxFinished;
      case TaskStatus.CHECKED:
        return StatusBoxChecked;
    }

    return null;
  }

  function statusToText(v: TaskStatus) {
    return STATUSES_TEXTS[v];
  }

  function statusToClass(v: TaskStatus) {
    return STATUSES_CLASSES[v];
  }

  return {
    statusToInlineIcon,
    statusToText,
    statusToClass,
  };
}
