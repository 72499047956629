import { ActivityTypeEnum } from "@verbleif/lib";
import ActivityEventItem from "./ActivityEventItem.vue";
import ActivityEntity from "@/features/Activities/ActivityEntity.vue";
import UnknownItem from "@/features/Activities/UnknownItem.vue";
import ActivityCommentItem from "@/features/Activities/ActivityCommentItem.vue";

export function useActivityItem({ activity }) {
  const component = getComponent();

  function getComponent() {
    switch (activity.type) {
      case ActivityTypeEnum.TYPE_REPORT_CREATED:
      case ActivityTypeEnum.TYPE_TASK_CREATED:
      case ActivityTypeEnum.TYPE_TASK_ASSIGNED:
      case ActivityTypeEnum.TYPE_REPORT_TRANSFORMED_TASK:
      case ActivityTypeEnum.TYPE_TASK_STATUS_CHANGED:
        return ActivityEventItem;
      case ActivityTypeEnum.TYPE_COMMENT:
        return ActivityCommentItem;
      case ActivityTypeEnum.TYPE_ENTITY:
        return ActivityEntity;
      default:
        return UnknownItem;
    }
  }

  return {
    activity,
    component,
  };
}
