import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faAnalytics as fadAnalytics,
  faBallotCheck as fadBallotCheck,
  faCheckCircle as fadCheckCircle,
  faCheckDouble as fadCheckDouble,
  faClock as fadClock,
  faClockTen as fadClockTen,
  faClockThree as fadClockThree,
  faCog as fadCog,
  faFileInvoiceDollar as fadFileInvoiceDollar,
  faFlag as fadFlag,
  faHome as fadHome,
  faPenRuler as fadPenRuler,
  faRightFromBracket as fadRightFromBracket,
  faRightToBracket as fadRightToBracket,
  faSpinner as fadSpinner,
  faTachometerAltFastest as fadTachometerAltFastest,
  faUserTie as fadUserTie,
  faUsers as fadUsers,
} from "@fortawesome/pro-duotone-svg-icons";

import {
  faArchive as farArchive,
  faBan as farBan,
  faBell as farBell,
  faBook as farBook,
  faBroom as farBroom,
  faBrush as farBrush,
  faCalendar as farCalendar,
  faCalendarCheck as farCalendarCheck,
  faCircleCheck as farCircleCheck,
  faClock as farClock,
  faCopy as farCopy,
  faCreditCard as farCreditCard,
  faDesktop as farDesktop,
  faExchange as farExchange,
  faExclamationCircle as farExclamationCircle,
  faExclamationTriangle as farExclamationTriangle,
  faExternalLink as farExternalLink,
  faFileExport as farFileExport,
  faFileImport as farFileImport,
  faFileInvoice as farFileInvoice,
  faFilter as farFilter,
  faHome as farHome,
  faHouse as farHouse,
  faLayerGroup as farLayerGroup,
  faLocation as farLocation,
  faLock as farLock,
  faMask as farMask,
  faMobile as farMobile,
  faPencil as farPencil,
  faPlus as farPlus,
  faPlusCircle as farPlusCircle,
  faRandom as farRandom,
  faRedo as farRedo,
  faRepeat as farRepeat,
  faRobot as farRobot,
  faSearch as farSearch,
  faSpinner as farSpinner,
  faTags as farTags,
  faTh as farTh,
  faThLarge as farThLarge,
  faTimes as farTimes,
  faTimesCircle as farTimesCircle,
  faTrash as farTrash,
  faUpload as farUpload,
  faUser as farUser,
  faUserFriends as farUserFriends,
  faUserShield as farUserShield,
  faUserSlash as farUserSlash,
  faUsers as farUsers,
  faUsersClass as farUsersClass,
  faWebhook as farWebhook,
} from "@fortawesome/pro-regular-svg-icons";

import {
  faAdjust, faArrowUpRight,
  faBallotCheck,
  faBan,
  faBell,
  faBook,
  faBuilding,
  faCalendar,
  faCalendarAlt,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCheck,
  faCheckDouble,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClock,
  faCog,
  faComment,
  faDoorClosed,
  faDoorOpen,
  faDownload,
  faEllipsisH,
  faExchange,
  faExclamation,
  faExclamationTriangle,
  faExternalLink,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFileCsv,
  faFilter,
  faFlag,
  faFolder,
  faFolderOpen,
  faGlobe,
  faHashtag,
  faHome,
  faInfoCircle,
  faKeyboard,
  faLink,
  faList,
  faLock,
  faPaperPlane,
  faPencil,
  faPlus,
  faQuestion,
  faRedo,
  faSave,
  faSortAlphaDown,
  faSortAlphaUp,
  faSpinner,
  faSquare,
  faStore,
  faStoreSlash,
  faSync,
  faTachometerAlt,
  faTasks,
  faTimes,
  faTimesCircle,
  faTrash,
  faUndo,
  faUpload,
  faUser,
  faUserSlash,
} from "@fortawesome/pro-solid-svg-icons";

import {
  faCamera as falCamera,
  faChevronLeft as falChevronleft,
  faCircleCheck as falCircleCheck,
  faExclamationTriangle as falExclamationTriangle,
  faFile as falFile,
  faFileArchive as falFileArchive,
  faFileExcel as falFileExcel,
  faFilePdf as falFilePdf,
  faFileVideo as falFileVideo,
  faFileWord as falFileWord,
  faImage as falImage,
  faInfoCircle as falInfoCircle,
  faSpinner as falSpinner,
  faTimes as falTimes,
} from "@fortawesome/pro-light-svg-icons";

library.add(
  falCircleCheck,
  farCircleCheck,
  falCamera,
  fadUserTie,
  fadAnalytics,
  fadCog,
  fadBallotCheck,
  fadFlag,
  fadTachometerAltFastest,
  farUserFriends,
  farUsers,
  farExclamationCircle,
  farUsersClass,
  farCreditCard,
  farFileInvoice,
  farUserShield,
  faBuilding,
  faUser,
  faLock,
  faSpinner,
  faSave,
  faEye,
  faEyeSlash,
  farLocation,
  farTh,
  farThLarge,
  farHome,
  farBrush,
  farTags,
  farLock,
  faBell,
  farBell,
  farTimes,
  farTimesCircle,
  farRedo,
  faAdjust,
  faPlus,
  farPlusCircle,
  faSortAlphaUp,
  faSortAlphaDown,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faEllipsisH,
  faHashtag,
  faPencil,
  faUserSlash,
  faTrash,
  falTimes,
  farUserSlash,
  faInfoCircle,
  faClock,
  faTimes,
  faPaperPlane,
  farTrash,
  farBroom,
  faBan,
  farUpload,
  faUpload,
  faCheck,
  faFolder,
  faCog,
  farPlus,
  faCalendar,
  faClock,
  faExternalLinkAlt,
  farPencil,
  falInfoCircle,
  faFilter,
  falExclamationTriangle,
  faBallotCheck,
  faRedo,
  fadSpinner,
  falFilePdf,
  falFileWord,
  faBook,
  falFileExcel,
  falFileVideo,
  falFileArchive,
  falFile,
  falInfoCircle,
  faExclamation,
  faDoorOpen,
  faDoorClosed,
  farFileExport,
  farCopy,
  faFolderOpen,
  farArchive,
  falInfoCircle,
  faTasks,
  faFlag,
  faComment,
  faDownload,
  farCalendar,
  fadFileInvoiceDollar,
  farBook,
  farSearch,
  farExchange,
  faExchange,
  farHouse,
  faExternalLink,
  farFilter,
  falImage,
  fadUsers,
  farUser,
  farRandom,
  faFileCsv,
  faLink,
  falChevronleft,
  faQuestion,
  farExternalLink,
  faTachometerAlt,
  faUndo,
  faSquare,
  faTimesCircle,
  faCheckDouble,
  farMask,
  faGlobe,
  faSync,
  fadHome,
  faCalendarAlt,
  faList,
  falFileExcel,
  faStoreSlash,
  faKeyboard,
  faStore,
  faExclamationTriangle,
  faHome,
  farClock,
  farCalendarCheck,
  faCaretDown,
  faCaretUp,
  faCaretRight,
  faCaretLeft,
  farExclamationTriangle,
  farSpinner,
  falSpinner,
  fadRightToBracket,
  fadRightFromBracket,
  fadClockTen,
  fadClockThree,
  fadClock,
  fadCheckCircle,
  fadCheckDouble,
  farMobile,
  farDesktop,
  farLayerGroup,
  farFileImport,
  farRobot,
  farWebhook,
  faArrowUpRight,
  farBan,
  farRepeat,
  fadPenRuler,
);
