import { useAuthStore, useImpersonationStore, useLocationStore } from "@verbleif/shared";
import { Crisp } from "crisp-sdk-web";
import { useSystemStore } from "@/features/Store/useSystemStore";
import router from "@/core/plugin/router";

export function useLogout() {
  const authStore = useAuthStore();
  const systemStore = useSystemStore();
  const locationStore = useLocationStore();
  const impersonationStore = useImpersonationStore();

  async function logout(redirectToLogin = true) {
    authStore.reset();
    locationStore.reset();
    impersonationStore.reset();
    try {
      Crisp.session.reset();
      Crisp.chat.hide();
    } catch (e) {
      console.error(e);
    }
    if (redirectToLogin) {
      await router.push({ name: "login" }).then(() => {
        systemStore.reset();
      });
    }

    systemStore.reset();
  }

  return { logout };
}
