import { computed, ref } from "vue";
import moment from "moment";
import { useAuthStore, useLocationStore } from "@verbleif/shared";
import type { HydraCollection, HydraItem, Notification } from "@verbleif/lib";
import api from "@/core/api";

export function createNotificationSidebar() {
  const locationStore = useLocationStore();
  const authStore = useAuthStore();

  const showNotificationSidebar = ref(false);
  const notifications = ref<HydraItem<Notification>[]>([]);

  async function getNotifications() {
    const user = authStore.user.value;
    if (!user) {
      console.error("No user so not fetching notifications");
      return;
    }

    const locationIri = locationStore.selectedLocation.value;
    return api
      .get<HydraCollection<Notification>>(
        `${user["@id"]}/notifications?readAt=null&active=1&location=${locationIri}`,
      )
      .then(({ data }) => {
        notifications.value = data["hydra:member"];
      });
  }

  function toggleNotificationSidebar() {
    showNotificationSidebar.value = !showNotificationSidebar.value;
  }

  async function removeNotification(notification: HydraItem<Notification>) {
    const notificationIndex = notifications.value.findIndex(n => n.id === notification.id);
    notifications.value.splice(notificationIndex, 1);
    return api.patch(`${notification["@id"]}/hide`, {}).catch(() => {
      notifications.value.splice(notificationIndex, 0, notification);
    });
  }

  function markAsRead(notification: HydraItem<Notification>) {
    const notificationIndex = notifications.value.findIndex(n => n.id === notification.id);
    if (notificationIndex === -1) {
      return;
    }
    notifications.value[notificationIndex].readAt = moment().toISOString(true);
    notifications.value = [
      ...notifications.value,
    ];
    api.patch(`${notification["@id"]}/read`, {}).catch(() => {
      notifications.value[notificationIndex].readAt = null;
      notifications.value = [
        ...notifications.value,
      ];
    });
  }

  const readNotifications = computed(() => {
    return notifications.value.filter(({ readAt }) => readAt !== null);
  });

  const unreadNotifications = computed(() => {
    return notifications.value.filter(({ readAt }) => readAt === null);
  });

  const notificationCount = computed(() => {
    return unreadNotifications.value.length;
  });

  return {
    getNotifications,
    removeNotification,
    toggleNotificationSidebar,
    markAsRead,
    showNotificationSidebar,
    readNotifications,
    unreadNotifications,
    notificationCount,
  };
}

const notificationSidebar = createNotificationSidebar();

export function useNotificationSidebar() {
  return notificationSidebar;
}
