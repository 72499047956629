import { onBeforeUnmount, onMounted, onUpdated } from "vue";

export function useScrollToBottom(container) {
  // resizeObserver DOES NOT DETECT scrollHeight changes, only real height changes, using onUpdated for that.
  const resizeObserver = new ResizeObserver(() => {
    if (!container.value) {
      return;
    }
    container.value.scrollTop = container.value.scrollHeight;
  });

  onMounted(() => {
    resizeObserver.observe(container.value);
  });

  onUpdated(() => {
    container.value.scrollTop = container.value.scrollHeight;
  });

  onBeforeUnmount(() => {
    resizeObserver.disconnect();
  });

  return {
    resizeObserver,
  };
}
