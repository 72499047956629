<script setup lang="ts">
import { Field, Form as VForm } from "vee-validate";
import { computed, toRefs } from "vue";
import { useI18n } from "vue-i18n";
import { Feature, UserRole, useAuthStore } from "@verbleif/shared";
import type { HydraItem, MediaObject, Task, useModal } from "@verbleif/lib";
import { useCount } from "../../FileUpload/useCount";
import { useRightsStore } from "@/core/store/RightStore";
import ActivitiesView from "@/features/Activities/ActivitiesView.vue";
import DragArea from "@/features/FileUpload/DragArea.vue";
import { provideSort } from "@/features/Sort/useSort";
import EditTaskInformation from "@/features/Tasks/EditTask/EditTaskInformation.vue";
import useTaskSchema from "@/features/Tasks/useTaskSchema";
import StatusChangeButton from "@/features/Tasks/EditTask/StatusChangeButton.vue";
import AssignTo from "@/features/AssignTo/AssignTo.vue";
import InsideItem from "@/features/Inside/InsideItem.vue";
import PriorityItem from "@/features/Priority/PriorityItem.vue";
import ReservationView from "@/features/Tasks/EditTask/ReservationView.vue";
import { ActivityEntityType } from "@/features/Activities/useActivities";
import ThirdPartyButton from "@/features/Tasks/EditTask/ThirdPartyButton.vue";

const props = defineProps<{
  item: HydraItem<Task>
  mediaObjects: HydraItem<MediaObject>[]
  manager: ReturnType<typeof useModal>
}>();
const { item } = toRefs(props);
const authStore = useAuthStore();
const { hasFeature } = useRightsStore();

const TRANSLATION_KEY = "tasks";
const { t } = useI18n();
const { sortField } = provideSort();
const { schema } = useTaskSchema(sortField);
const { hasRole } = useRightsStore();
const mediaObjects = ref(props.mediaObjects);
const { attachmentTitle, setCount } = useCount(TRANSLATION_KEY, mediaObjects);

function submit(values: any) {
  props.manager.options.onOk(values);
}

function mapToIri(item: string | HydraItem<any>) {
  return typeof item === "string" ? item : item["@id"];
}

const initialValues = computed<HydraItem<Task> & { assignTo: any[] }>(() => {
  return {
    ...item.value,
    mediaObjects: mediaObjects.value,
    assignTo: [
      ...(item.value.users ? item.value.users.map(mapToIri) : []),
      ...(item.value.departments ? item.value.departments.map(mapToIri) : []),
    ],
  };
});

const allowedToChange = computed(() => {
  if (hasRole(UserRole.ROLE_DEPARTMENT_MANAGER)) {
    return true;
  }
  if (typeof props.item?.createdBy === "string") {
    return false;
  }
  return props.item?.createdBy?.id === authStore.user.value?.id;
});
</script>

<template>
  <div class="edit-task-container">
    <VForm
      v-slot="{ errors }"
      :initial-values="initialValues"
      :validation-schema="schema"
      class="form"
      @submit="submit"
    >
      <div class="task-content">
        <div class="task-info">
          <div class="task-info-header">
            <Field v-slot="{ field }" name="status">
              <StatusChangeButton
                singleton-group="task-edit"
                :model-value="field.value"
                @update:model-value="field.onChange"
              />
            </Field>
            <Field v-slot="{ field }" name="assignTo">
              <AssignTo
                :disabled="!allowedToChange"
                singleton-group="task-edit"
                :model-value="field.value"
                :loading="false"
                @update:model-value="field.onChange"
              />
            </Field>
            <Field v-slot="{ field }" name="inside">
              <InsideItem
                v-if="!hasFeature(Feature.HIDE_INSIDE)"
                singleton-group="task-edit"
                :loading="false"
                :inline="false"
                :model-value="field.value"
                is-clearable
                :disabled="!allowedToChange"
                @update:model-value="field.onChange"
              />
            </Field>
            <Field v-slot="{ field }" name="priority">
              <PriorityItem
                v-if="!hasFeature(Feature.HIDE_PRIORITY)"
                singleton-group="task-edit"
                :loading="false"
                :inline="false"
                :model-value="field.value"
                is-clearable
                :disabled="!allowedToChange"
                @update:model-value="field.onChange"
              />
            </Field>
            <ThirdPartyButton :item="item" class="tools" />
          </div>
          <EditTaskInformation :item="initialValues" />
          <!-- SUBMIT -->
          <div class="error-list">
            <div
              v-for="(value, key) in errors"
              :key="key + value"
              class="error-item is-danger"
            >
              {{ t(`${TRANSLATION_KEY}.information.${key}_label`) }}:
              {{ value }}
            </div>
          </div>
          <div class="buttons">
            <VButton
              :loading="manager.loading.value"
              class="submit-button"
              type="submit"
            >
              {{ manager.options.okText }}
            </VButton>
          </div>
        </div>
        <div class="task-side">
          <VTabs>
            <VTabItem :title="t('tasks.information.tab_title')">
              <ActivitiesView :type="ActivityEntityType.TASK" :item="initialValues" />
            </VTabItem>
            <VTabItem :title="attachmentTitle">
              <DragArea
                :initial-value="mediaObjects"
                :editable="hasRole(UserRole.ROLE_DEPARTMENT_MANAGER) || mapToIri(item.createdBy) === authStore.user.value?.['@id']"
                class="drag-area"
                @count-changed="setCount"
              />
            </VTabItem>
            <VTabItem
              v-if="initialValues?.reservation"
              :title="t('tasks.reservation.tab_title')"
            >
              <ReservationView :reservation-iri="initialValues.reservation" />
            </VTabItem>
          </VTabs>
        </div>
      </div>
    </VForm>
  </div>
</template>

<style lang="scss" scoped>
.edit-task-container {
  display: flex;
  flex-direction: column;
  width: 1000px;
  flex: 1;
  z-index: 2; // modal header has 1
  padding-top: 15px;
  height: 100%;

  @media (max-width: 768px) {
    width: auto;

    .task-content {
      display: flex;
      max-height: none;
      flex-direction: column;
      height: 100%;

      .task-info {
        min-height: 500px;
        min-width: 100%;
      }

      .task-side {
        max-height: 600px;
      }
    }
  }

  .task-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    box-sizing: border-box;
    gap: 10px;
    height: 100%;
    width: 100%;
    position: relative;

    .task-info {
      display: flex;
      flex-direction: column;
      gap: 10px;
      flex: 1 1 100%;
      padding-left: 15px;
      padding-bottom: 15px;
      height: 100%;

      .task-info-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
      }
    }

    .task-side {
      padding-top: 15px;
      width: 50%;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;

      .drag-area {
        padding-right: 15px;
        padding-bottom: 15px;
      }
    }
  }
  .form {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: calc(100% - 35px);
  }

  .error-list {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    gap: 5px;

    .error-item {
      &.is-danger {
        color: var(--secondary);
      }
    }
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    bottom: 0;
    margin-top: 10px;

    .submit-button {
      align-self: flex-start;
    }
  }
  .tabs-container {
    display: flex;
    width: 100%;
    flex: 1;
    height: 100%;

    .tab-container:nth-child(1) {
      height: 100%;
      gap: 100px;
    }
  }
}

.picker {
  display: flex;
  gap: 10px;
  flex: 1;
}

::v-deep(.field) {
  flex: 1;
}

.icon_holder {
  flex: 1;
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
</style>
