import { computed, inject, provide, ref } from "vue";

const SidebarKey = Symbol("SidebarKey");

export function createSidebar() {
  const visible = ref(true);

  function toggleVisibility() {
    visible.value = !visible.value;
  }

  const sidebarMinWidth = computed(() => {
    return `min-width: ${visible.value ? 250 : 20}px`;
  });

  const sidebarOpacity = computed(() => {
    return `opacity: ${visible.value ? 1 : 0}`;
  });

  return {
    visible,
    sidebarOpacity,
    sidebarMinWidth,
    toggleVisibility,
  };
}

export function provideSidebar() {
  const inst = createSidebar();
  provide(SidebarKey, inst);
  return inst;
}

export function useSidebar() {
  const inst = inject(SidebarKey);

  if (!inst) {
    throw new Error("Run provideSidebar before useSidebar");
  }

  return inst;
}
