import { computed, inject, provide, ref } from "vue";
import {
  TASK_TYPE_OBJECT,
} from "../Tasks/useTaskType";
import useSystemStore from "@/features/Store/useSystemStore";

export const SORT_KEY = Symbol("SortKey");

export function createSort(initialSortField = null) {
  const systemStore = useSystemStore();
  const sortField = ref(initialSortField);

  function setSortField(field) {
    sortField.value = field;
  }

  const showObject = computed(() => {
    if (!sortField.value) {
      return false;
    }

    const sort = systemStore.sorts.value.find(s => s["@id"] === sortField.value?.value);

    if (!sort) {
      return false;
    }

    return [
      TASK_TYPE_OBJECT,
    ].includes(sort.type);
  });

  return {
    sortField,
    setSortField,
    showObject,
  };
}

export function provideSort(...params) {
  const inst = createSort(...params);
  provide(SORT_KEY, inst);
  return inst;
}

export function useSort(sortField) {
  const inst = inject(SORT_KEY);

  if (!inst) {
    throw new Error("Run provideSort before useSort.");
  }

  inst.setSortField(sortField);

  return inst;
}
