import type { Ref } from "vue";
import { inject, provide, ref } from "vue";
import type { ConfirmDialogOptions, HydraItem, SkeletonItem, Task } from "@verbleif/lib";
import type { ArgumentsType } from "vitest";
import { TaskStatus, provideProgress, useConfirmDialog } from "@verbleif/lib";
import { useToast } from "vue-toastification";
import { api } from "@/core/api";
import { useI18n } from "@/core/plugin/i18n.ts";

const MultiSelectKey = Symbol("MultiSelect");
interface MultiAssignData {
  users: string[]
  departments: string[]
  status: number
  priority: number | null
  inside: number | null
  blocking: boolean | null
  deadlineAt: string | null
  timeSensitive: boolean
}

function defaultModelValues() {
  return {
    users: [],
    departments: [],
    status: TaskStatus.OPEN,
    priority: null,
    inside: null,
    blocking: null,
    deadlineAt: null,
    timeSensitive: false,
  };
}

enum LastChangeType {
  ADDED = 0,
  REMOVED = 1,
}

enum Direction {
  DOWN = -1,
  UP = 1,
}

function createMultiSelect(items: Ref<(HydraItem<Task> | SkeletonItem)[]>, resetAndLoad: any) {
  const multiSelectIris = ref<string[]>([]);
  const modelValues = reactive<MultiAssignData>(defaultModelValues());
  const multiSelectLoading = ref(false);
  const lastIndex = ref<number | null>(null);
  const lastChangeType = ref<LastChangeType | null>(null);
  const confirmDialog = useConfirmDialog();
  const progress = provideProgress();
  const { t } = useI18n();
  const { success } = useToast();

  function addIndexToSelection(iri: string) {
    if (multiSelectIris.value.includes(iri)) {
      return;
    }

    const index = multiSelectIris.value.findIndex(v => v === iri);
    if (index !== -1) {
      return;
    }
    multiSelectIris.value.push(iri);
    lastChangeType.value = LastChangeType.ADDED;
  }

  function removeIndexToSelection(iri: string) {
    if (!multiSelectIris.value.includes(iri)) {
      return;
    }

    const index = multiSelectIris.value.findIndex(v => v === iri);
    if (index === -1) {
      return;
    }
    multiSelectIris.value.splice(index, 1);
    lastChangeType.value = LastChangeType.REMOVED;
  }

  function handleIndexChange(iri: string, changeType: LastChangeType) {
    switch (changeType) {
      case LastChangeType.ADDED:
        addIndexToSelection(iri);
        break;
      case LastChangeType.REMOVED:
        removeIndexToSelection(iri);
        break;
    }
  }

  function onItemSelect(task: HydraItem<Task>, event: MouseEvent) {
    const clickedIndex = items.value.findIndex(t => !("skeleton" in t) && t["@id"] === task["@id"]);
    if (clickedIndex === -1) {
      console.error("Clicked index not found in items.value");
      return;
    }

    const iri = task["@id"];
    if (!event.shiftKey || lastIndex.value === null || lastChangeType.value === null) {
      lastIndex.value = clickedIndex;

      // Remove it when clicked again.
      if (multiSelectIris.value.includes(iri)) {
        removeIndexToSelection(iri);

        return;
      }

      addIndexToSelection(iri);
      return;
    }

    // No need to single click an item again, just check based on the selected state
    if (multiSelectIris.value.includes(iri)) {
      lastChangeType.value = LastChangeType.REMOVED;
    } else {
      lastChangeType.value = LastChangeType.ADDED;
    }

    const subtractionMode = clickedIndex > lastIndex.value ? Direction.UP : Direction.DOWN;
    console.log(`Shift selection started, substraction mode is ${Direction[subtractionMode]}}`);
    let i = lastIndex.value;
    if (subtractionMode === Direction.DOWN) {
      while (i >= clickedIndex) {
        const item = items.value?.[i];
        let itemIri: string | null = null;

        if (("skeleton" in item)) {
          continue;
        }
        itemIri = item["@id"];

        i += subtractionMode;
        if (!itemIri) {
          continue;
        }
        handleIndexChange(itemIri, lastChangeType.value);
      }
    } else {
      while (i <= clickedIndex) {
        const item = items.value?.[i];
        let itemIri: string | null = null;

        if (("skeleton" in item)) {
          continue;
        }
        itemIri = item["@id"];

        i += subtractionMode;
        if (!itemIri) {
          continue;
        }
        handleIndexChange(itemIri, lastChangeType.value);
      }
    }

    lastIndex.value = clickedIndex;
  }

  function resetModelValues() {
    multiSelectIris.value = [];
    Object.assign(modelValues, defaultModelValues());
  }

  async function onMultiScalar(data: Partial<MultiAssignData>) {
    Object.assign(modelValues, data);
    multiSelectLoading.value = true;
    progress.setMaxProgress(multiSelectIris.value.length);
    for (const taskIri of multiSelectIris.value) {
      await api.patch(taskIri, data);
      progress.increaseProgress();
    }
    resetAndLoad();
    progress.resetProgress();
    multiSelectLoading.value = false;
    success(t("tasks.bulk_edit_success", { amount: multiSelectIris.value.length }));
    resetModelValues();
  }

  async function onMultiDelete() {
    const amount = multiSelectIris.value.length;
    const options: ConfirmDialogOptions = {
      title: t("tasks.bulk_remove_title", { amount }),
      messageIcon: ["far", "trash"],
      message: t("tasks.bulk_remove_message", { amount }),
      continueVariant: "is-danger",
      continueIcon: ["fas", "trash"],
      continueText: t("tasks.bulk_remove_confirm_btn", { amount }),
      cancelText: t("base.cancel"),
      shouldConfirm: true,
      confirmIcon: ["fas", "keyboard"],
      confirmPlaceholder: t("tasks.bulk_remove_confirm_placeholder", { amount }),
      confirmMatch: t("tasks.bulk_remove_confirm_match"),
    };
    try {
      await confirmDialog.open({ options, async: true });
    } catch (cancel) {
      return;
    } finally {
      confirmDialog.close();
    }

    progress.setMaxProgress(multiSelectIris.value.length);
    multiSelectLoading.value = true;
    for (const taskIri of multiSelectIris.value) {
      await api.delete(taskIri);
      progress.increaseProgress();
    }
    progress.resetProgress();
    resetAndLoad();
    multiSelectLoading.value = false;
    success(t("tasks.bulk_remove_success", { amount }));
  }

  async function onMultiAssignTo(input: string[]) {
    progress.setMaxProgress(multiSelectIris.value.length);
    multiSelectLoading.value = true;
    for (const taskIri of multiSelectIris.value) {
      const match = items.value.find((t) => {
        if ("skeleton" in t) {
          return false;
        }

        return t["@id"] === taskIri;
      });

      if (!match || "skeleton" in match) {
        console.error(`No match found anymore in items.value, skipping iri ${taskIri}.`);
        continue;
      }

      console.log(match);
      if (!match.users) {
        console.error(`Match was found but has no users, skipping iri ${taskIri}.`);
        continue;
      }

      if (!match.departments) {
        console.error(`Match was found but has no users, skipping iri ${taskIri}.`);
        continue;
      }

      function mapToIri(item: string | HydraItem<any>) {
        return typeof item === "string" ? item : item["@id"];
      }

      const usersMapped: string[] = match.users.map(mapToIri);
      const departmentsMapped: string[] = match.departments.map(mapToIri);

      const users: string[] = [...usersMapped];
      const departments: string[] = [...departmentsMapped];

      for (const assignment of input) {
        if (assignment.includes("/departments")) {
          if (departments.includes(assignment)) {
            break;
          }
          departments.push(assignment);
        }
        if (assignment.includes("/users")) {
          if (users.includes(assignment)) {
            break;
          }
          users.push(assignment);
        }
      }

      await api.patch(taskIri, {
        users,
        departments,
      });
      progress.increaseProgress();
    }

    resetAndLoad();
    progress.resetProgress();
    multiSelectLoading.value = false;
    success(t("tasks.bulk_assign_success", { amount: multiSelectIris.value.length }));
    resetModelValues();
  }

  return {
    progress,
    onMultiDelete,
    multiSelectIris,
    modelValues,
    resetModelValues,
    multiSelectLoading,
    onMultiAssignTo,
    onItemSelect,
    onMultiScalar,
  };
}

type MultiSelectArguments = ArgumentsType<typeof createMultiSelect>;
export function provideMultiSelect(...params: MultiSelectArguments) {
  const instance = createMultiSelect(...params);
  provide(MultiSelectKey, instance);
  return instance;
}

type MultiSelectReturn = ReturnType<typeof createMultiSelect>;
export function useMultiSelect() {
  const instance = inject<MultiSelectReturn>(MultiSelectKey);

  if (!instance) {
    throw new Error("Run provideMultiSelect before useMultiSelect.");
  }

  return instance;
}
