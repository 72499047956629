import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "inherit",
  height: "inherit",
  viewBox: "0 0 512 512"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      fill: "#DBDBDB",
      "fill-rule": "nonzero",
      class: "blocking"
    }, [
      _createElementVNode("path", { d: "M.067 191.5c0-19.1 7.585-37.4 21.093-50.9L140.6 21.15A71.98 71.98 0 0 1 191.5.066h129c19.1 0 37.4 7.586 50.9 21.084L490.8 140.6c13.5 13.5 21.1 31.8 21.1 50.9v129c0 19.1-7.6 37.4-21.1 50.9L371.4 490.8a71.98 71.98 0 0 1-50.9 21.1h-129c-19.1 0-37.4-7.6-50.9-21.1L21.15 371.4A71.97 71.97 0 0 1 .067 320.5zm55.033-17c-4.5 4.5-7.03 10.7-7.03 17v129c0 6.3 2.53 11.6 7.03 17l119.4 119.4c4.5 4.5 10.7 7 17 7h129c6.3 0 11.6-2.5 17-7l119.4-119.4c4.5-5.4 7-10.7 7-17v-129c0-6.3-2.5-12.5-7-17L337.5 55.1c-5.4-4.5-10.7-7.03-17-7.03h-129c-6.3 0-12.5 2.53-17 7.03z" }),
      _createElementVNode("path", { d: "M338.734 159.906c-2.16 0-4.28.177-6.346.517-5.186-15.424-19.785-26.57-36.935-26.57-3.493 0-6.882.464-10.106 1.33-6.882-11.13-19.163-18.558-33.175-18.558s-26.348 7.46-33.218 18.633a38.4 38.4 0 0 0-10.01-1.32c-21.478 0-39.001 17.474-39.001 38.953v65.787c-2.835-.54-5.735-.865-8.656-.865-.002 0 .001 0 0 0-23.578 0-43.287 19.043-43.287 43.335 0 10.977 4.089 21.38 11.61 29.458l43.958 47.198c21.202 22.783 51.228 35.821 82.337 35.821h17.897c57.304 0 103.885-46.581 103.885-103.875v-90.89c0-21.479-17.474-38.954-38.953-38.954M351.72 289.75c0 42.962-34.944 77.906-77.42 77.906h-18.394a86.58 86.58 0 0 1-63.353-27.57L149.4 293.76c-3.356-3.603-5.09-8.332-5.09-12.498 0-11.988 10.026-16.987 16.962-16.987 4.63 0 9.25 1.848 12.67 5.507l14.456 15.522c.907.498 2.043.876 3.125.876 2.221 0 4.363-1.716 4.363-4.336V172.89c0-7.169 5.816-12.985 12.497-12.985 7.169 0 12.498 5.816 12.498 12.985v82.234c.993 4.76 4.888 8.656 9.65 8.656 4.76 0 8.169-3.875 8.169-8.17l.486-100.033c0-7.168 5.816-12.984 12.498-12.984 7.168 0 12.497 5.816 12.497 12.984v99.547c.974 4.76 4.87 8.656 9.63 8.656s8.651-3.876 8.651-8.17l.004-82.342c0-7.168 5.816-12.984 12.498-12.984 7.168 0 12.497 5.816 12.497 12.984v82.343c.975 4.274 4.87 8.17 9.632 8.17 4.76 0 8.17-3.876 8.17-8.17v-56.753c0-7.168 5.815-12.984 12.497-12.984 7.168 0 12.497 5.816 12.497 12.984v90.891z" })
    ], -1)
  ])))
}
export default { render: render }