import { computed } from "vue";
import { useI18n } from "vue-i18n";
import type { Activity, HydraItem, User } from "@verbleif/lib";
import { ActivityTypeEnum } from "@verbleif/lib";
import { useSystemStore } from "@/features/Store/useSystemStore";
import { useStatus } from "@/features/Status/useStatus";

export function useActivityEventItem({ activity }: { activity: Activity }) {
  const { t } = useI18n();
  const { statusToText } = useStatus();
  const systemStore = useSystemStore();

  function resolveUser(iri: string | null | HydraItem<User>): null | User {
    let user: User | null = null;

    if (!iri) {
      return null;
    }

    iri = typeof iri === "string" ? iri : iri["@id"];

    if (systemStore.usersIndexedByIri.value?.[iri]) {
      user = systemStore.usersIndexedByIri.value?.[iri];
    } else if (systemStore.systemUsersIndexedByIri.value?.[iri]) {
      user = systemStore.systemUsersIndexedByIri.value?.[iri];
    }

    return user;
  }

  const message = computed(() => {
    switch (activity.type) {
      case ActivityTypeEnum.TYPE_TASK_CREATED:
        return t("tasks.activity.task_created", {
          by: resolveUser(activity.createdBy)?.fullName || t("activities.unknown_user"),
        });
      case ActivityTypeEnum.TYPE_REPORT_CREATED:
        return t("tasks.activity.report_created", {
          by: resolveUser(activity.createdBy)?.fullName || t("activities.unknown_user"),
        });
      case ActivityTypeEnum.TYPE_TASK_STATUS_CHANGED:
        return t("tasks.activity.task_status_changed", {
          by: resolveUser(activity.createdBy)?.fullName || t("activities.unknown_user"),
          from: t(statusToText(activity.meta.from)),
          to: t(statusToText(activity.meta.to)),
        });
      case ActivityTypeEnum.TYPE_REPORT_TRANSFORMED_TASK:
        return t("tasks.activity.report_transformed_task", {
          by: resolveUser(activity.createdBy)?.fullName || t("activities.unknown_user"),
        });
      case ActivityTypeEnum.TYPE_TASK_ASSIGNED:
        if (!activity.meta.removed.length) {
          return t("tasks.activity.task_assigned_added", {
            by: resolveUser(activity.createdBy)?.fullName || t("activities.unknown_user"),
            added: addedAssignees(activity),
          });
        }
        if (!activity.meta.added.length) {
          return t("tasks.activity.task_assigned_removed", {
            by: resolveUser(activity.createdBy)?.fullName || t("activities.unknown_user"),
            removed: removedAssignees(activity),
          });
        }
        return t("tasks.activity.task_assigned_added_and_removed", {
          by: resolveUser(activity.createdBy)?.fullName || t("activities.unknown_user"),
          added: addedAssignees(activity),
          removed: removedAssignees(activity),
        });
      default:
        break;
    }
    return activity.meta;
  });

  /**
   * @deprecated
   */
  function removedAssignees(activity: Activity) {
    return activity.meta.removed.map((i: any) => (i?.fullName || i?.name) || t("activities.unknown_user")).join(", ");
  }

  /**
   * @deprecated
   */
  function addedAssignees(activity: Activity) {
    return activity.meta.added.map((i: any) => (i?.fullName || i?.name) || t("activities.unknown_user")).join(", ");
  }

  return {
    message,
  };
}
