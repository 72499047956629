import moment from "moment";
import { useAuthStore } from "@verbleif/shared";
import type { Ref } from "vue";
import { TaskListType } from "@/features/Tasks/TasksRoutes";
import { FilterCondition } from "@/features/Tasks/Overview/useFilters.ts";
import useParamsHelper from "@/composables/useParamsHelper.ts";

export enum TaskParamHookApplyEnum {
  BEFORE = "before",
  AFTER = "after",
}

interface ParamHook {
  when: TaskParamHookApplyEnum
  fn: (params: URLSearchParams) => void
}

export function useTaskParams(
  currentListType: Ref<TaskListType | null>,
  currentListId: Ref<string | null>,
  hooks: ParamHook[] = [],
) {
  const authStore = useAuthStore();
  const { removeParamStartingWithAndContains } = useParamsHelper();

  function getParams(params: URLSearchParams) {
    const todayStart = moment().startOf("day");
    const todayEnd = moment().endOf("day");

    if (!params.has("order[status]")) {
      params.set("order[status]", "asc");
    }

    const customSort: Record<string, string> = {};
    params.forEach((v, k) => {
      if (!k.startsWith("order")) {
        return;
      }
      if (k === "order[status]") {
        return;
      }
      customSort[k] = v;
      params.delete(k);
    });

    // Preset if no custom sorting has been used
    if (
      Object.values(customSort).length === 0
      && currentListType.value !== TaskListType.TYPE_READY
      && currentListType.value !== TaskListType.TYPE_FINISHED
      && currentListType.value !== TaskListType.TYPE_ROUTE
    ) {
      if (!params.has("order[deadlineAt]")) {
        params.set("order[deadlineAt]", "asc");
      }
      if (!params.has("order[priority]")) {
        params.set("order[priority]", "desc");
      }
      if (!params.has("order[object.name]")) {
        params.set("order[object.name]", "asc");
      }
    }

    // Make sure order[status] is always the first.
    for (const k in customSort) {
      if (!Object.prototype.hasOwnProperty.call(customSort, k)) {
        continue;
      }
      params.append(k, customSort[k]);
    }

    for (const hook of hooks.filter(h => h.when === TaskParamHookApplyEnum.BEFORE)) {
      hook.fn(params);
    }

    const currentUser = authStore.user.value;
    if (!currentUser) {
      throw new Error("No user found in route params.");
    }

    switch (currentListType.value) {
      case TaskListType.TYPE_TEMPLATE:
        params.set(`filter[where][isTemplate][${FilterCondition.EQUAL}]`, "true");
        params.set("allowTemplates", "true");
        break;
      case TaskListType.TYPE_TODAY:
        removeParamStartingWithAndContains(params, "filter[where]", ["deadlineAt", "users"]);
        params.set(`filter[where][and][][deadlineAt][${FilterCondition.GREATER_THEN_OR_EQUAL}]`, todayStart.toISOString(true).replace(/\.\d+/, ""));
        params.set(`filter[where][and][][deadlineAt][${FilterCondition.LOWER_THEN_OR_EQUAL}]`, todayEnd.toISOString(true).replace(/\.\d+/, ""));
        params.set(`filter[where][and][][users][${FilterCondition.CONTAINS}]`, `${currentUser.id}`);
        break;
      case TaskListType.TYPE_ALL_TODAY:
        removeParamStartingWithAndContains(params, "filter[where]", "deadlineAt");
        params.set(`filter[where][and][][deadlineAt][${FilterCondition.GREATER_THEN_OR_EQUAL}]`, todayStart.toISOString(true).replace(/\.\d+/, ""));
        params.set(`filter[where][and][][deadlineAt][${FilterCondition.LOWER_THEN_OR_EQUAL}]`, todayEnd.toISOString(true).replace(/\.\d+/, ""));
        break;
      case TaskListType.TYPE_OPEN_UNTIL_TODAY:
        removeParamStartingWithAndContains(params, "filter[where]", ["deadlineAt", "users"]);
        params.set(`filter[where][and][][deadlineAt][${FilterCondition.LOWER_THEN_OR_EQUAL}]`, todayEnd.toISOString(true).replace(/\.\d+/, ""));
        params.set(`filter[where][and][][users][${FilterCondition.CONTAINS}]`, `${currentUser.id}`);
        break;
      case TaskListType.TYPE_ALL_UNTIL_TODAY:
        removeParamStartingWithAndContains(params, "filter[where]", "deadlineAt");
        params.set(`filter[where][and][][deadlineAt][${FilterCondition.LOWER_THEN_OR_EQUAL}]`, todayEnd.toISOString(true).replace(/\.\d+/, ""));
        break;
      case TaskListType.TYPE_ALL:
      case TaskListType.TYPE_CHANGEOVER_DAY:
        break;
      case TaskListType.TYPE_USER:
        if (!currentListId.value) {
          throw new Error("No user found in route params.");
        }
        params.set(`filter[where][and][][users][${FilterCondition.CONTAINS}]`, currentListId.value);
        break;
      case TaskListType.TYPE_DEPARTMENT:
        if (!currentListId.value) {
          throw new Error("No department found in route params.");
        }
        params.set(`filter[where][and][][departments][${FilterCondition.CONTAINS}]`, currentListId.value);
        break;
      case TaskListType.TYPE_ASSIGNED_TO_ME:
        params.set(`filter[where][and][][users][${FilterCondition.CONTAINS}]`, `${currentUser.id}`);
        break;
      case TaskListType.TYPE_UNASSIGNED:
        params.set(`filter[where][and][][users][${FilterCondition.EMPTY}]`, "true");
        params.set(`filter[where][and][][departments][${FilterCondition.EMPTY}]`, "true");
        break;
      case TaskListType.TYPE_READY:
        removeParamStartingWithAndContains(params, "filter[where]", "status");
        params.set(`filter[where][and][][status][${FilterCondition.EQUAL}]`, "4");
        params.set("order[updatedAt]", "desc");
        break;
      case TaskListType.TYPE_FINISHED:
        removeParamStartingWithAndContains(params, "filter[where]", "status");
        params.set(`filter[where][and][][status][${FilterCondition.EQUAL}]`, "5");
        params.set("order[updatedAt]", "desc");
        break;
      case TaskListType.TYPE_ROUTE:
        removeParamStartingWithAndContains(params, "filter[where]", ["users", "deadlineAt"]);
        params.set(`filter[where][and][][users][${FilterCondition.CONTAINS}]`, `${currentUser.id}`);
        if (!params.has(`filter[where][and][][deadlineAt][${FilterCondition.LOWER_THEN_OR_EQUAL}]`)) {
          params.set(`filter[where][and][][deadlineAt][${FilterCondition.LOWER_THEN_OR_EQUAL}]`, todayEnd.toISOString(true).replace(/\.\d+/, ""));
        }
        params.set("order[object.position]", "asc");
        params.set("order[deadlineAt]", "asc");
        params.set("order[priority]", "desc");
        params.set("order[object.name]", "asc");
        break;
      default:
        break;
    }

    for (const hook of hooks.filter(h => h.when === TaskParamHookApplyEnum.AFTER)) {
      hook.fn(params);
    }

    return params;
  }

  return {
    getParams,
  };
}
