import type { ConfirmDialogOptions } from "@verbleif/lib";
import { useConfirmDialog } from "@verbleif/lib";
import { useI18n } from "vue-i18n";
import { useToast } from "vue-toastification";
import * as Sentry from "@sentry/vue";
import api from "@/core/api";
import { useTaskTransformer } from "@/features/Tasks/useTaskTransformer";

interface UseTransferToTaskArguments {
  translationKey: string
  componentProps?: Record<string, any>
  load?: (() => Promise<any>) | null
}

export function useTransferToTask({
  translationKey,
  componentProps = {},
  load = null,
}: UseTransferToTaskArguments) {
  const { t } = useI18n();
  const { error, success } = useToast();
  const { open, close, setLoading } = useConfirmDialog();
  const { transformer } = useTaskTransformer();

  async function showTransferToTask(item: Record<string, any>) {
    const options: ConfirmDialogOptions = {
      title: "Melding omzetten naar taak",
      shouldConfirm: false,
      messageIcon: ["far", "exchange"],
      message: "We hebben nog wat extra gegevens nodig.",
      continueIcon: ["fas", "exchange"],
      continueVariant: "is-primary",
      continueText: "Omzetten",
      cancelText: t("base.cancel"),
      onContinue: (data: any) => createTask(item, data),
    };

    open({
      component: import("./TransferToTask.vue") as unknown as Promise<{ default: typeof defineComponent }>,
      options,
      componentProps: {
        ...componentProps,
        description: item.description,
      },
    });
  }

  async function createTask(report: any, data: any) {
    setLoading(true);
    const output: any = await transformer({ ...report, ...data });

    output.report = report["@id"];

    api
      .post("/tasks", output)
      .then(() => {
        success(t(`${translationKey}.report_to_task_success`));
        load && load();
      })
      .catch((e: any) => {
        let msg = "base.unknown_error";
        if (e.response) {
          msg = `base.${e.response.status}`;
        }
        Sentry.setContext("error", e);
        Sentry.captureException(e);
        error(t(msg, { errorCode: 0 }));
      })
      .finally(() => {
        setLoading(false);
        close();
      });
  }

  return {
    showTransferToTask,
  };
}
