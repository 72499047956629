import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16",
  viewBox: "0 0 512 512"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#4CAF50",
      "fill-rule": "nonzero",
      d: "m505.038 174.799-39.599-39.594c-9.4-9.399-24.6-9.399-33.9 0L192.047 374.67 80.648 263.186c-9.4-9.399-24.6-9.399-33.899 0L7.05 302.881c-9.4 9.398-9.4 24.596 0 33.995L175.046 504.95c9.4 9.399 24.599 9.399 33.9 0l295.992-296.157c9.4-9.499 9.4-24.697.1-33.995M180.746 280.783c6.2 6.3 16.399 6.3 22.599 0L411.34 72.613c6.2-6.298 6.2-16.396 0-22.596l-45.2-45.293c-6.198-6.299-16.398-6.299-22.598 0L192.046 156.202l-55.4-55.492c-6.199-6.299-16.399-6.299-22.599 0L68.75 146.003c-6.2 6.3-6.2 16.398 0 22.597z"
    }, null, -1)
  ])))
}
export default { render: render }