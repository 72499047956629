<script setup lang="ts">
import type { HydraCollection, MediaObject } from "@verbleif/lib";
import { provideModal } from "@verbleif/lib";
import ReportListSidebar from "./Overview/Sidebar/ReportListSidebar.vue";
import ReportsView from "./Overview/ReportsView.vue";
import { provideReportList } from "./Overview/useReportList";
import useRemoveReport from "./RemoveReport/RemoveReport";
import useReportTransformer from "./useReportTransformer";
import useEditReport from "@/features/Reports/EditReport/useEditReport";
import EditReport from "@/features/Reports/EditReport/EditReport.vue";
import useCreateReport from "@/features/Reports/CreateReport/useCreateReport";
import CreateReport from "@/features/Reports/CreateReport/CreateReport.vue";
import { useTransferToTask } from "@/features/Reports/TransferToTask/TransferToTask";
import api from "@/core/api.ts";

const TRANSLATION_KEY = "reports";
const modalManager = provideModal();
const { transformer } = useReportTransformer();
const visible = ref(modalManager.visible);

let load: any = null;
async function reload() {
  load && load();
}

function setLoadFunction(f: any) {
  load = f;
}

provideReportList();

const { showCreate } = useCreateReport({
  component: CreateReport,
  endpoint: "/reports",
  translationKey: TRANSLATION_KEY,
  modalManager,
  transformer,
  load: reload,
});
const { showRemove } = useRemoveReport(reload, "/reports", TRANSLATION_KEY);
const { showTransferToTask } = useTransferToTask({
  load: reload,
  translationKey: TRANSLATION_KEY,
});
const { showEdit } = useEditReport({
  component: EditReport,
  endpoint: "/reports",
  translationKey: TRANSLATION_KEY,
  modalManager,
  transformer,
  componentProps: {
    showTransferToTask,
  },
});

async function showEditInterceptor(item: any) {
  function iriToId(item: string) {
    return item.split("/").pop();
  }

  const mediaObjects = await api.get<HydraCollection<MediaObject>>(`/locations/${iriToId(item.location)}/reports/${item.id}/media_objects`)
    .then(r => r.data?.["hydra:member"] || []);

  showEdit(item, mediaObjects);
}
</script>

<template>
  <div class="reports-container">
    <ReportListSidebar />
    <ReportsView
      :set-load-function="setLoadFunction"
      @show-create="showCreate"
      @show-edit="showEditInterceptor"
      @show-remove="showRemove"
      @show-transfer-to-task="showTransferToTask"
    />
    <transition name="fade">
      <VModal v-if="visible" :headerless="true" size="is-large" />
    </transition>
  </div>
</template>

<style lang="scss" scoped>
.reports-container {
  width: 100%;
  height: 100%;
  display: flex;
}
</style>
