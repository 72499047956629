<script setup lang="ts">
import { computed, ref, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { createTimer } from "@verbleif/lib";
import type { NotificationItem } from "@/features/Notification/useNotifications";
import { iconMap } from "@/features/Notification/useNotifications";

defineOptions({
  name: "NotificationItem",
});

const props = withDefaults(defineProps<NotificationItem>(), {
  createdBy: null,
  hideAnimation: false,
  progressData: null,
  autoClose: true,
  body: null,
  buttonAction: null,
  buttonText: null,
  duration: 5000,
});

const emit = defineEmits<{
  close: [boolean]
  buttonClick: [boolean]
}>();
const {
  duration,
  hideAnimation,
  autoClose,
  progressData,
  closeable,
} = toRefs(props);
const { t } = useI18n();
const elementRef = ref<HTMLElement | null>(null);
const className = ref(hideAnimation.value ? "" : "animate__backInRight");
const timer = createTimer({
  onComplete,
  delay: duration.value,
  autoStartWhenDocumentFocus: autoClose.value,
});

function onComplete() {
  if (!closeable.value) {
    return;
  }

  className.value = "animate__backOutRight";
  setTimeout(() => emit("close", true), 500);
  timer.stop();
}

const currentProgress = computed(() => {
  if (autoClose.value) {
    return timer.remaining.value;
  }

  if (!progressData.value) {
    return null;
  }

  return progressData.value?.current;
});

const maxProgress = computed(() => {
  if (autoClose.value) {
    return duration.value;
  }

  if (!progressData.value) {
    return null;
  }

  return progressData.value.max;
});

watchEffect(() => {
  if (!elementRef.value) {
    return;
  }

  if (!autoClose.value) {
    return;
  }

  elementRef.value.addEventListener("mouseenter", () => timer.stop());
  elementRef.value.addEventListener("mouseleave", () => timer.start());
});
</script>

<template>
  <div
    ref="elementRef"
    class="notification-item animate__animated"
    :class="[className]"
    @click="onComplete"
  >
    <div class="header">
      <div class="left">
        <div class="icon">
          <FontAwesomeIcon :icon="iconMap[type]" />
        </div>
        <div class="text">
          {{ title }}
        </div>
      </div>
      <div v-if="closeable" class="right">
        <div class="icon">
          <FontAwesomeIcon :icon="['fal', 'times']" />
        </div>
      </div>
    </div>
    <div class="body">
      <div v-if="body" class="text">
        {{ body }}
      </div>
    </div>
    <div class="footer">
      <div class="left">
        <div class="text">
          {{ leftFooterText }}
        </div>
      </div>
      <div v-if="typeof buttonAction === 'function' && buttonText" class="right">
        <VButton size="is-small" @click.stop="buttonAction">
          {{ t(buttonText) }}
        </VButton>
      </div>
    </div>
    <div v-if="maxProgress !== null && currentProgress !== null" class="progress" :class="{ indeterminate: maxProgress || currentProgress }">
      <progress v-if="maxProgress || currentProgress" :max="maxProgress" :value="currentProgress" />
      <div v-else class="indeterminate-progress-bar">
        <div class="indeterminate-progress-bar__progress" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.notification-item {
  width: 325px;
  background-color: var(--background);
  box-shadow: 0 1px 4px 0 #7f7f7f;
  display: flex;
  padding: 10px;
  flex-direction: column;
  font-size: 13px;
  border-radius: 4px;
  transition: transform 1s;
  position: relative;

  .left {
    margin-right: auto;
  }

  .right {
    margin-left: auto;
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;

    .left {
      display: flex;
      align-items: center;

      .icon {
        font-size: 12px;
        margin-right: 5px;
      }

      .text {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 12px;
        color: var(--text);
      }
    }

    .right {
      .icon {
        cursor: pointer;
        font-size: 16px;
        transition: ease transform 100ms;

        &:hover {
          transform: rotate(90deg);
        }

        &:active {
          transform: rotate(90deg) scale(1.2);
        }
      }
    }

    .icon {
      color: var(--primary);
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    color: var(--text);

    .text {
      margin-bottom: 10px;
    }

    .extra {
      margin-bottom: 10px;

      .bold {
        font-weight: bold;
      }
    }
  }

  .footer {
    display: flex;
    align-items: flex-end;

    .text {
      color: var(--text);
      align-self: flex-end;
      font-size: 10px;
    }
  }

  .progress {
    position: absolute;
    left: 0;
    bottom: 0;

    &:not(.indeterminate) {
      bottom: 0;
    }

    width: 100%;
    height: 6px;

    .indeterminate-progress-bar {
      background-color: var(--grey-1);
      height: 100%;
      position: relative;
      overflow: hidden;

      .indeterminate-progress-bar__progress {
        background-color: var(--primary);
        border-radius: 20px;
        position: absolute;
        bottom: 0;
        top: 0;
        width: 25%;
        animation-duration: 1.3s;
        animation-iteration-count: infinite;
        animation-name: indeterminate-progress-bar;
      }

      @keyframes indeterminate-progress-bar {
        from {
          left: -50%;
        }
        to {
          left: 100%;
        }
      }
    }

    progress,
    progress::-webkit-progress-bar,
    progress::-webkit-progress-value {
      border-radius: 0 0 4px 4px;
    }

    progress::-webkit-progress-bar {
      background-color: var(--grey-1);
    }

    progress::-webkit-progress-value {
      background-color: var(--primary);
    }

    progress {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
