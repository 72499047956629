<script setup>
import { useI18n } from "vue-i18n";
import moment from "moment";
import { Theme, useTheme } from "@verbleif/shared";
import DeadLineTime from "@/features/Deadline/DeadlineTime.vue";

const props = defineProps({
  modelValue: {
    type: null,
    default: null,
  },
  initialValue: {
    type: null,
    default: null,
  },
  timeSensitive: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  labelLeft: {
    type: [Boolean, String],
    default: false,
  },
});

defineEmits(["update:modelValue"]);

const { t } = useI18n();
const { theme } = useTheme();
const { locale } = useI18n();

function getDate(dateVal) {
  const newDate = moment(dateVal);
  if (!dateVal) {
    return "";
  }

  return `${newDate.format(
    props.timeSensitive ? "D MMMM YYYY HH:mm" : "D MMMM YYYY",
  )}`;
}
function transformISOstring(isoDate) {
  return isoDate
    ? moment(isoDate).format(
      props.timeSensitive ? "D MMMM YYYY HH:mm:ss" : "D MMMM YYYY",
    )
    : "";
}
</script>

<template>
  <VueDatePicker
    :dark="theme === Theme.DARK"
    :model-value="modelValue"
    :locale="locale"
    cancel-text="Terug"
    select-text="Kiezen"
    :placeholder="t(`tasks.information.deadline_placeholder`)"
    :teleport="true"
    :auto-apply="true"
    @update:model-value="(v) => $emit('update:modelValue', v)"
  >
    <template #time-picker="{ time, updateTime }">
      <DeadLineTime
        :hours="time.hours"
        :minutes="time.minutes"
        :seconds="time.seconds"
        @update:hours="updateTime($event)"
        @update:minutes="updateTime($event, false)"
        @update:seconds="updateTime($event, false, true)"
      />
    </template>
    <template #action-row="{ value }">
      {{ getDate(value) }}
    </template>
    <template #dp-input="{ value, onInput }">
      <VInput
        has-new-style
        :disabled="disabled"
        autocomplete="off"
        :model-value="transformISOstring(value)"
        :placeholder="t(`tasks.information.deadline_placeholder`)"
        :label-left="labelLeft"
        icon-right="trash"
        icon-left="calendar"
        readonly
        @click="onInput"
      />
    </template>
  </VueDatePicker>
</template>

<style scoped></style>
