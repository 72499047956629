<script setup lang="ts">
import moment from "moment";
import { useActivityItem } from "./useActivityItem";
import type { ActivityEntityType } from "@/features/Activities/useActivities";

defineOptions({
  name: "ActivityItem",
});

const props = defineProps<{
  activity: any
  type: ActivityEntityType
}>();

defineEmits<{
  "update:modelValue": [any]
}>();

const { component } = useActivityItem({ activity: props.activity });
const time = moment(props.activity.createdAt).format("HH:mm");
</script>

<template>
  <template v-if="component !== null">
    <component
      :is="component"
      :activity="activity"
      :time="time"
      :type="type"
      @update:model-value="(v: any) => $emit('update:modelValue', v)"
    />
  </template>
</template>

<style scoped lang="scss"></style>
