export const BLOCKING_TRUE = true;
export const BLOCKING_FALSE = false;
export const BLOCKINGS = [BLOCKING_TRUE, BLOCKING_FALSE];
export const BLOCKING_TEXTS = {
  [BLOCKING_TRUE]: "tasks.blocking.yes",
  [BLOCKING_FALSE]: "tasks.blocking.no",
};
export const BLOCKING_CLASSES = {
  [BLOCKING_FALSE]: "",
  [BLOCKING_TRUE]: "is-blocking",
};

export function useBlocking() {
  function blockingToText(v) {
    return BLOCKING_TEXTS?.[v] || "tasks.blocking.choose";
  }

  function blockingToClass(v) {
    return BLOCKING_CLASSES?.[v] || "unknown";
  }

  return {
    blockingToClass,
    blockingToText,
  };
}
