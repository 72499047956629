import type { InternalAxiosRequestConfig } from "axios";
import type { InterceptorConfig } from "@verbleif/lib";
import { InterceptorType } from "@verbleif/lib";
import { useImpersonationStore } from "@verbleif/shared";

const impersonationStore = useImpersonationStore();

export function ImpersonationPlugin(
  excludedRoutes: string[] = [],
): InterceptorConfig<InterceptorType.REQUEST> {
  async function resolved(config: InternalAxiosRequestConfig<any>): Promise<InternalAxiosRequestConfig> {
    if (!config.url) {
      return config;
    }

    if (!impersonationStore.isImpersonating.value) {
      return config;
    }

    if (excludedRoutes.includes(config.url)) {
      return config;
    }

    Object.assign(config.headers, impersonationStore.getRequestHeaders.value);

    return config;
  }

  return {
    name: "ImpersonationPlugin",
    type: InterceptorType.REQUEST,
    priority: 10,
    resolved,
  };
}
