import { ref } from "vue";
import moment from "moment";
import { FilterCondition } from "@/features/Tasks/Overview/useFilters.ts";

export function useDateSelector() {
  const dateSelector = ref<string[] | null>(null);

  function getParams(params: URLSearchParams) {
    if (dateSelector.value) {
      const afterDate = moment(dateSelector.value[0]).startOf("day");
      if (dateSelector.value[0] && dateSelector.value[1]) {
        const beforeDate = moment(dateSelector.value[1]).endOf("day");

        params.append(`filter[where][and][][deadlineAt][${FilterCondition.GREATER_THEN_OR_EQUAL}]`, afterDate.toISOString(true).replace(/\.\d+/, ""));
        params.append(`filter[where][and][][deadlineAt][${FilterCondition.LOWER_THEN_OR_EQUAL}]`, beforeDate.toISOString(true).replace(/\.\d+/, ""));
      } else if (dateSelector.value[0]) {
        params.append(`filter[where][and][][deadlineAt][${FilterCondition.GREATER_THEN_OR_EQUAL}]`, afterDate.toISOString(true).replace(/\.\d+/, ""));
      }
    }
  }

  return {
    getParams,
    dateSelector,
  };
}
