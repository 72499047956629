<script setup lang="ts">
import { computed } from "vue";
import AssignToPlaceholder from "./AssignToPlaceholderIcon.svg?component";
import AssignmentListItem from "@/features/AssignTo/AssignmentListItem.vue";
import AvatarItem from "@/features/Avatar/Avatar.vue";
import { useI18n } from "@/core/plugin/i18n.ts";

const props = withDefaults(defineProps<{
  assignments: string[]
  disabled?: boolean
  size?: "is-small" | "is-medium" | "is-large"
  removable?: boolean
  alwaysShowPlaceholder?: boolean
  truncateAt?: number | false
}>(), {
  disabled: false,
  removable: false,
  alwaysShowPlaceholder: false,
  truncateAt: 2,
  size: "is-small",
});

const emit = defineEmits<{
  (e: "remove", item: string, event: MouseEvent): void
}>();

const i18n = useI18n();
const systemStore = useSystemStore();
const unknownUser = i18n.t("activities.unknown_user");

function resolveName(iri: string): string {
  const user = systemStore.usersIndexedByIri.value?.[iri];
  const department = systemStore.departmentsIndexedByIri.value?.[iri];
  if (user) {
    return user.fullName;
  }
  if (department) {
    return department.name;
  }

  return unknownUser;
}

function resolveAvatar(iri: string): string | null {
  const user = systemStore.usersIndexedByIri.value?.[iri];
  if (user) {
    return user.avatar;
  }

  return null;
}

const visibleItems = computed(() => {
  // Webstorm says this can be simplified but this is not true because when passing 0 it should show 0 items.
  if (props.truncateAt === false) {
    return props.assignments;
  }
  return props.assignments.slice(0, props.truncateAt);
});
const truncatedItems = computed(() => {
  // Webstorm says this can be simplified but this is not true because when passing 0 it should show 0 items.
  if (props.truncateAt === false) {
    return props.assignments;
  }
  return props.assignments.slice(props.truncateAt, props.assignments.length);
});
const truncatedNames = computed(() => {
  return truncatedItems.value.map(resolveName).join(", ");
});
</script>

<template>
  <div class="assignments-container" :class="[size, { 'is-disabled': disabled }]">
    <div
      v-if="alwaysShowPlaceholder || assignments.length === 0"
      class="placeholder"
    >
      <AssignToPlaceholder class="assign-placeholder" />
    </div>
    <VTooltip
      v-if="truncatedItems.length > 0"
      :content="truncatedNames"
      :disabled="disabled"
      placement="bottom"
    >
      <template #reference>
        <div class="assignment-container">
          <AvatarItem
            class="avatar-container"
            :size="size"
            :name="`+${truncatedItems.length}`"
            :letter-amount="2"
            :active="false"
          />
        </div>
      </template>
    </VTooltip>
    <AssignmentListItem
      v-for="(assignee, index) in visibleItems"
      :key="assignee"
      :disabled="disabled"
      :index="index"
      :name="resolveName(assignee)"
      :size="size"
      :removable="removable"
      :avatar-iri="resolveAvatar(assignee)"
      @remove="(e) => emit('remove', assignee, e)"
    />
  </div>
</template>

<style lang="scss" scoped>
.assignments-container {
  &.is-disabled {
    opacity: 0.7;
  }

  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-end;

  &:not(.is-disabled) { // Apply hover effect only if not .is-disable
    cursor: pointer;
    > {
      :hover {
        opacity: 0.7;
      }
    }
  }

  > *:not(:last-child) {
    margin-left: -7px;
  }

  .placeholder {
    width: 32px;
    height: 32px;
  }
}
</style>
