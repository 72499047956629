import { createConsola } from "consola";

const LOG_LEVEL_FATAL = 0;
const LOG_LEVEL_WARNINGS = 1;
const LOG_LEVEL_NORMAL = 2;
const LOG_LEVEL_INFO = 3;
const LOG_LEVEL_DEBUG = 4;
const LOG_LEVEL_TRACE = 5;

let level = import.meta.env.VITE_LOG_LEVEL;
console.log("LOG LEVEL", level);
if (![
  LOG_LEVEL_FATAL,
  LOG_LEVEL_DEBUG,
  LOG_LEVEL_INFO,
  LOG_LEVEL_TRACE,
  LOG_LEVEL_NORMAL,
  LOG_LEVEL_WARNINGS,
].includes(level)) {
  level = LOG_LEVEL_INFO;
}

export default createConsola({
  level: level || LOG_LEVEL_INFO,
});
