import type { I18nOptions } from "vue-i18n";
import { createI18n } from "vue-i18n";
import { useNavigatorLanguage, useStorage } from "@vueuse/core";
import { watch } from "vue";
import translations from "@/translations/index";

const navigatorLanguage = useNavigatorLanguage();
const langSplit: string | undefined = navigatorLanguage.language.value?.split("-")?.[0];
export const locale = useStorage("language", langSplit || "nl", localStorage);

const options: I18nOptions = {
  legacy: false,
  locale: locale.value,
  fallbackLocale: "nl",
  messages: translations,
  numberFormats: {
    nl: {
      currency: {
        style: "currency",
        currency: "EUR",
      },
    },
  },
};
export const i18n = createI18n<false, typeof options>(options);

watch(locale, () => {
  i18n.global.locale.value = locale.value;
});

export default i18n;

export function useI18n() {
  return i18n.global;
}
