import { TaskPriority } from "@verbleif/lib";
import PriorityInlineIcon from "./PriorityInlineIcon.svg";
import PriorityStrokedIcon from "./PriorityStrokedIcon.svg";
import PriorityPlaceholderIcon from "./PriorityPlaceholderIcon.svg";

export const PRIORITIES_TEXTS = {
  [TaskPriority.LOW]: "tasks.priority.low",
  [TaskPriority.NORMAL]: "tasks.priority.medium",
  [TaskPriority.HIGH]: "tasks.priority.high",
  [TaskPriority.URGENT]: "tasks.priority.urgent",
};
export const PRIORITIES_CLASSES = {
  [TaskPriority.LOW]: "is-low",
  [TaskPriority.NORMAL]: "is-medium",
  [TaskPriority.HIGH]: "is-high",
  [TaskPriority.URGENT]: "is-urgent",
};

export function usePriority() {
  function priorityToInlineIcon(v: TaskPriority | null) {
    if (v === null) {
      return PriorityInlineIcon;
    }

    return PriorityInlineIcon;
  }

  function priorityToStrokedIcon(v: TaskPriority | null) {
    if (v === null) {
      return PriorityPlaceholderIcon;
    }

    return PriorityStrokedIcon;
  }

  function priorityToText(v: TaskPriority) {
    return PRIORITIES_TEXTS?.[v] || "tasks.priority.none";
  }

  function priorityToClass(v: TaskPriority) {
    return PRIORITIES_CLASSES?.[v] || "is-none";
  }

  return {
    priorityToInlineIcon,
    priorityToStrokedIcon,
    priorityToText,
    priorityToClass,
  };
}
