<script>
import { useSidebar } from "@/features/Tasks/Sidebar/useSidebar";

export default {
  name: "VSidebarHeader",
  props: {
    title: {
      type: String,
      default: "title",
    },
  },
  setup() {
    const { visible, toggleVisibility } = useSidebar();

    return {
      visible,
      toggleVisibility,
    };
  },
};
</script>

<template>
  <div class="header">
    <div v-if="visible" class="title">
      {{ title }}
    </div>
    <div class="buttons">
      <FontAwesomeIcon
        v-if="visible"
        class="collapse"
        :icon="['fas', 'chevron-left']"
        @click="toggleVisibility"
      />
      <FontAwesomeIcon
        v-else
        class="collapse"
        :icon="['fas', 'chevron-right']"
        @click="toggleVisibility"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.header {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
}

.title {
  margin-left: 10px;
  color: var(--text);
  font-weight: bold;
  font-size: 25px;
}

.buttons {
  display: flex;
  margin-right: 15px;
  color: var(--text);

  .collapse {
    margin-left: 20px;

    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
}
</style>
