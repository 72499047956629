<script setup lang="ts">
import { computed, ref, toRefs } from "vue";
import { useI18n } from "vue-i18n";
import { VInput } from "@verbleif/lib";
import AssignToItem from "@/features/AssignTo/AssignToItem.vue";
import { useSystemStore } from "@/features/Store/useSystemStore";
import AssignmentList from "@/features/AssignTo/AssignmentList.vue";

export type AssignToModelValue = string[];
const props = withDefaults(defineProps<{
  modelValue: AssignToModelValue
  placement?: string
  submit?: boolean
  singletonGroup?: string
  disabled?: boolean
}>(), {
  disabled: false,
  placement: "auto",
  submit: false,
  singletonGroup: undefined,
});

const emit = defineEmits<{
  (e: "update:modelValue", v: AssignToModelValue): void
}>();
const { modelValue } = toRefs(props);
const { t } = useI18n();
const selected = ref<string[]>(modelValue.value);
const activeOnly = ref(false);
const inputEl = ref<InstanceType<typeof VInput> | null>(null);
const systemStore = useSystemStore();

const items = computed(() => {
  const items = systemStore.filteredAssignableUsersTeamsDepartments.value;

  if (activeOnly.value) {
    return items.filter(i => selected.value.includes(i["@id"]));
  }

  return items.sort((i) => {
    if (i["@type"] === "Department") {
      return -1;
    }

    return 1;
  });
});

const search = computed({
  get() {
    return systemStore.searchValue.value;
  },
  set(v) {
    systemStore.setSearchValue(v);
  },
});

const searchTypes = computed(() => {
  return systemStore.searchTypes;
});

function toggleSearchType(type: "Department" | "User") {
  systemStore.toggleSearchType(type);
}

watchEffect(() => {
  if (!inputEl.value) {
    return;
  }

  inputEl.value.$el.getElementsByTagName("input")[0].focus();
});

function clickItem(item: string, event: MouseEvent): void {
  const index = selected.value.findIndex(i => i === item);

  if (index !== -1) {
    event.stopPropagation();
    // Add stopPropagation because popper checks every click inside the document and looks if the clicked target is inside popper.
    // But when its removed, its not in the DOM list anymore, causing popper to be closed.
    selected.value.splice(index, 1);
    emit("update:modelValue", selected.value);
    return;
  }
  selected.value.push(item);
  emit("update:modelValue", selected.value);
}
</script>

<template>
  <VPopper
    trigger="clickToToggle"
    stop-propagation
    :disabled="disabled"
    append-to-body
    :options="{ placement }"
    :singleton-group="singletonGroup"
  >
    <template #reference>
      <AssignmentList
        :disabled="disabled"
        size="is-medium"
        :assignments="modelValue"
        always-show-placeholder
        :removable="!disabled"
        @remove="(item, e) => clickItem(item, e)"
      />
    </template>
    <div class="assign-to">
      <div class="search">
        <VInput
          ref="inputEl"
          v-model="search"
          :icon-left="['far', 'search']"
          autocomplete="off"
          :placeholder="t('tasks.assignTo.search')"
          variant="is-white"
        />
      </div>
      <div class="filters">
        <div
          v-for="(isEnabled, searchType) in searchTypes"
          :key="searchType"
          class="filter"
          :class="{ 'is-active': isEnabled }"
          @click="toggleSearchType(searchType)"
        >
          {{ t(`tasks.assignTo.${searchType}`, 2) }}
        </div>
        <div
          class="filter"
          :class="{ 'is-active': activeOnly }"
          @click="activeOnly = !activeOnly"
        >
          {{ t(`tasks.assignTo.activeOnly`) }}
        </div>
      </div>
      <div class="items-container">
        <div v-if="items.length" class="items-wrapper">
          <VDivider />
          <div class="items">
            <AssignToItem
              v-for="item in items"
              :key="item['@id']"
              :avatar="{
                active: true,
                name: 'fullName' in item ? item.fullName : item.name,
                subtext: t(`tasks.assignTo.${item['@type']}`),
                avatarIri: 'avatar' in item ? item.avatar : undefined,
              }"
              :class="{
                'is-selected': selected.includes(item['@id']),
              }"
              @click="(e) => clickItem(item['@id'], e)"
            />
          </div>
        </div>
        <div v-if="!items.length" class="no-items">
          Geen afdelingen en/of gebruikers gevonden.
        </div>
      </div>
      <div v-if="submit" class="buttons">
        ERROR
      </div>
    </div>
  </VPopper>
</template>

<style lang="scss" scoped>
.assign-to {
  box-shadow: rgba(0, 0, 0, 0.25) 0 1px 10px 0;
  background: var(--background-modal);
  color: var(--text);
  border-radius: 4px;
}

.buttons {
  margin: 10px;
}

.search {
  padding: 10px;
}

.items-container {
  height: 300px;
  overflow-y: auto;
}
.no-items {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: var(--text);
  font-size: 14px;
  text-align: center;
  padding: 0 10px;
}
.items-wrapper {
  &:not(:first-child) {
    margin-top: 15px;
  }
}
.filters {
  display: flex;
  flex-direction: row;
  margin: 0 10px 10px 10px;

  .filter {
    font-size: 13px;
    border-radius: 20px;
    padding: 2px 10px;
    cursor: pointer;
    user-select: none;
    color: var(--text);
    background-color: var(--input-background-active);

    &:hover {
      opacity: 0.5;
    }

    &.is-active {
      color: white;
      background-color: var(--primary);
    }

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}
</style>
