import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16",
  viewBox: "0 0 512 512"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      fill: "none",
      "fill-rule": "evenodd"
    }, [
      _createElementVNode("path", { d: "M0 0h512v512H0z" }),
      _createElementVNode("path", {
        fill: "#f44336",
        "fill-rule": "nonzero",
        d: "M255.5 257c70.424 0 127.5-57.524 127.5-128.5S325.924 0 255.5 0 128 57.524 128 128.5 185.076 257 255.5 257m89.4 31h-16.662c-22.151 10.2-46.796 16-72.738 16s-50.487-5.8-72.738-16H166.1C92.066 288 32 348.2 32 422.4V464c0 26.5 21.452 48 47.893 48h351.214C457.548 512 479 490.5 479 464v-41.6c0-74.2-60.066-134.4-134.1-134.4"
      })
    ], -1)
  ])))
}
export default { render: render }