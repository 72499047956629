<script setup lang="ts">
import { computed, ref, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import CloseIcon from "./CloseIcon.svg?component";

const props = withDefaults(defineProps<{
  inline?: boolean
  modelValue: string | number | boolean | null
  items: any
  itemToInlineIcon: (arg: any) => any
  itemToStrokedIcon: (arg: any) => any
  itemToClass: (arg: any) => any
  itemToText: (arg: any) => any
  loading: boolean
  closeOnClick?: boolean
  disabled?: boolean
  isClearable: boolean
  isChangeable: boolean
  singletonGroup?: string
}>(), {
  inline: false,
  disabled: false,
  loading: true,
  closeOnClick: true,
  isClearable: false,
  isChangeable: true,
  singletonGroup: undefined,
});

const emit = defineEmits(["update:modelValue"]);

const { t } = useI18n();
const currentItemClassName = ref("");

function updateItem(v: any, doClose: any) {
  emit("update:modelValue", v);
  props.closeOnClick && doClose();
}

const canClear = computed(() => {
  if (!props.isClearable) {
    return false;
  }

  return props.items.includes(props.modelValue);
});

watchEffect(() => {
  // Do NOT change === null to !props.currentItem since currentItem CAN be falsy.
  if (props.loading || props.modelValue === null) {
    currentItemClassName.value = "";
    return;
  }
  currentItemClassName.value = props.itemToClass(props.modelValue);
});
</script>

<template>
  <VPopper
    trigger="clickToToggle"
    append-to-body
    :options="{ placement: 'bottom-start' }"
    transition="fade"
    :disabled="loading || !isChangeable || disabled"
    prevent-default
    stop-propagation
    :singleton-group="singletonGroup"
  >
    <template #reference>
      <div
        class="reference-wrapper"
        :class="[currentItemClassName, { 'is-loading': loading }]"
      >
        <VSkeleton
          v-if="loading"
          :width="100"
          :dynamic-width="false"
          no-background
          :pill="false"
        >
          <component :is="itemToInlineIcon(modelValue)" />
        </VSkeleton>
        <template v-else>
          <VTooltip
            :disabled="!isChangeable || disabled"
            :content="t(itemToText(modelValue))"
            placement="top"
          >
            <template #reference>
              <div class="preview is-hoverable" :class="{ 'is-disabled': disabled || !isChangeable }">
                <component
                  :is="
                    inline
                      ? itemToInlineIcon(modelValue)
                      : itemToStrokedIcon(modelValue)
                  "
                />
                <CloseIcon
                  v-if="canClear && !inline"
                  class="clear-icon"
                  @click.stop="$emit('update:modelValue', null)"
                />
              </div>
            </template>
          </VTooltip>
        </template>
      </div>
    </template>
    <template #default="{ doClose }">
      <div class="changeable-item-container-popover" :class="$attrs.class">
        <template
          v-for="i in items"
          :key="i"
        >
          <template v-if="i === -1">
            <div class="divider" />
          </template>
          <template v-else>
            <div
              class="item"
              :class="[
                itemToClass(i),
                { 'is-active': currentItemClassName === itemToClass(i) },
              ]"
              @click="() => updateItem(i, doClose)"
            >
              <div class="preview" :class="itemToClass(i)">
                <component :is="itemToInlineIcon(i)" />
              </div>
              {{ t(itemToText(i)) }}
              <FontAwesomeIcon class="icon-check" :icon="['far', 'circle-check']" />
            </div>
          </template>
        </template>
        <template v-if="isClearable">
          <div class="divider" />
          <div class="item" @click="() => updateItem(null, doClose)">
            <FontAwesomeIcon class="icon" :icon="['fa', 'ban']" />
            {{ t('base.clear') }}
          </div>
        </template>
      </div>
    </template>
  </VPopper>
</template>

<style lang="scss" scoped>
.reference-wrapper {
  position: relative;

  .clear-icon {
    display: none;
    position: absolute;
    top: -7px;
    right: -7px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  .preview {
    display: flex;
    &.is-hoverable {
      &:not(.is-disabled) { // Apply hover effect only if not .is-disable
        cursor: pointer;

        &:hover {
          opacity: 0.7;

          .clear-icon {
            display: block;
          }
        }
      }
    }
  }
}

.changeable-item-container-popover {
  border-radius: 8px;
  min-width: 170px;
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
  box-shadow: 0 1px 10px var(--popper-shadow);
  background: var(--background-modal);

  .divider {
    width: 100%;
    height: 1px;
    margin: 5px 0;
    background-color: #EEEEEE;
  }

  .icon {
    font-size: 16px;
  }

  .item {
    display: flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
    color: var(--text);
    font-size: 14px;
    border-radius: 4px;
    padding: 5px;
    white-space: nowrap;
    position: relative;
    gap: 10px;

    .icon-check {
      display: none;
      color: var(--text);
    }

    &.is-active {
      font-weight: bold;

      .icon-check {
        display: initial;
        margin-left: auto;
        color: inherit;
      }
    }

    &:hover {
      background-color: var(--input-background-hover);
    }
  }
}
</style>
