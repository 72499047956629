<script setup>
import { Field, Form as VForm } from "vee-validate";
import { useI18n } from "vue-i18n";
import { Feature, useLocationStore } from "@verbleif/shared";
import { TaskStatus } from "@verbleif/lib";
import { useCount } from "../../FileUpload/useCount";
import { useRightsStore } from "@/core/store/RightStore";
import ActivitiesView from "@/features/Activities/ActivitiesView.vue";
import DragArea from "@/features/FileUpload/DragArea.vue";
import { provideSort } from "@/features/Sort/useSort";
import CreateTaskInformation from "@/features/Tasks/CreateTask/CreateTaskInformation.vue";
import useTaskSchema from "@/features/Tasks/useTaskSchema";
import StatusChangeButton from "@/features/Tasks/EditTask/StatusChangeButton.vue";
import AssignTo from "@/features/AssignTo/AssignTo.vue";
import InsideItem from "@/features/Inside/InsideItem.vue";
import PriorityItem from "@/features/Priority/PriorityItem.vue";

defineProps({
  manager: {
    type: Object,
    default: () => {
    },
  },
});
const TRANSLATION_KEY = "tasks";
const { t } = useI18n();
const { sortField } = provideSort();
const { schema } = useTaskSchema(sortField);
const mediaObjects = ref([]);
const { attachmentTitle, setCount } = useCount(TRANSLATION_KEY, mediaObjects);
const locationStore = useLocationStore();
const { hasFeature } = useRightsStore();
const { sorts } = useSystemStore();

const initialValues = {
  quantity: 1,
  timeSensitive: false,
  location: locationStore.selectedLocation.value,
  status: TaskStatus.OPEN,
  assignTo: [],
  sort: sorts.value.length === 1 ? sorts.value[0]["@id"] : null,
  blocking: hasFeature(Feature.TASK_CREATE_BLOCKING_DEFAULT_TRUE),
};
</script>

<template>
  <div class="create-task-container">
    <VForm
      v-slot="{ setFieldValue, errors }"
      :initial-values="initialValues"
      :validation-schema="schema"
      class="form"
      @submit="manager.options.onOk"
    >
      <div class="task-content">
        <div class="task-info">
          <div class="task-info-header">
            <Field v-slot="{ field }" name="status">
              <StatusChangeButton
                singleton-group="task-create"
                :model-value="field.value"
                @update:model-value="field.onChange"
              />
            </Field>
            <Field v-slot="{ field }" name="assignTo">
              <AssignTo
                singleton-group="task-create"
                :model-value="field.value"
                :loading="false"
                @update:model-value="field.onChange"
              />
            </Field>
            <Field v-slot="{ field }" name="inside">
              <InsideItem
                v-if="!hasFeature(Feature.HIDE_INSIDE)"
                singleton-group="task-create"
                :loading="false"
                :inline="false"
                :model-value="field.value"
                is-clearable
                @update:model-value="field.onChange"
              />
            </Field>
            <Field v-slot="{ field }" name="priority">
              <PriorityItem
                v-if="!hasFeature(Feature.HIDE_PRIORITY)"
                singleton-group="task-create"
                :loading="false"
                :inline="false"
                :model-value="field.value"
                is-clearable
                @update:model-value="field.onChange"
              />
            </Field>
          </div>
          <CreateTaskInformation />
          <!-- SUBMIT -->
          <div class="error-list">
            <div
              v-for="(value, key) in errors"
              :key="key + value"
              class="error-item is-danger"
            >
              {{ t(`${TRANSLATION_KEY}.information.${key}_label`) }}:
              {{ value }}
            </div>
          </div>
          <div class="buttons">
            <VButton
              :loading="manager.loading.value"
              class="submit-button"
              type="submit"
            >
              {{ manager.options.okText }}
            </VButton>
          </div>
        </div>
        <div class="task-side">
          <VTabs>
            <VTabItem :title="t(`${TRANSLATION_KEY}.information.tab_title`)">
              <ActivitiesView
                :type="ActivityEntityType.TASK"
                @queue-change="(d) => setFieldValue('activities', d)"
              />
            </VTabItem>
            <VTabItem :title="attachmentTitle">
              <DragArea class="drag-area" @count-changed="setCount" />
            </VTabItem>
          </VTabs>
        </div>
      </div>
    </VForm>
  </div>
</template>

<style lang="scss" scoped>
.create-task-container {
  display: flex;
  flex-direction: column;
  width: 1000px;
  flex: 1;
  z-index: 2; // modal header has 1
  padding-top: 15px;
  height: 100%;

  @media (max-width: 768px) {
    width: auto;

    .task-content {
      display: flex;
      max-height: none;
      flex-direction: column;
      height: 100%;

      .task-info {
        min-height: 500px;
        min-width: 100%;
      }

      .task-side {
        max-height: 600px;
      }
    }
  }

  .task-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    box-sizing: border-box;
    gap: 10px;
    height: 100%;
    width: 100%;
    position: relative;

    .task-info {
      display: flex;
      flex-direction: column;
      gap: 10px;
      flex: 1 1 100%;
      padding-left: 15px;
      padding-bottom: 15px;
      height: 100%;

      .task-info-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
      }
    }

    .task-side {
      padding-top: 15px;
      width: 50%;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;

      .drag-area {
        padding-right: 15px;
        padding-bottom: 15px;
      }
    }
  }

  .form {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: calc(100% - 35px);
  }

  .error-list {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    gap: 5px;

    .error-item {
      &.is-danger {
        color: var(--secondary);
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    bottom: 0;
    margin-top: 10px;

    .submit-button {
      align-self: flex-start;
    }
  }

  .tabs-container {
    display: flex;
    width: 100%;
    flex: 1;
    height: 100%;

    .tab-container:nth-child(1) {
      height: 100%;
      gap: 100px;
    }
  }
}

.picker {
  display: flex;
  gap: 10px;
  flex: 1;
}

::v-deep(.field) {
  flex: 1;
}

.icon_holder {
  flex: 1;
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
</style>
