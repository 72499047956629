<script>
import { computed, defineComponent, inject } from "vue";
import { DeadlineSymbol } from "@/features/Deadline/DeadlineSymbol";

export default defineComponent({
  props: {
    hoursIncrement: {
      type: [Number, String],
      default: 1,
    },
    minutesIncrement: {
      type: [Number, String],
      default: 1,
    },
    is24: {
      type: Boolean,
      default: true,
    },
    hoursGridIncrement: {
      type: [String, Number],
      default: 1,
    },
    minutesGridIncrement: {
      type: [String, Number],
      default: 5,
    },
    range: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
    minTime: {
      type: Object,
      default: () => ({}),
    },
    maxTime: {
      type: Object,
      default: () => ({}),
    },
    timePicker: {
      type: Boolean,
      default: false,
    },
    hours: {
      type: [Number, Array],
      default: 0,
    },
    minutes: {
      type: [Number, Array],
      default: 0,
    },
    customProps: {
      type: Object,
      default: null,
    },
  },
  emits: ["update:hours", "update:minutes"],
  setup() {
    const { timeSensitive, toggleTimeSensitive } = inject(DeadlineSymbol);

    // Generate array of hours
    const hoursArray = computed(() => {
      const arr = [];
      for (let i = 0; i < 24; i++) {
        arr.push({
          text: i < 10 ? `0${i}` : i,
          value: i,
        });
      }
      return arr;
    });
    // Generate array of minutes
    const minutesArray = computed(() => {
      const arr = [];
      for (let i = 0; i < 60; i++) {
        arr.push({
          text: i < 10 ? `0${i}` : i,
          value: i,
        });
      }
      return arr;
    });

    return {
      hoursArray,
      minutesArray,
      toggleTimeSensitive,
      timeSensitive,
    };
  },
});
</script>

<template>
  <div v-if="timeSensitive" class="custom-time-picker-component">
    <select
      class="select-input"
      :value="hours"
      @change="$emit('update:hours', +$event.target.value)"
    >
      <option v-for="h in hoursArray" :key="h.value" :value="h.value">
        {{ h.text }}
      </option>
    </select>
    <select
      class="select-input"
      :value="minutes"
      @change="$emit('update:minutes', +$event.target.value)"
    >
      <option v-for="m in minutesArray" :key="m.value" :value="m.value">
        {{ m.text }}
      </option>
    </select>
    <FontAwesomeIcon
      icon="times"
      class="close-time"
      @click="toggleTimeSensitive"
    />
  </div>
  <div v-else class="enable_time" @click="toggleTimeSensitive">
    <div class="time_selector">
      <FontAwesomeIcon icon="clock" class="time" />
      <div>Tijd toevoegen</div>
    </div>
    <FontAwesomeIcon icon="times" class="close" />
  </div>
</template>

<style lang="scss">
.custom-time-picker-component {
  display: flex;
  align-items: center;
  justify-content: center;
}

.select-input {
  margin: 5px 3px;
  padding: 5px;
  width: 100px;
  border-radius: 4px;
  border-color: var(--dp-border-color);
  outline: none;
}
.close-time {
  margin-left: 5px;
  color: var(--primary);
  &:hover {
    cursor: pointer;
  }
}
.enable_time {
  margin: 10px;
  &:hover {
    cursor: pointer;
    background: var(--input-background-hover);
  }
  .time_selector {
    display: flex;
    gap: 10px;
  }
  .close,
  .time {
    color: var(--primary);
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  background-color: var(--input-background);
  color: var(--input-text);
  height: 36px;
  border-radius: 50px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}
</style>
