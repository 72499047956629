<script>
export default {
  name: "NoNotifications",
  props: {
    text: {
      type: String,
      default: "No notifications yet. Nothing to see here.",
    },
  },
};
</script>

<template>
  <div class="no-tasks-container">
    <svg
      id="notificaties"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 708.66 708.66"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            class="cls-1"
            d="M526.34,407c-20.73-3.89-25,1.77-33.42-4.2s-40.37-47.06-52.09-76.06v0A384.5,384.5,0,0,0,428.43,280C408,218,375.73,194.63,327.27,193.5a26.46,26.46,0,1,0-51.12,13.65c-30.95,18.1-54,51.72-50.37,85.81,3.36,31.89,8.81,58.88,15.76,81.69l-.25-.06s13.39,69.75-1.71,90.56-29.82,24.94-31.47,27.75-5.85,20.6,1.16,26.07,7.85,11,40.91,2.24l37.84-10a32.56,32.56,0,1,0,62.77-16.61L364.36,491a66.09,66.09,0,0,0,28.83-6.85c46.92-11.31,135.1-33.3,139.91-39.93C539.68,435.16,547.07,410.85,526.34,407Z"
          />
        </clipPath>
      </defs>
      <circle class="cls-2" cx="241.29" cy="257.27" r="207.88" />
      <circle class="cls-2" cx="503.3" cy="206.3" r="138.94" />
      <circle class="cls-2" cx="405.18" cy="406.97" r="252.3" />
      <path
        class="cls-3"
        d="M526.34,407c-20.73-3.89-25,1.77-33.42-4.2s-40.37-47.06-52.09-76.06v0A384.5,384.5,0,0,0,428.43,280C408,218,375.73,194.63,327.27,193.5a26.46,26.46,0,1,0-51.12,13.65c-30.95,18.1-54,51.72-50.37,85.81,3.36,31.89,8.81,58.88,15.76,81.69l-.25-.06s13.39,69.75-1.71,90.56-29.82,24.94-31.47,27.75-5.85,20.6,1.16,26.07,7.85,11,40.91,2.24l37.84-10a32.56,32.56,0,1,0,62.77-16.61L364.36,491a66.09,66.09,0,0,0,28.83-6.85c46.92-11.31,135.1-33.3,139.91-39.93C539.68,435.16,547.07,410.85,526.34,407Z"
      />
      <g id="Laag_5" data-name="Laag 5">
        <g class="cls-4">
          <path
            class="cls-5"
            d="M222.07,483.39,394.58,438s-115.33,30.37-145.1-19.73C233.94,392.06,222.07,483.39,222.07,483.39Z"
          />
        </g>
      </g>
      <g class="cls-4">
        <polygon
          class="cls-6"
          points="361.61 491.96 278.81 513.63 289.61 542.21 356.36 554.21 374.73 516.21 361.61 491.96"
        />
      </g>
      <g class="cls-4">
        <path
          class="cls-7"
          d="M276.15,207.15s39.33-18,60.33-17.61S273.32,143,273.32,143l-9.46,53Z"
        />
      </g>
      <path class="cls-8" d="M425.65,269.11a101.61,101.61,0,1,0-196.47,52" />
      <path class="cls-8" d="M425.65,269.11S460.84,420.43,515.42,406" />
      <path class="cls-8" d="M229.18,321.08S273.42,470,218.85,484.45" />
      <path
        class="cls-8"
        d="M232,526,524.5,448.64c11.85-3.14,19-15.17,16-26.75h0c-3.07-11.58-15.27-18.49-27.12-15.35"
      />
      <path
        class="cls-8"
        d="M524.5,448.64,232,526c-11.85,3.13-24-3.78-27.12-15.35h0c-3.06-11.58,4.13-23.62,16-26.75"
      />
      <line class="cls-8" x1="306.87" y1="461.17" x2="394.58" y2="437.96" />
      <line class="cls-8" x1="427.32" y1="429.3" x2="450.71" y2="423.11" />
      <path class="cls-8" d="M327.93,192.65a26.7,26.7,0,0,0-51.62,13.65" />
      <path class="cls-8" d="M286.79,513.27A33.89,33.89,0,1,0,352,494.58" />
      <path class="cls-9" d="M273.32,246.15s-19,15-18.91,43" />
      <circle class="cls-8" cx="255" cy="303.48" r="0.62" />
      <line class="cls-8" x1="490.17" y1="124.58" x2="490.17" y2="321.08" />
      <line class="cls-8" x1="524.5" y1="200.79" x2="524.5" y2="297.56" />
      <line class="cls-8" x1="175.66" y1="164.42" x2="175.66" y2="327.88" />
      <path
        class="cls-10"
        d="M201.45,49.39c-25.8,0-25.79,25.78-25.79,25.78,0-25.78-25.79-25.78-25.79-25.78,25.79,0,25.79-25.79,25.79-25.79C175.66,48.44,201.45,49.39,201.45,49.39Z"
      />
      <path
        class="cls-11"
        d="M603.48,349.51c-25.8,0-25.78,25.79-25.78,25.79,0-25.79-25.79-25.79-25.79-25.79,25.79,0,25.79-25.78,25.79-25.78C577.7,348.57,603.48,349.51,603.48,349.51Z"
      />
      <path
        class="cls-12"
        d="M182.54,623.32c-25.8,0-25.78,25.79-25.78,25.79,0-25.79-25.79-25.79-25.79-25.79,25.79,0,25.79-25.79,25.79-25.79C156.76,622.38,182.54,623.32,182.54,623.32Z"
      />
      <path
        class="cls-13"
        d="M571.74,560c-19.84,0-19.83,19.83-19.83,19.83,0-19.83-19.83-19.83-19.83-19.83,19.83,0,19.83-19.83,19.83-19.83C551.91,559.24,571.74,560,571.74,560Z"
      />
      <path
        class="cls-14"
        d="M144.84,304.24C125,304.24,125,324.07,125,324.07c0-19.83-19.83-19.83-19.83-19.83,19.83,0,19.83-19.83,19.83-19.83C125,303.52,144.84,304.24,144.84,304.24Z"
      />
    </svg>
    <div class="text">
      {{ text }}
    </div>
    <div class="slot">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.no-tasks-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 50px;

  svg {
    width: 150px;
  }

  .text {
    width: 50%;
    text-align: center;
    margin-top: 25px;
    color: var(--text);
  }

  .slot {
    margin-top: 25px;
  }
}

.cls-1,
.cls-10,
.cls-11,
.cls-12,
.cls-8,
.cls-9 {
  fill: none;
}

.cls-2 {
  fill: var(--primary-lighter);
}

.cls-3 {
  fill: #fff;
}

.cls-4 {
  clip-path: url(#clip-path);
}

.cls-5 {
  fill: var(--primary-light);
}

.cls-6 {
  fill: var(--primary);
}

.cls-7 {
  fill: var(--primary);
}

.cls-8,
.cls-9 {
  stroke: var(--primary-dark);
  stroke-miterlimit: 10;
}

.cls-10,
.cls-11,
.cls-12,
.cls-8,
.cls-9 {
  stroke-linecap: round;
}

.cls-8 {
  stroke-width: 7px;
}

.cls-9 {
  stroke-width: 8px;
}

.cls-10 {
  stroke: var(--secondary);
}

.cls-10,
.cls-11,
.cls-12 {
  stroke-linejoin: round;
  stroke-width: 4px;
}

.cls-11 {
  stroke: var(--secondary-dark);
}

.cls-12 {
  stroke: var(--secondary);
}

.cls-13 {
  fill: var(--secondary-light);
}

.cls-14 {
  fill: var(--secondary-dark);
}
</style>
