import prettyBytes from "pretty-bytes";
import { computed } from "vue";
import { useExtensions } from "@/features/FileUpload/useExtensions";
import { useMediaObject } from "@/features/FileUpload/useMediaObject";

export function useUploadingFileItem(props, { emit }) {
  const { uploadFile, status, statusText } = useMediaObject();
  const { resolveExtension } = useExtensions();

  uploadFile(props.file).then(uploadSuccess);

  function retry() {
    uploadFile(props.file).then(uploadSuccess);
  }

  const size = computed(() => {
    return prettyBytes(props.file.size);
  });

  function uploadSuccess(mediaObject) {
    emit("upload", mediaObject);
  }

  const extension = computed(() => {
    return resolveExtension(props.file.type);
  });

  const previewUrl = computed(() => {
    if (extension.value.ext !== "image") {
      return false;
    }
    return URL.createObjectURL(props.file);
  });

  return {
    retry,
    status,
    extension,
    previewUrl,
    size,
    statusText,
  };
}
