import nl from "./nl";
import en from "./en";
import pl from "./pl";
import de from "./de";
import es from "./es";
import fr from "./fr";
import tr from "./tr";
import ro from "./ro";

export default {
  nl,
  en,
  pl,
  de,
  es,
  fr,
  tr,
  ro,
};
