<script setup lang="ts">
import { useAuthStore, useLocationStore } from "@verbleif/shared";
import type { HydraCollection, HydraItem, Objects } from "@verbleif/lib";
import { provideConfirmDialog } from "@verbleif/lib";
import { Crisp } from "crisp-sdk-web";
import NavbarContainer from "@/features/Navbar/NavbarContainer.vue";
import NotificationSidebar from "@/features/NotificationSidebar/NotificationSidebar.vue";
import Loader from "@/features/Loader/LoaderView.vue";
import Notifications from "@/features/Notification/NotificationList.vue";
import { useSystemStore } from "@/features/Store/useSystemStore";
import { useLoader } from "@/features/Loader/useLoader";
import api from "@/core/api";
import refreshToken from "@/core/refreshToken";
import i18n from "@/core/plugin/i18n.ts";

// useConfigStore();
const route = useRoute();
const authStore = useAuthStore();
const systemStore = useSystemStore();
const locationStore = useLocationStore();
const loaderProvider = useLoader();
provideConfirmDialog();
try {
  Crisp.configure("3df3ab2f-fe2a-4c89-ad4a-43965e928246", {
    autoload: false,
    sessionMerge: true,
    locale: i18n.global.locale.value ?? "nl",
  });
} catch (e) {
  console.error(e);
}

const hideNavbar = computed(
  () => route.path === "/" || route.meta.hideNavbar === true,
);
const isPublicRoute = computed(
  () => route.path === "/" || route.meta.public === true,
);
const globalLoading = ref(true);

// Always reset the system store when switching locations.
watch([locationStore.selectedLocation, authStore.isAuthenticated], ([newLocation, isAuthenticated]) => {
  consola.debug("App.vue: locationStore.selectedLocation changed / immediate to: ", newLocation);
  if (!newLocation) {
    return;
  }

  systemStore.reset();
  if (isAuthenticated) {
    consola.debug("App.vue: loadApiBasics() since isAuthenticated is true.");
    loadApiBasics();
  }
}, { immediate: true });

watch(() => authStore.client.value?.["@id"], (v) => {
  if (!v) {
    return;
  }
  api.get(
      `${v}/media_objects/avatars?pagination=false`,
  ).then((r) => {
    systemStore.setAvatars(r.data["hydra:member"]);

    try {
      if (authStore.user.value?.avatar && systemStore.avatarsUrlIndexedByIri.value?.[authStore.user.value?.avatar]) {
        Crisp.user.setAvatar(systemStore.avatarsUrlIndexedByIri.value[authStore.user.value?.avatar]);
      }
    } catch (e) {
      console.error(e);
    }
  });
});

function loadApiBasics() {
  api.get(
    `${locationStore.selectedLocation.value}/departments?pagination=false&order[name]=asc&active=1`,
  ).then(r => systemStore.setDepartments(r.data["hydra:member"]));
  api.get(
    `${locationStore.selectedLocation.value}/users?pagination=false&order[name]=asc&active=1`,
  ).then(r => systemStore.setUsers(r.data["hydra:member"]));
  api.get(
    "/api/system_users",
  ).then(r => systemStore.setSystemUsers(r.data["hydra:member"]));
  api.get(
    `${locationStore.selectedLocation.value}/sorts?pagination=false&order[name]=asc&active=1`,
  ).then(r => systemStore.setSorts(r.data["hydra:member"]));
  api.get(
    `${locationStore.selectedLocation.value}/groups?pagination=false&order[name]=asc&active=1`,
  ).then(r => systemStore.setGroups(r.data["hydra:member"]));
  syncObjects();
}

async function syncObjects(page = 1, sub = false): Promise<HydraItem<Objects>[]> {
  const objects: HydraItem<Objects>[] = [];
  const firstPage = await api.get<HydraCollection<HydraItem<Objects>>>(
    `${locationStore.selectedLocation.value}/objects?page=${page}&perPage=100&order[name]=asc&active=1`,
  ).then(r => r.data);

  const items = firstPage["hydra:member"];
  objects.push(...items);
  const itemCount = items.length;
  if (itemCount === 100) {
    objects.push(...await syncObjects(page + 1, true));
  }

  if (!sub) {
    systemStore.setObjects(objects);
  }

  return objects;
}

if (!authStore.hasToken.value && !authStore.isRemembered.value) {
  authStore.setUser(null); // Reset it to be sure.
  globalLoading.value = false;
  loaderProvider.hide();
}

if (authStore.hasToken.value || authStore.isRemembered.value) {
  refreshToken()
    .finally(() => {
      globalLoading.value = false;
      loaderProvider.hide();
    });
}

const loading = computed(() => loaderProvider.loading.value);
</script>

<template>
  <transition name="fade">
    <Loader v-if="loading || globalLoading" />
  </transition>
  <Notifications />
  <template v-if="!globalLoading">
    <template v-if="!isPublicRoute && !hideNavbar">
      <NavbarContainer :class="{ 'is-visible': !hideNavbar }" class="navbar" />
      <transition name="slide-in">
        <NotificationSidebar />
      </transition>
      <!-- GLOBAL COMPONENTS THAT RELY ON A USER STATE SHOULD BE PLACED HERE -->
    </template>
    <div class="app-container" :class="{ 'is-login-route': !hideNavbar }">
      <router-view v-slot="{ Component }">
        <transition mode="out-in" name="fade">
          <component :is="Component" />
        </transition>
        <transition name="fade">
          <VConfirmDialog />
        </transition>
      </router-view>
    </div>
  </template>
</template>

<style src="@verbleif/lib/src/scss/Reset.css"></style>

<style src="@verbleif/lib/src/scss/Fonts.css"></style>

<style src="@verbleif/lib/src/scss/Toast.css"></style>

<style lang="scss" src="./main.scss" />
