import { useI18n } from "vue-i18n";
import { useToast } from "vue-toastification";
import * as Sentry from "@sentry/vue";
import type { Component } from "vue";
import type { ModalManager } from "@verbleif/lib";
import { api } from "@/core/api";

interface UseCreateArguments {
  component: Component
  endpoint: string
  translationKey: string
  modalManager: ModalManager
  transformer: ((input: any) => Promise<any>)
  load?: (() => Promise<any>) | null
}

export function useCreateReport({
  component,
  endpoint,
  translationKey,
  modalManager,
  transformer,
  load = null,
}: UseCreateArguments) {
  const { t } = useI18n();
  const { success, error } = useToast();

  function showCreate() {
    modalManager.open({
      component,
      opts: {
        okIcon: ["fas", "plus"],
        okText: t("base.create"),
        cancelText: t("base.cancel"),
        onOk: input => create(input),
      },
    });
  }

  async function createComments({ reportIri, activities }: any) {
    for (const activity of activities) {
      const clone: any = { ...activity };
      delete clone.id;
      await api
        .post("/activities", { ...clone, report: reportIri })
        .catch(e => console.error(e));
    }
  }

  async function create(input: any) {
    modalManager.setLoading(true);

    const output = await transformer(input);
    let toastId: any = "1";
    return api
      .post(endpoint, output)
      .then(async (r) => {
        await createComments({
          reportIri: r.data["@id"],
          activities: input.activities || [],
        });
        toastId = success(t(`${translationKey}.create_success`));
        modalManager.close();
      })
      .catch((e) => {
        let msg = "base.unknown_error";
        if (e.response) {
          msg = `base.${e.response.status}`;
        }
        error(t(msg, { errorCode: 0 }), { id: toastId });
        Sentry.setContext("error", e);
        Sentry.captureException(e);
      })
      .finally(() => {
        modalManager.setLoading(false);
        load && load();
      });
  }

  return {
    showCreate,
    create,
  };
}

export default useCreateReport;
