import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 512 512"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#8e24aa",
      d: "m249.4 103.4-45.2-45.1c-.3.3-.6.7-.9 1l-64 64c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l64-64c.3-.3.7-.6 1-.9L161.9 16c-18.7-18.7-49.1-18.7-67.8 0L16 94.1c-18.7 18.7-18.7 49.1 0 67.9l87.4 87.4zm159.2 159.2-146 146 87.5 87.4c18.7 18.7 49.1 18.7 67.9 0l78-78.1c18.7-18.7 18.7-49.1 0-67.9l-19.8-19.8c-.3.3-.6.7-.9 1l-64 64c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l64-64c.3-.3.7-.6 1-.9z",
      class: "fa-secondary",
      opacity: ".4"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#8e24aa",
      d: "m378.7 19.3-56.4 56.4 114 114 56.4-56.4c25-25 25-65.5 0-90.5l-23.4-23.5c-25-25-65.5-25-90.5 0zm-79 79L44.9 353.2c-6.7 6.7-11.6 15.1-14.2 24.2L.9 481.4c-2.4 8.4 0 17.4 6.1 23.6s15.2 8.5 23.6 6.1l104-29.7c9.2-2.6 17.5-7.5 24.2-14.2l254.9-254.9z",
      class: "fa-primary"
    }, null, -1)
  ])))
}
export default { render: render }