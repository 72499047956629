<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { Feature, UserRole } from "@verbleif/shared";
import { TaskPriority } from "@verbleif/lib";
import { useReportList } from "../useReportList";
import Folder from "./folder.svg?component";
import FolderOpen from "./folder-open.svg?component";
import Globe from "./globe.svg?component";
import { useRightsStore } from "@/core/store/RightStore";
import VSidebar from "@/features/Tasks/Sidebar/VSidebar.vue";
import VSidebarItem from "@/features/Tasks/Sidebar/VSidebarItem.vue";
import VSidebarItemActions from "@/features/Tasks/Sidebar/VSidebarItemActions.vue";
import VSidebarItemNew from "@/features/Tasks/Sidebar/VSidebarItemNew.vue";
import VSidebarSection from "@/features/Tasks/Sidebar/VSidebarSection.vue";
import PriorityItem from "@/features/Priority/PriorityItem.vue";
import ConnectIcon from "@/features/Auth/OAuth/Thirdparties/ConnectIcon.vue";
import { useSystemStore } from "@/features/Store/useSystemStore";
import { ReportListType } from "@/features/Reports/ReportsRoutes";

const { t } = useI18n();
const systemStore = useSystemStore();
const { hasFeature, hasRole } = useRightsStore();

const {
  reportLists,
  createReportList,
  updateReportList,
  filteredSorts,
  deleteReportList,
} = useReportList();
</script>

<template>
  <VSidebar :title="t('reports.sidebar.title')">
    <VSidebarSection
      v-slot="{ toggle }"
      v-model="systemStore.reportSidebar.shortcuts"
      :title="t('reports.sidebar.shortcuts')"
    >
      <VSidebarItem
        :to="{
          name: 'report-list',
          params: { type: ReportListType.TYPE_CREATED_BY_ME },
        }"
        :text="t('reports.sidebar.created_by_me')"
        :icon="Folder"
        :icon-active="FolderOpen"
        @toggle-tree="toggle"
      />
    </VSidebarSection>
    <VSidebarSection
      v-if="hasFeature(Feature.CUSTOM_REPORT_LISTS)"
      v-slot="{ toggle }"
      v-model="systemStore.reportSidebar.customLists"
      :title="t('reports.sidebar.myLists')"
    >
      <div v-if="reportLists.length">
        <VSidebarItem
          v-for="(reportList, index) in reportLists"
          :key="reportList.id"
          :to="{
            name: 'report-list',
            params: { type: ReportListType.TYPE_CUSTOM, id: reportList.id },
          }"
          :text="reportList.name"
          :icon="Folder"
          :icon-active="FolderOpen"
          :is-placeholder="reportList.isPlaceholder"
          editable
          @toggle-tree="toggle"
          @update:text="
            (name) => updateReportList(reportList.id, index, { name })
          "
        >
          <template #actions>
            <VSidebarItemActions>
              <VDropdownItem
                :text="t('tasks.sidebar.actionRemove')"
                :icon="['fas', 'trash']"
                class="is-danger"
                @click="() => deleteReportList(reportList.id, index)"
              />
            </VSidebarItemActions>
          </template>
        </VSidebarItem>
      </div>
      <VSidebarItemNew
        :text="t('reports.sidebar.createNewList')"
        @create="() => createReportList(t('reports.sidebar.createNewList'))"
      />
    </VSidebarSection>
    <VSidebarSection
      v-if="hasRole(UserRole.ROLE_DEPARTMENT_MANAGER)"
      v-slot="{ toggle }"
      v-model="systemStore.reportSidebar.needsAssignment"
      :title="t('reports.sidebar.needs_assignment')"
    >
      <VSidebarItem
        :to="{
          name: 'report-list',
          params: { type: ReportListType.TYPE_UNASSIGNED_ALL },
        }"
        :icon="Globe"
        :text="t('reports.sidebar.assign_all')"
        @toggle-tree="toggle"
      />
      <template v-if="!hasFeature(Feature.HIDE_PRIORITY)">
        <VSidebarItem
          :to="{
            name: 'report-list',
            params: { type: ReportListType.TYPE_UNASSIGNED_URGENT_PRIO },
          }"
          :text="t('reports.sidebar.assign_urgent_prio')"
          :icon="true"
          @toggle-tree="toggle"
        >
          <template #icon>
            <PriorityItem
              :loading="false"
              :is-changeable="false"
              :model-value="TaskPriority.URGENT"
            />
          </template>
        </VSidebarItem>
        <VSidebarItem
          :to="{
            name: 'report-list',
            params: { type: ReportListType.TYPE_UNASSIGNED_HIGH_PRIO },
          }"
          :text="t('reports.sidebar.assign_high_prio')"
          :icon="true"
          @toggle-tree="toggle"
        >
          <template #icon>
            <PriorityItem
              :loading="false"
              :is-changeable="false"
              :model-value="TaskPriority.HIGH"
            />
          </template>
        </VSidebarItem>
        <VSidebarItem
          :to="{
            name: 'report-list',
            params: { type: ReportListType.TYPE_UNASSIGNED_MED_PRIO },
          }"
          :text="t('reports.sidebar.assign_med_prio')"
          :icon="true"
          @toggle-tree="toggle"
        >
          <template #icon>
            <PriorityItem
              :loading="false"
              :is-changeable="false"
              :model-value="TaskPriority.NORMAL"
            />
          </template>
        </VSidebarItem>
        <VSidebarItem
          :to="{
            name: 'report-list',
            params: { type: ReportListType.TYPE_UNASSIGNED_LOW_PRIO },
          }"
          :text="t('reports.sidebar.assign_low_prio')"
          :icon="true"
          @toggle-tree="toggle"
        >
          <template #icon>
            <PriorityItem
              :loading="false"
              :is-changeable="false"
              :model-value="TaskPriority.LOW"
            />
          </template>
        </VSidebarItem>
        <VSidebarItem
          :to="{
            name: 'report-list',
            params: { type: ReportListType.TYPE_UNASSIGNED_NONE_PRIO },
          }"
          :text="t('reports.sidebar.assign_none_prio')"
          :icon="true"
          @toggle-tree="toggle"
        >
          <template #icon>
            <PriorityItem
              :loading="false"
              :is-changeable="false"
              :model-value="null"
            />
          </template>
        </VSidebarItem>
      </template>
    </VSidebarSection>
    <VSidebarSection
      v-if="hasFeature(Feature.PUBLIC_REPORTS)"
      v-slot="{ toggle }"
      v-model="systemStore.reportSidebar.thirdParty"
      :title="t('reports.sidebar.third_party')"
    >
      <VSidebarItem
        v-if="hasFeature(Feature.PUBLIC_NOTIFICATIONS_GREAT_STAY_APP)"
        :to="{
          name: 'report-list',
          params: { type: ReportListType.TYPE_THIRD_PARTY, id: 'great_stay_app' },
        }"
        :text="t('reports.sidebar.third_party_great_stay_app')"
        :icon="true"
        @toggle-tree="toggle"
      >
        <template #icon>
          <ConnectIcon
            non-absolute
            no-background
            third-party-name="great_stay_app"
          />
        </template>
      </VSidebarItem>
      <VSidebarItem
        v-if="hasFeature(Feature.PUBLIC_NOTIFICATIONS_WEMA)"
        :to="{
          name: 'report-list',
          params: { type: ReportListType.TYPE_THIRD_PARTY, id: 'wema_mobile' },
        }"
        :text="t('reports.sidebar.third_party_wema_mobile')"
        :icon="true"
        @toggle-tree="toggle"
      >
        <template #icon>
          <ConnectIcon
            non-absolute
            no-background
            third-party-name="wema_mobile"
          />
        </template>
      </VSidebarItem>
    </VSidebarSection>
    <VSidebarSection
      v-if="filteredSorts.length"
      v-slot="{ toggle }"
      v-model="systemStore.reportSidebar.sorts"
      :title="t('reports.sidebar.per_sort')"
    >
      <VSidebarItem
        v-for="sort in filteredSorts"
        :key="sort.id"
        :to="{
          name: 'report-list',
          params: { type: ReportListType.TYPE_SORT, id: sort.id },
        }"
        :text="sort.name"
        :icon="Folder"
        :icon-active="FolderOpen"
        @toggle-tree="toggle"
      />
    </VSidebarSection>
  </VSidebar>
</template>

<style scoped></style>
