<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { Feature, UserRole } from "@verbleif/shared";
import VSidebar from "../../Sidebar/VSidebar.vue";
import VSidebarItem from "../../Sidebar/VSidebarItem.vue";
import VSidebarItemActions from "../../Sidebar/VSidebarItemActions.vue";
import VSidebarItemNew from "../../Sidebar/VSidebarItemNew.vue";
import VSidebarSection from "../../Sidebar/VSidebarSection.vue";
import { useTaskList } from "../useTaskList";
import List from "./list.svg?component";
import Clock from "./clock.svg?component";
import CalendarAlt from "./calendar-alt.svg?component";
import Globe from "./globe.svg?component";
import UserColored from "./user-colored.svg?component";
import Route from "./route-regular.svg?component";
import Question from "./question.svg?component";
import Check from "./check.svg?component";
import CheckDouble from "./check-double.svg?component";
import Folder from "./folder.svg?component";
import FolderOpen from "./folder-open.svg?component";
import PencilRuler from "./pen-ruler.svg?component";
import { useRightsStore } from "@/core/store/RightStore";
import { useSystemStore } from "@/features/Store/useSystemStore";
import { TaskListType } from "@/features/Tasks/TasksRoutes";

const { t } = useI18n();
const systemStore = useSystemStore();
const { hasRole } = useRightsStore();
const { hasFeature } = useRightsStore();
const {
  rootLists,
  childrenPerListItem,
  listsKeyedByRouteId,
  otherUserLists,
  taskLists,
  createTaskList,
  updateTaskList,
  deleteTaskList,
} = useTaskList();
</script>

<template>
  <VSidebar :title="t('tasks.sidebar.title')">
    <VSidebarSection
      v-slot="{ toggle }"
      v-model="systemStore.taskSidebar.shortcuts"
      :title="t('tasks.sidebar.shortcuts')"
    >
      <VSidebarItem
        :to="{ name: 'task-list', params: { type: TaskListType.TYPE_ALL } }"
        :text="t('tasks.sidebar.all')"
        :icon="List"
        @toggle-tree="toggle"
      />
      <VSidebarItem
        :to="{ name: 'task-list', params: { type: TaskListType.TYPE_TODAY } }"
        :text="t('tasks.sidebar.today')"
        :icon="Clock"
        @toggle-tree="toggle"
      />
      <VSidebarItem
        :to="{
          name: 'task-list',
          params: { type: TaskListType.TYPE_OPEN_UNTIL_TODAY },
        }"
        :text="t('tasks.sidebar.open_until_today')"
        :icon="CalendarAlt"
        @toggle-tree="toggle"
      />
      <VSidebarItem
        :to="{ name: 'task-list', params: { type: TaskListType.TYPE_ALL_TODAY } }"
        :text="t('tasks.sidebar.all_today')"
        :icon="Globe"
        @toggle-tree="toggle"
      />
      <VSidebarItem
        :to="{
          name: 'task-list',
          params: { type: TaskListType.TYPE_ALL_UNTIL_TODAY },
        }"
        :text="t('tasks.sidebar.all_until_today')"
        :icon="Globe"
        @toggle-tree="toggle"
      />
      <VSidebarItem
        :to="{
          name: 'task-list',
          params: { type: TaskListType.TYPE_ASSIGNED_TO_ME },
        }"
        :text="t('tasks.sidebar.assignedToMe')"
        :icon="UserColored"
        @toggle-tree="toggle"
      />
      <VSidebarItem
        :to="{
          name: 'task-list',
          params: { type: TaskListType.TYPE_ROUTE },
        }"
        :text="t('tasks.sidebar.route')"
        :icon="Route"
        @toggle-tree="toggle"
      />
      <VSidebarItem
        :to="{ name: 'task-list', params: { type: TaskListType.TYPE_UNASSIGNED } }"
        :text="t('tasks.sidebar.unAssigned')"
        :icon="Question"
        @toggle-tree="toggle"
      />
      <VSidebarItem
        :to="{ name: 'task-list', params: { type: TaskListType.TYPE_READY } }"
        :text="t('tasks.sidebar.typeDone')"
        :icon="Check"
        @toggle-tree="toggle"
      />
      <VSidebarItem
        :to="{ name: 'task-list', params: { type: TaskListType.TYPE_FINISHED } }"
        :text="t('tasks.sidebar.typeFinished')"
        :icon="CheckDouble"
        @toggle-tree="toggle"
      />
      <VSidebarItem
        v-if="hasRole(UserRole.ROLE_ADMIN)"
        :to="{ name: 'task-list', params: { type: TaskListType.TYPE_TEMPLATE } }"
        :text="t('tasks.sidebar.templates')"
        :icon="PencilRuler"
        @toggle-tree="toggle"
      />
    </VSidebarSection>
    <VSidebarSection
      v-if="hasFeature(Feature.TASK_LISTS)"
      v-slot="{ toggle }"
      v-model="systemStore.taskSidebar.customLists"
      :title="t('tasks.sidebar.myLists')"
    >
      <div v-if="taskLists.length">
        <VSidebarItem
          v-for="(taskList, index) in taskLists"
          :key="taskList.id"
          :to="{
            name: 'task-list',
            params: { type: TaskListType.TYPE_CUSTOM, id: taskList.id },
          }"
          :text="taskList.name"
          :icon="Folder"
          :icon-active="FolderOpen"
          :is-placeholder="taskList.isPlaceholder"
          editable
          @toggle-tree="toggle"
          @update:text="(name) => updateTaskList(taskList.id, index, { name })"
        >
          <template #actions>
            <VSidebarItemActions>
              <VDropdownItem
                :text="t('tasks.sidebar.actionRemove')"
                :icon="['fas', 'trash']"
                class="is-danger"
                @click="() => deleteTaskList(taskList.id, index)"
              />
            </VSidebarItemActions>
          </template>
        </VSidebarItem>
      </div>
      <VSidebarItemNew
        :text="t('tasks.sidebar.newList')"
        @create="() => createTaskList(t('tasks.sidebar.newList'))"
      />
    </VSidebarSection>
    <VSidebarSection
      v-slot="{ toggle }"
      v-model="systemStore.taskSidebar.departments"
      :title="t('tasks.sidebar.departmentLists')"
    >
      <VSidebarItem
        v-for="item in rootLists"
        v-bind="item"
        :key="item.id"
        :children="childrenPerListItem[item.routeId]"
        :children-per-list-item="childrenPerListItem"
        :lists-keyed-by-route-id="listsKeyedByRouteId"
        @toggle-tree="toggle"
      />
    </VSidebarSection>
    <VSidebarSection
      v-slot="{ toggle }"
      v-model="systemStore.taskSidebar.otherUsers"
      :title="t('tasks.sidebar.otherUserLists')"
    >
      <VSidebarItem
        v-for="item in otherUserLists"
        v-bind="item"
        :key="item.id"
        :children="[]"
        :children-per-list-item="{}"
        :lists-keyed-by-route-id="listsKeyedByRouteId"
        @toggle-tree="toggle"
      />
    </VSidebarSection>
  </VSidebar>
</template>

<style scoped></style>
