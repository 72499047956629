import { useAuthStore, useLocationStore } from "@verbleif/shared";
import type { ComputedRef } from "vue";
import { TaskPriority } from "@verbleif/lib";
import api from "@/core/api";
import { ReportListType } from "@/features/Reports/ReportsRoutes";
import { FilterCondition } from "@/features/Tasks/Overview/useFilters.ts";

export function useReports(
  {
    currentListType,
    currentListId,
  }: {
    currentListType: ComputedRef<ReportListType>
    currentListId: ComputedRef<string | null>
  },
) {
  const authStore = useAuthStore();
  const locationStore = useLocationStore();

  function getParams(params: URLSearchParams) {
    switch (currentListType.value) {
      case ReportListType.TYPE_SORT:
        if (!currentListId.value) {
          throw new Error("No user found in route params.");
        }
        params.set(`filter[where][and][][sort][${FilterCondition.EQUAL}]`, currentListId.value);
        break;
      case ReportListType.TYPE_CREATED_BY_ME:
        if (!authStore.user.value) {
          throw new Error("Authentication expected.");
        }
        params.set(`filter[where][and][][createdBy][${FilterCondition.EQUAL}]`, `${authStore.user.value.id}`);
        break;
      case ReportListType.TYPE_UNASSIGNED_ALL:
        break;
      case ReportListType.TYPE_UNASSIGNED_NONE_PRIO:
        params.set(`filter[where][and][][priority][${FilterCondition.NULL}]`, "");
        break;
      case ReportListType.TYPE_UNASSIGNED_LOW_PRIO:
        params.set(`filter[where][and][][priority][${FilterCondition.EQUAL}]`, `${TaskPriority.LOW}`);
        break;
      case ReportListType.TYPE_UNASSIGNED_MED_PRIO:
        params.set(`filter[where][and][][priority][${FilterCondition.EQUAL}]`, `${TaskPriority.NORMAL}`);
        break;
      case ReportListType.TYPE_UNASSIGNED_HIGH_PRIO:
        params.set(`filter[where][and][][priority][${FilterCondition.EQUAL}]`, `${TaskPriority.HIGH}`);
        break;
      case ReportListType.TYPE_UNASSIGNED_URGENT_PRIO:
        params.set(`filter[where][and][][priority][${FilterCondition.EQUAL}]`, `${TaskPriority.URGENT}`);
        break;
      case ReportListType.TYPE_THIRD_PARTY:
        if (!currentListId.value) {
          throw new Error("No user found in route params.");
        }
        params.set(`filter[where][and][][thirdPartyName][${FilterCondition.EQUAL}]`, currentListId.value);
        break;
      default:
        break;
    }

    params.set(`filter[where][and][][task][${FilterCondition.NULL}]`, "");

    return params;
  }

  async function getReports(options: any) {
    const locationIri = locationStore.selectedLocation.value;
    return await api.get(`${locationIri}/reports`, options);
  }

  return {
    getReports,
    getParams,
  };
}
