<script setup>
import { provide } from "vue";
import CaretRight from "./caret-right.svg?component";

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: "Title",
  },
});
const emit = defineEmits(["update:modelValue"]);
const ToggleDropdownSymbol = Symbol("ToggleDropdownSymbol");
provide(ToggleDropdownSymbol);

function toggleDropdown(forcedValue = null) {
  const finalValue = forcedValue !== null ? forcedValue : !props.modelValue;
  if (props.modelValue === finalValue) {
    return;
  }

  emit("update:modelValue", finalValue);
}
</script>

<template>
  <div class="section">
    <div
      class="header"
      :class="{ 'is-collapsed': !modelValue }"
      @click="() => toggleDropdown()"
    >
      <div class="collapse-controls">
        <div class="toggle">
          <CaretRight class="collapse" />
        </div>
      </div>
      <div class="title">
        {{ title }}
      </div>
    </div>
    <div v-show="modelValue">
      <slot :toggle="toggleDropdown" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.section {
  .header {
    height: 35px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    user-select: none;
    cursor: pointer;

    &:hover {
      background-color: var(--grey-1);
    }

    .title {
      color: var(--text);
      font-weight: bold;
      font-size: 12px;
    }

    &:not(.is-collapsed) {
      .toggle {
        transform: rotate(90deg);
      }
    }

    .collapse-controls {
      width: 10px;
      margin-right: 5px;

      .toggle {
        color: var(--text);
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: ease 150ms;
      }
    }
  }
}
</style>
