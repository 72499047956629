<script>
export default {
  name: "VSidebarItemActions",
};
</script>

<template>
  <div class="menu" @click.prevent.stop>
    <VPopper
      root-class="is-default"
      trigger="clickToToggle"
      append-to-body
      visible-arrow
      :options="{ placement: 'bottom' }"
      transition="fade"
    >
      <div class="options">
        <slot />
      </div>
      <template #reference>
        <div class="click-reference">
          <FontAwesomeIcon class="settings" :icon="['fas', 'ellipsis-h']" />
        </div>
      </template>
    </VPopper>
  </div>
</template>

<style lang="scss" scoped>
.menu .click-reference {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 8px;
  height: 100%;
  color: var(--text);
  font-size: 13px;
}
</style>
