import moment from "moment";
import { useI18n } from "@/core/plugin/i18n";

// Thanks to this post https://stackoverflow.com/a/71966958
// Without dist it does not work, but no error and its autocompletes :/
import "moment/dist/locale/nl";
import "moment/dist/locale/de";
import "moment/dist/locale/pl";
import "moment/dist/locale/fr";
import "moment/dist/locale/tr";

const i18n = useI18n();

watchEffect(() => {
  moment.locale(i18n.locale.value);
});
