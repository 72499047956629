import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 512 512"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      fill: "none",
      "fill-rule": "evenodd"
    }, [
      _createElementVNode("path", {
        fill: "#F44336",
        "fill-rule": "nonzero",
        d: "M232.55 152h46.85c1.35 0 2.6.65 3.35 1.8l9.5 14.2h-72.5l9.5-14.2c.75-1.1 2-1.8 3.35-1.8zm88.55 16-18.35-27.55c-5.2-7.75-13.95-12.45-23.3-12.45h-46.9c-9.35 0-18.1 4.7-23.3 12.45L190.9 168H156c-6.65 0-12 5.35-12 12s5.35 12 12 12h5.8l12 162.35c1.25 16.7 15.15 29.65 31.9 29.65h100.6c16.75 0 30.65-12.95 31.9-29.65l12-162.35h5.8c6.65 0 12-5.35 12-12s-5.35-12-12-12h-34.9m5.05 24-11.9 160.6c-.3 4.2-3.8 7.4-8 7.4H205.7c-4.2 0-7.65-3.25-8-7.4L185.85 192z"
      }),
      _createElementVNode("circle", {
        cx: "256",
        cy: "256",
        r: "241",
        stroke: "#F44336",
        "stroke-width": "30"
      })
    ], -1)
  ])))
}
export default { render: render }