import type { ConfirmDialogOptions, HydraCollection, HydraItem, Task, TaskTemplate } from "@verbleif/lib";
import { useConfirmDialog } from "@verbleif/lib";
import { useI18n } from "vue-i18n";
import api from "@/core/api";

export function useTaskTemplate(translationKey: string) {
  const { t } = useI18n();
  const { open } = useConfirmDialog();

  async function showTaskTemplate(item: HydraItem<Task>) {
    const params = new URLSearchParams();
    params.append("templateTask", `${item["@id"]}`);
    const response = await api.get<HydraCollection<TaskTemplate> | null>(`${item.location.replace("/api/v1", "/api")}/task_templates`, { params }).catch(() => null);
    const taskTemplate = response?.data?.["hydra:member"]?.[0];

    const options: ConfirmDialogOptions = {
      title: t(`${translationKey}.modal_title`),
      cancelText: t("base.cancel"),
    };

    console.log("got here");
    open({
      options,
      component: import("@/features/Tasks/TaskTemplate/TaskTemplate.vue"),
      componentProps: {
        task: item,
        taskTemplate,
      },
    });
  }

  return {
    showTaskTemplate,
  };
}
export default useTaskTemplate;
