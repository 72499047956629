<script>
export default {
  name: "NoTasks",
  props: {
    text: {
      type: String,
      default: "No tasks yet. Nothing to see here.",
    },
  },
};
</script>

<template>
  <div class="no-tasks-container">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 708.66 708.66"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            class="cls-1"
            d="M484.1,195.6a34.7,34.7,0,0,0-30.05-34.38v-2H182.43v1.94A34.7,34.7,0,0,0,152,191.61h-1.94V421.25h32.38V531.72H454.05V230A34.71,34.71,0,0,0,484.1,195.6Z"
          />
        </clipPath>
        <clipPath id="clip-path-2">
          <polygon
            class="cls-1"
            points="520.34 257.93 496.96 298.17 496.96 529.24 545.16 529.24 545.16 298.17 520.34 257.93"
          />
        </clipPath>
      </defs>
      <g id="taken">
        <circle class="cls-2" cx="460.23" cy="408.85" r="207.88" />
        <circle class="cls-2" cx="239.3" cy="542.54" r="138.94" />
        <circle class="cls-2" cx="292.85" cy="279.49" r="252.3" />
        <polygon
          class="cls-3"
          points="520.34 257.93 496.96 298.17 496.96 529.24 545.16 529.24 545.16 298.17 520.34 257.93"
        />
        <path
          class="cls-3"
          d="M484.1,195.6a34.7,34.7,0,0,0-30.05-34.38v-2H182.43v1.94A34.7,34.7,0,0,0,152,191.61h-1.94V421.25h32.38V531.72H454.05V230A34.71,34.71,0,0,0,484.1,195.6Z"
        />
        <g class="cls-4">
          <path
            class="cls-5"
            d="M186.45,230.11s36.51-2,36.16-30.27-16.45-41.79-42.9-40-70.45,22.28-70.45,22.28l-7,301.5,80.17-62.36V232Z"
          />
        </g>
        <g class="cls-6">
          <rect
            class="cls-7"
            x="482.17"
            y="489.16"
            width="76.34"
            height="42.56"
          />
        </g>
        <g class="cls-4">
          <rect
            class="cls-8"
            x="182.43"
            y="209.99"
            width="325.45"
            height="34.74"
          />
        </g>
        <polyline
          class="cls-9"
          points="150.05 195.6 150.05 421.25 182.18 421.25"
        />
        <path
          class="cls-9"
          d="M219.91,210H182.43V526.32a5.24,5.24,0,0,0,5,5.4H449a5.24,5.24,0,0,0,5-5.4V232"
        />
        <path
          class="cls-9"
          d="M182.43,232H451a35.58,35.58,0,0,0,35.48-35.47v-1.83A35.59,35.59,0,0,0,451,159.21H185.52"
        />
        <path class="cls-9" d="M222.86,195.6a36.41,36.41,0,0,0-72.81,0" />
        <path class="cls-9" d="M222.86,195.6A36.41,36.41,0,0,1,186.45,232" />
        <line class="cls-9" x1="495.52" y1="303.11" x2="545.16" y2="303.11" />
        <path
          class="cls-9"
          d="M495.52,524.74c0,3.84,2.72,7,6,7h37.56c3.32,0,6-3.14,6-7V489.16H495.52Z"
        />
        <polygon
          class="cls-9"
          points="520.34 257.93 495.52 303.11 495.52 489.16 545.16 489.16 545.16 303.11 520.34 257.93"
        />
        <line class="cls-9" x1="507.88" y1="303.11" x2="507.88" y2="489.16" />
        <line class="cls-9" x1="532.8" y1="303.11" x2="532.8" y2="489.16" />
        <line class="cls-9" x1="512.68" y1="272.55" x2="528" y2="272.55" />
        <rect
          class="cls-9"
          x="222.61"
          y="260.48"
          width="45.27"
          height="45.27"
        />
        <rect
          class="cls-9"
          x="222.61"
          y="353.75"
          width="45.27"
          height="45.27"
        />
        <rect
          class="cls-9"
          x="222.61"
          y="447.03"
          width="45.27"
          height="45.27"
        />
        <line class="cls-10" x1="317.99" y1="271.76" x2="414.38" y2="271.76" />
        <line class="cls-10" x1="343.96" y1="298.17" x2="403.69" y2="298.17" />
        <line class="cls-10" x1="293.98" y1="361.96" x2="419.74" y2="361.96" />
        <line class="cls-10" x1="320.39" y1="380.09" x2="395.84" y2="380.09" />
        <line class="cls-10" x1="360.63" y1="405.37" x2="424.14" y2="405.37" />
        <line class="cls-10" x1="318.49" y1="457.75" x2="414.7" y2="457.75" />
        <line class="cls-10" x1="295.86" y1="477.87" x2="404.01" y2="477.87" />
        <path
          class="cls-11"
          d="M419.6,51.07c25.8,0,25.78,25.79,25.78,25.79,0-25.79,25.79-25.79,25.79-25.79-25.79,0-25.79-25.79-25.79-25.79C445.38,50.13,419.6,51.07,419.6,51.07Z"
        />
        <path
          class="cls-12"
          d="M14.76,279.49c25.8,0,25.79,25.79,25.79,25.79,0-25.79,25.78-25.79,25.78-25.79-25.78,0-25.78-25.78-25.78-25.78C40.55,278.55,14.76,279.49,14.76,279.49Z"
        />
        <path
          class="cls-13"
          d="M445.38,642.52c25.8,0,25.79,25.78,25.79,25.78,0-25.78,25.79-25.78,25.79-25.78-25.79,0-25.79-25.79-25.79-25.79C471.17,641.57,445.38,642.52,445.38,642.52Z"
        />
        <path
          class="cls-14"
          d="M130.22,579.8c19.84,0,19.83,19.83,19.83,19.83,0-19.83,19.83-19.83,19.83-19.83-19.83,0-19.83-19.83-19.83-19.83C150.05,579.08,130.22,579.8,130.22,579.8Z"
        />
        <path
          class="cls-15"
          d="M563.43,246.15c19.84,0,19.83,19.83,19.83,19.83,0-19.83,19.83-19.83,19.83-19.83-19.83,0-19.83-19.83-19.83-19.83C583.26,245.42,563.43,246.15,563.43,246.15Z"
        />
      </g>
    </svg>
    <div class="text">
      {{ text }}
    </div>
    <div class="slot">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.no-tasks-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 100px;

  svg {
    width: 200px;
  }

  .text {
    margin-top: 25px;
    color: var(--text);
  }

  .slot {
    margin-top: 25px;
  }
}
.cls-1,
.cls-10,
.cls-11,
.cls-12,
.cls-13,
.cls-9 {
  fill: none;
}
.cls-2 {
  fill: var(--primary-lighter);
}
.cls-3 {
  fill: #fff;
}
.cls-4 {
  clip-path: url(#clip-path);
}
.cls-5 {
  fill: var(--primary);
}
.cls-6 {
  clip-path: url(#clip-path-2);
}
.cls-7 {
  fill: var(--primary-light);
}
.cls-8 {
  fill: var(--primary-lighter);
}
.cls-10,
.cls-9 {
  stroke: var(--primary);
  stroke-miterlimit: 10;
  stroke-width: 7px;
}
.cls-10,
.cls-11,
.cls-12,
.cls-13 {
  stroke-linecap: round;
}
.cls-11 {
  stroke: var(--secondary-lighter);
}
.cls-11,
.cls-12,
.cls-13 {
  stroke-linejoin: round;
  stroke-width: 4px;
}
.cls-12 {
  stroke: var(--secondary-dark);
}
.cls-13 {
  stroke: var(--secondary);
}
.cls-14 {
  fill: var(--secondary-light);
}
.cls-15 {
  fill: var(--secondary-dark);
}
</style>
