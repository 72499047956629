import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  viewBox: "0 0 512 512"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      fill: "none",
      "fill-rule": "evenodd"
    }, [
      _createElementVNode("circle", {
        cx: "256",
        cy: "256",
        r: "241",
        fill: "#4F4F4F",
        stroke: "#FFF",
        "stroke-width": "30"
      }),
      _createElementVNode("path", {
        fill: "#FFF",
        "fill-rule": "nonzero",
        d: "M354.337 353.621c-8.34 8.34-21.845 8.334-30.175.005l-67.882-67.883-67.883 67.883c-8.339 8.339-21.835 8.325-30.165-.005s-8.344-21.826-.004-30.165l67.882-67.882-67.882-67.883c-8.33-8.33-8.335-21.835.004-30.174 8.34-8.34 21.836-8.325 30.165.004l67.883 67.883 67.882-67.883c8.34-8.339 21.845-8.334 30.175-.004s8.334 21.835-.005 30.174l-67.882 67.883 67.882 67.882c8.348 8.339 8.348 21.821.005 30.165"
      })
    ], -1)
  ])))
}
export default { render: render }