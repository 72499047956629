import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16",
  viewBox: "0 0 512 512"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      fill: "none",
      "fill-rule": "evenodd"
    }, [
      _createElementVNode("path", { d: "M0 0h512v512H0z" }),
      _createElementVNode("path", {
        fill: "#4F4F4F",
        "fill-rule": "nonzero",
        d: "M463.999 128h-192l-64-64H48C21.49 64 0 85.492 0 112v288c0 26.51 21.49 48 48 48h415.999c26.51 0 48-21.49 48-48V176c0-26.51-21.49-48-48-48"
      })
    ], -1)
  ])))
}
export default { render: render }