<script setup lang="ts">
import type {
  HydraItem,
  Report,
} from "@verbleif/lib";
import {
  LoopbackFilterLogicalOperatorEnum,
  LoopbackFilterOperatorEnum,
  providePaginate,
  useLazyLoadScroll,
} from "@verbleif/lib";
import { computed, ref, toRefs, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import * as Sentry from "@sentry/vue";
import { UserRole } from "@verbleif/shared";
import ReportItem from "./ReportItem/ReportItem.vue";
import { useReportList } from "./useReportList";
import { useReports } from "./useReports";
import { useRightsStore } from "@/core/store/RightStore";
import NoReports from "@/assets/NoReports.vue";
import api from "@/core/api";

const props = defineProps({
  setLoadFunction: {
    type: Function,
    default: () => {},
  },
});
defineEmits(["showCreate", "showEdit", "showRemove", "showTransferToTask"]);

const { setLoadFunction } = toRefs(props);

const TRANSLATION_KEY = "reports";
const { t } = useI18n();
const route = useRoute();
const { hasRole } = useRightsStore();
const reportContainer = ref(null);
const {
  currentListId,
  currentListTranslation,
  currentListName,
  currentListType,
} = useReportList();

const { getReports, getParams } = useReports({
  currentListId,
  currentListType,
});

const {
  resetAndLoad,
  load,
  loading,
  items,
  sortable,
  cursor: { currentPage, hasNextPage, totalItems },
} = providePaginate<HydraItem<Report>>({
  cursorParams: { partial: true },
  initialPerPage: 25,
  sortableFields: {
    "object.name": t(`${TRANSLATION_KEY}.object`),
    "inside": t(`${TRANSLATION_KEY}.inside`),
    "priority": t(`${TRANSLATION_KEY}.priority`),
    "createdAt": t(`${TRANSLATION_KEY}.createdAt`),
    "updatedAt": t(`${TRANSLATION_KEY}.updatedAt`),
  } as any,
  searchableFields: [
    {
      name: "object.name",
      operator: LoopbackFilterOperatorEnum.ILIKE,
      logicalOperator: LoopbackFilterLogicalOperatorEnum.OR,
    },
    {
      name: "description",
      operator: LoopbackFilterOperatorEnum.ILIKE,
      logicalOperator: LoopbackFilterLogicalOperatorEnum.OR,
    },
  ] as any,
  onLoad: options => getReports(options),
  loadParams: params => getParams(params),
});
sortable.sort.value = "updatedAt";

const listHasReports = computed(() => {
  return !!Object.keys(items.value).length;
});

useLazyLoadScroll(
  reportContainer,
  (options) => {
    currentPage.value++;
    load(options);
  },
  hasNextPage,
  loading,
);

load();

setLoadFunction.value(resetAndLoad);

watch(
  () => route.params,
  () => {
    resetAndLoad();
  },
);

const translations = computed(() => {
  return {
    createdAt: t("reports.createdAt"),
  };
});

function updateReport(input: Partial<Report>, report: HydraItem<Report>) {
  const oldItem = Object.assign({}, report);
  Object.assign(report, input);
  api
    .patch(`/reports/${report.id}`, input)
    .then((r) => {
      Object.assign(report, r.data);
    })
    .catch((e) => {
      Sentry.setContext("error", e);
      Sentry.captureException(e);
      Object.assign(report, oldItem);
    });
}
</script>

<template>
  <div ref="reportContainer" class="container">
    <div class="header">
      <div class="title">
        {{
          t(currentListTranslation, {
            count: totalItems,
            list: currentListName,
          })
        }}
      </div>
      <div class="right-container">
        <VButton class="button" icon-left="plus" @click="$emit('showCreate')">
          {{ t(`${TRANSLATION_KEY}.create_title`) }}
        </VButton>
      </div>
    </div>
    <div class="table-controls">
      <VSort
        :ascending-text="t('base.table.ascending')"
        :descending-text="t('base.table.descending')"
        :disabled-text="t('base.table.sort_disabled')"
        :placeholder-text="t('base.table.sort_placeholder')"
      />
      <VSearch
        :clear-search-text="t('base.table.clear_search')"
        :placeholder-text="t('base.table.search_placeholder')"
      />
    </div>
    <div v-if="listHasReports" class="reports">
      <ReportItem
        v-for="(report, index) in items"
        :key="index"
        :report="report"
        :index="index"
        :translations="translations"
        @on-row-click="$emit('showEdit', report)"
        @update="(input) => !('skeleton' in report) && updateReport(input, report)"
      >
        <template #actions>
          <VTooltip
            v-if="hasRole(UserRole.ROLE_DEPARTMENT_MANAGER)"
            :content="t('reports.actions.transfer_to_task')"
          >
            <template #reference>
              <FontAwesomeIcon
                :icon="['far', 'exchange']"
                @click.stop="$emit('showTransferToTask', report)"
              />
            </template>
          </VTooltip>
        </template>
        <template #dropdown-actions>
          <VDropdownItem
            v-if="hasRole(UserRole.ROLE_DEPARTMENT_MANAGER)"
            :icon="['fas', 'pencil']"
            :text="t('base.table.edit')"
            close-on-click
            @click="$emit('showEdit', report)"
          />
          <VDropdownItem
            v-if="!hasRole(UserRole.ROLE_DEPARTMENT_MANAGER)"
            :icon="['fas', 'eye']"
            :text="t('base.table.view')"
            close-on-click
            @click="$emit('showEdit', report)"
          />
          <VDropdownItem
            v-if="hasRole(UserRole.ROLE_DEPARTMENT_MANAGER)"
            :icon="['far', 'exchange']"
            :text="t('reports.actions.transfer_to_task')"
            close-on-click
            @click="$emit('showTransferToTask', report)"
          />
          <VDropdownItem
            v-if="hasRole(UserRole.ROLE_DEPARTMENT_MANAGER)"
            :icon="['fas', 'trash']"
            :text="t('base.table.remove')"
            class="is-danger"
            close-on-click
            @click="$emit('showRemove', report)"
          />
        </template>
      </ReportItem>
    </div>
    <div v-else class="reports empty">
      <NoReports :text="t(`${TRANSLATION_KEY}.no_reports`)">
        <VButton class="button" icon-left="plus" @click="$emit('showCreate')">
          {{ t(`${TRANSLATION_KEY}.create_title`) }}
        </VButton>
      </NoReports>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  padding: 18px 25px 100px;
  overflow-y: auto;

  .header {
    display: flex;
    margin-bottom: 13px;

    .title {
      color: var(--text);
      font-size: 25px;
      font-weight: bold;
    }

    .right-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: auto;

      .overview-settings {
        margin-right: 10px;
        color: var(--grey-3);
        cursor: pointer;
      }
    }
  }
}

.reports {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.table-controls {
  align-items: center;
  display: flex;
  margin-bottom: 13px;
}

.v-name-profile-pic {
  .image {
    display: inline-flex;
    font-size: 17px;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background: darkgrey;
    align-items: center;
    justify-content: center;
    color: white;
    margin-right: 10px;
  }
}
</style>
