import type { RouteRecordRaw } from "vue-router";
import SettingsContainer from "@/features/Settings/SettingsContainer.vue";

export const routes = <RouteRecordRaw[]>[
  {
    path: "/settings",

    name: "settings",
    component: SettingsContainer,
    redirect: () => {
      return {
        name: "my-account",
      };
    },
    children: [
      {
        path: "/:catchAll(.*)",
        redirect: { name: "my-account" },
      },
      {
        path: "",
        name: "settings-redirect",
        redirect: { name: "my-account" },
      },
      {
        path: "my-account",
        name: "my-account",
        component: () => import("./Personal/MyAccount.vue"),
      },
      {
        path: "subscription",
        name: "subscription",
        component: () => import("./Personal/MyAccount.vue"),
      },
      {
        path: "invoices",
        name: "invoices",
        component: () => import("./Personal/MyAccount.vue"),
      },
      {
        path: "users",
        name: "users",
        component: () => import("./Users/UsersOverview.vue"),
      },
      {
        path: "l-users",
        name: "location-users",
        component: () => import("./LocationUsers/LocationUsersOverview.vue"),
      },
      {
        path: "teams",
        name: "teams",
        component: () => import("./Teams/TeamsOverview.vue"),
      },
      {
        path: "departments",
        name: "departments",
        component: () => import("./Departments/DepartmentsOverview.vue"),
      },
      {
        path: "sorts",
        name: "sorts",
        component: () => import("./Sorts/SortsOverview.vue"),
      },
      {
        path: "groups",
        name: "groups",
        component: () => import("./Groups/GroupsOverview.vue"),
      },
      {
        path: "objects",
        name: "objects",
        component: () => import("./Objects/ObjectsOverview.vue"),
      },
      {
        path: "locations",
        name: "locations",
        component: () => import("../Settings/Locations/LocationsOverview.vue"),
      },
      {
        path: "automations",
        name: "automations",
        component: () => import("../Settings/Automation/AutomationOverview.vue"),
      },
    ],
  },
];

export default routes;
