import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 512 512"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#979797",
      "fill-rule": "nonzero",
      d: "m491.609 73.625-53.862-53.84c-26.378-26.378-69.076-26.382-95.46 0L24.91 335.088.329 484.084c-2.675 16.215 11.368 30.26 27.587 27.587l148.995-24.582 315.326-317.378c26.33-26.33 26.58-68.878-.628-96.086M120.643 301.999l170.26-169.155 88.25 88.251L210 391.355v-41.356h-48v-48zM82.131 458.131 53.87 429.868l12.14-73.586 18.4-18.283h41.59v48h48v41.59l-18.282 18.401zm378.985-319.533-.05.052-.052.05-48.03 48.344-88.03-88.03 48.344-48.03.05-.05.05-.05c9.147-9.145 23.978-9.258 33.236 0l53.855 53.854c9.877 9.877 9.938 24.549.627 33.86"
    }, null, -1)
  ])))
}
export default { render: render }