<script>
import { useField } from "vee-validate";
import { useI18n } from "vue-i18n";
import VueEasyLightbox from "vue-easy-lightbox";
import UploadingFileItem from "@/features/FileUpload/UploadingFileItem.vue";
import FileItem from "@/features/FileUpload/FileItem.vue";
import useImagePreviewer from "@/features/FileUpload/useImagePreviewer";
import useFileUpload from "@/features/FileUpload/useFileUpload";
import useDragAndDrop from "@/features/FileUpload/useDragAndDrop";

export default {
  name: "DragArea",
  components: { UploadingFileItem, FileItem, VueEasyLightbox },
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    initialValue: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: "mediaObjects",
    },
  },
  emits: ["countChanged"],
  setup(props, { emit }) {
    const TRANSLATION_KEY = "dragAndDrop";
    const { t } = useI18n();
    const { errorMessage, value: files } = useField(props.name, null, {
      initialValue: props.initialValue,
    });

    const { imageIndex, lightboxVisible, showImg, handleHide }
      = useImagePreviewer();

    const {
      hasFiles,
      addFiles,
      onDeleteFile,
      fileList,
      inputEl,
      onUpload,
      onDelete,
    } = useFileUpload({
      editable: props.editable,
      files,
      emit,
    });

    const { dragOver, dragLeave, drop } = useDragAndDrop({
      editable: props.editable,
      onDrop: addFiles,
    });

    function handleChange(e) {
      addFiles(e.target.files);
    }
    return {
      t,
      TRANSLATION_KEY,
      errorMessage,
      fileList,
      inputEl,
      files,
      imageIndex,
      lightboxVisible,
      hasFiles,
      showImg,
      handleHide,
      drop,
      dragOver,
      dragLeave,
      onDelete,
      onDeleteFile,
      onUpload,
      handleChange,
    };
  },
};
</script>

<template>
  <div class="attachments">
    <div
      :class="{ 'has-files': hasFiles }"
      class="file-list"
      @dragleave.prevent="dragLeave"
      @dragover.prevent="dragOver"
      @drop.prevent="drop"
    >
      <div class="file-list-entries">
        <div v-if="!editable && !hasFiles" class="no-files">
          {{ t(`${TRANSLATION_KEY}.empty`) }}
        </div>
        <UploadingFileItem
          v-for="(file, index) in fileList"
          :key="file"
          :file="file"
          @delete="() => onDeleteFile({ file, index })"
          @upload="(mediaObject) => onUpload({ mediaObject, file, index })"
        />
        <FileItem
          v-for="(file, index) in files"
          :key="file.id"
          :media-object="file"
          @show-image="() => showImg(index)"
          @delete="() => onDelete({ file, index })"
        />
        <VueEasyLightbox
          :visible="lightboxVisible"
          :imgs="
            files
              .filter((MO) => MO.mimeType.includes('image'))
              .map((file) => file.url)
          "
          :index="imageIndex"
          @hide="handleHide"
        />
      </div>
      <div v-if="editable" class="file-input-entry">
        <input
          ref="inputEl"
          class="file-input"
          multiple
          name="mediaObjects"
          type="file"
          @change="handleChange"
        >
        <div v-if="hasFiles" class="notice-small">
          {{ t(`${TRANSLATION_KEY}.dragAndDrop`) }}
          {{ t(`${TRANSLATION_KEY}.or`) }}
          <span class="click-here">{{ t(`${TRANSLATION_KEY}.choose`) }}</span>
        </div>
        <div v-else class="notice">
          <FontAwesomeIcon :icon="['fas', 'upload']" class="icon" />
          <div class="dragAndDrop">
            {{ t(`${TRANSLATION_KEY}.dragAndDrop`) }}
          </div>
          <div class="or">
            {{ t(`${TRANSLATION_KEY}.or`) }}
          </div>
          <div class="choose">
            {{ t(`${TRANSLATION_KEY}.choose`) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.attachments {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;

  .file-list {
    display: flex;
    flex-direction: column;
    flex: 1;
    border: dashed $grey-1 2px;
    border-radius: 5px;
    padding: 20px 20px 10px 20px;
    gap: 10px;

    &.drag-over {
      border: solid 2px var(--primary);
    }

    &.has-files {
      .file-list-entries {
        flex: 1;
      }

      .file-input-entry {
        width: 100%;
        flex: 0;
        border-radius: 5px;
        border: dashed 2px $grey-1;
      }

      .file-input {
        height: 46px;
      }
    }

    .file-list-entries {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
    }

    .no-files {
      width: 100%;
      text-align: center;
    }

    .file-input-entry {
      display: flex;
      flex: 1;
      position: relative;

      .notice-small {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 7px;
        height: 100%;
        width: 100%;
        pointer-events: none;
        color: var(--text);

        .click-here {
          color: var(--primary);
        }
      }

      .notice {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        gap: 15px;
        color: var(--text);

        .icon {
          font-size: 40px;
        }

        .dragAndDrop {
          font-size: 24px;
        }

        .or {
          font-size: 20px;
        }

        .choose {
          font-size: 20px;
          color: var(--primary);
        }
      }

      .file-input {
        cursor: pointer;
        color: transparent;
        flex: 1;

        &::-webkit-file-upload-button {
          visibility: hidden;
        }
      }
    }
  }
}
</style>
