export function useExtensions() {
  const fallback = { icon: ["fal", "file"], class: "icon-file" };

  const extensionMap = [
    { ext: "image", icon: ["fal", "image"], class: "icon-image" },
    { ext: "video", icon: ["fal", "file-video"], class: "icon-video" },
    { ext: "application/pdf", icon: ["fal", "file-pdf"], class: "icon-pdf" },
    {
      ext: "application/msword",
      icon: ["fal", "file-word"],
      class: "icon-word",
    },
    {
      ext: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      icon: ["fal", "file-word"],
      class: "icon-word",
    },
    {
      ext: "application/vnd.ms-excel",
      icon: ["fal", "file-excel"],
      class: "icon-excel",
    },
    {
      ext: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      icon: ["fal", "file-excel"],
      class: "icon-excel",
    },
    {
      ext: "application/zip",
      icon: ["fal", "file-archive"],
      class: "icon-archive",
    },
    {
      ext: "application/x-zip-compressed",
      icon: ["fal", "file-archive"],
      class: "icon-archive",
    },
  ];

  function resolveExtension(type) {
    return extensionMap.find(i => type.includes(i.ext)) || fallback;
  }

  return {
    resolveExtension,
  };
}
