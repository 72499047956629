import type {
  AuthExtraDataResponseV1,
  AuthExtraDataResponseV2,
  AuthResponseV1, AuthResponseV2,
} from "@verbleif/shared";
import type {
  Client,
  HydraCollection,
  HydraItem, HydraItemContext,
  Location,
  User,
  UserV1,
} from "@verbleif/lib";
import {
  useAuthStore, useImpersonationStore,
  useLocationStore,
} from "@verbleif/shared";
import * as Sentry from "@sentry/vue";
import type { AxiosRequestConfig } from "axios";
import { Crisp } from "crisp-sdk-web";
import { useRightsStore } from "@/core/store/RightStore";
import api from "@/core/api";
import router from "@/core/plugin/router";

const authStore = useAuthStore();
const locationStore = useLocationStore();
const rightStore = useRightsStore();
const impersonationStore = useImpersonationStore();

export async function onAuthenticateSuccess(response: AuthResponseV1 | AuthResponseV2): Promise<void> {
  authStore.setToken(response.token);
  authStore.setRefreshToken(response.refresh_token);

  let userIri: string | null;
  if (typeof response.user !== "string") {
    userIri = response.user["@id"];
  } else {
    userIri = response.user;
  }

  if (impersonationStore.isImpersonating.value) {
    // Switch to v2 response automatically when login response is also V2
    if (typeof response.user !== "string") {
      userIri = await api.get<HydraItemContext<AuthExtraDataResponseV1>>("/api/v1/me", {
        _retry: false,
      } satisfies AxiosRequestConfig & { _retry: boolean } as any).then((r) => {
        return r.data.user["@id"];
      });
    } else {
      userIri = await api.get<HydraItemContext<AuthExtraDataResponseV2>>("/api/me", {
        _retry: false,
      } satisfies AxiosRequestConfig & { _retry: boolean } as any).then((r) => {
        return r.data.user;
      });
    }
  }

  if (("effectiveFeatures" in response)) {
    rightStore.setEffectiveFeatures(response.effectiveFeatures);
  } else {
    rightStore.setEffectiveFeatures(response.user.effectiveFeatures);
  }

  if (!userIri) {
    console.error(response.user);
    throw new Error("No user iri could be matched.");
  }

  await api.get<HydraItem<User | UserV1>>(userIri, {
    _retry: false,
  } satisfies AxiosRequestConfig & { _retry: boolean } as any)
    .then((r) => {
      console.log("THEN");

      const user = r.data;
      authStore.setUser(user);

      if (import.meta.env.PROD) {
        Sentry.setUser(user);
      }

      const clientIri = typeof user.client === "string" ? user.client : user.client["@id"];
      return api.get<HydraItemContext<Client>>(clientIri, {
        _retry: false,
      } satisfies AxiosRequestConfig & { _retry: boolean } as any).then((r) => {
        authStore.setClient(r.data);
      });
    });
  try {
    if (authStore.user.value) {
      if (authStore.user.value?.email && authStore.user.value?.email.includes("@")) {
        console.log("Setting email to crisp", authStore.user.value);
        Crisp.user.setEmail(authStore.user.value?.email);
      }
      if (authStore.user.value?.fullName) {
        Crisp.user.setNickname(authStore.user.value?.fullName);
      }
      if (authStore.client.value?.name) {
        Crisp.user.setCompany(authStore.client.value?.name, {});
      }
      Crisp.session.setData({
        user_id: authStore.user.value?.id,
      });
    }
    Crisp.session.setSegments(["web"], false);
    Crisp.chat.show();
  } catch (e) {
    Sentry.captureException(e);
    console.error("Crisp:", e);
  }
  await api.get<HydraCollection<Location>>("/api/v1/locations", {
    _retry: false,
  } satisfies AxiosRequestConfig & { _retry: boolean } as any)
    .then((r) => {
      locationStore.setLocations(r.data["hydra:member"]);
    });

  const currentRoute = router.currentRoute.value;
  if (
    locationStore.hasMultipleLocations.value
      && !locationStore.selectedLocation.value
      && !currentRoute.meta.public
  ) {
    const from = {
      redirect: currentRoute.query.redirect as string || currentRoute.params.redirect || currentRoute.fullPath,
    };
    await router.push({ name: "choose-location", query: from, params: from });
  }
}
