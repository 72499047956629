type KeyOf<T> = keyof T;

export function useGroupBy<T extends Record<string, any>>() {
  function groupBy<K extends KeyOf<T>>(array: T[], groupByKey: K | string): Record<string, T[]> {
    let groupKey = getLastValidGroupBy(array, groupByKey as K);

    if (typeof groupKey === "undefined") {
      groupKey = "false" as K;
    }

    return array.reduce((groupedItems, item) => {
      let currentGroupKey: string | undefined = item[groupByKey as K]?.toString();

      if (currentGroupKey === undefined) {
        currentGroupKey = "false";
      }

      groupedItems[currentGroupKey] = (groupedItems[currentGroupKey] || []).concat(item);
      return groupedItems;
    }, {} as Record<string, T[]>);
  }

  function getLastValidGroupBy<K extends KeyOf<T>>(arrayWithObjects: T[], groupByKey: K): K | undefined {
    return arrayWithObjects.find((item) => {
      if (typeof item !== "object") {
        return false;
      }
      return item[groupByKey];
    })?.[groupByKey];
  }

  return {
    groupBy,
  };
}
