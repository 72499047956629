<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { computed } from "vue";
import greatStayApp from "./greatstayapp.png";
import question from "./question.png";
import wemaMobile from "./wemamobile.png";
import bookingExpertsLogo from "@/assets/BexLogo.png?url";

const props = withDefaults(defineProps<{
  thirdPartyName?: string
  left?: boolean
  nonAbsolute?: boolean
  noBackground?: boolean
  hasBorder?: boolean
  size?: "is-small" | "is-medium" | "is-large"
}>(), {
  thirdPartyName: "question",
  left: false,
  nonAbsolute: false,
  noBackground: false,
  hasBorder: false,
  size: "is-small",
});
const TRANSLATION_KEY = "auth.oauth";
const { t } = useI18n();

const thirdPartyIcon = computed(() => {
  switch (props?.thirdPartyName) {
    case "booking_experts":
      return bookingExpertsLogo;
    case "great_stay_app":
      return greatStayApp;
    case "wema_mobile":
      return wemaMobile;
    default:
      return question;
  }
});
const thirdPartyColor = computed(() => {
  switch (props?.thirdPartyName) {
    case "booking_experts":
      return "#14954F";
    case "great_stay_app":
      return "#000000";
    case "wema_mobile":
      return "#000000";
    default:
      return "#000000";
  }
});
</script>

<template>
  <div
    class="connect-icon"
    :class="[{ left, 'has-border': hasBorder, 'non-absolute': nonAbsolute, 'no-background': noBackground }, size]"
    :title="t(`${TRANSLATION_KEY}.third_party_connection`)"
  >
    <img alt="Logo" :src="thirdPartyIcon">
  </div>
</template>

<style lang="scss" scoped>
.connect-icon {
  transition: 100ms;

  &:hover {
    cursor: pointer;
    transition: 100ms;
    background: var(--grey-1);
  }
  display: inline-flex;
  border-radius: 50%;
  &:not(.no-background) {
    border: 1px solid var(--grey-1);
    background-color: var(--input-background);
  }
  &.has-border {
    border: 2px v-bind(thirdPartyColor) solid;
    border-radius: 50%;
  }
  justify-content: center;
  align-items: center;
  color: white;

  &.is-small {
    width: 20px;
    height: 20px;

    &:not(.non-absolute) {
      position: absolute;
      right: -10px;
    }
    &.left {
      left: -35px;
      right: 0;
    }
  }

  &.is-medium {
    width: 32px;
    height: 32px;
  }

  &.is-large {
    width: 64px;
    height: 64px;
  }

  img {
    width: 80%;
  }
  .icon {
    color: var(--hovercolor);
    font-size: 8px;
  }
}
</style>
