import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16",
  viewBox: "0 0 512 512"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      fill: "none",
      "fill-rule": "evenodd"
    }, [
      _createElementVNode("path", { d: "M0 0h512v512H0z" }),
      _createElementVNode("path", {
        fill: "#4F4F4F",
        "fill-rule": "nonzero",
        d: "M256.97.001c-79.614 0-131.18 32.703-171.664 91.026-7.345 10.58-5.08 25.086 5.164 32.874l43.028 32.71c10.345 7.863 25.067 6.024 33.167-4.15 24.984-31.38 43.518-49.448 82.544-49.448 30.684 0 68.638 19.798 68.638 49.63 0 22.553-18.568 34.135-48.866 51.164-35.332 19.86-82.088 44.576-82.088 106.405V320c0 13.255 10.718 24 23.938 24h72.286c13.22 0 23.938-10.745 23.938-24v-5.773C307.055 271.367 432 269.582 432 153.6 432 66.257 341.633.001 256.97.001m-9.995 373.458c-38.098 0-69.094 31.075-69.094 69.271S208.877 512 246.975 512s69.093-31.075 69.093-69.271-30.996-69.27-69.093-69.27"
      })
    ], -1)
  ])))
}
export default { render: render }