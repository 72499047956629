<script>
import { useI18n } from "vue-i18n";
import { useTheme } from "@verbleif/shared";

export default {
  name: "VDateSelector",
  emits: ["input"],
  setup: () => {
    const { t, locale } = useI18n();
    const { theme } = useTheme();
    return {
      t,
      locale,
      theme,
    };
  },
};
</script>

<template>
  <VueDatePicker
    class="date-picker"
    :dark="theme === 'dark'"
    locale="nl"
    cancel-text="Terug"
    select-text="Kiezen"
    :placeholder="t(`tasks.information.deadline_placeholder`)"
    range
    v-bind="$attrs"
    :enable-time-picker="false"
  >
    <template #dp-input="{ value, onInput }">
      <VInput
        has-new-style
        text-align="is-text-align-left"
        :model-value="value"
        :placeholder="t(`tasks.information.deadline_placeholder`)"
        icon-left="calendar"
        icon-right="trash"
        readonly
        :class="[{ 'is-selected': value }]"
        @click="onInput"
      />
    </template>
  </VueDatePicker>
</template>

<style scoped lang="scss">
::v-deep(.input input.is-selected) {
  border: 1px solid var(--primary);
}
.date-picker {
  width: 250px;
}
</style>
