<script setup lang="ts">
import { useI18n } from "vue-i18n";
import type { DatePickerInstance } from "@vuepic/vue-datepicker";
import { ref } from "vue";
import { VPopper } from "@verbleif/lib";
import { Theme, useTheme } from "@verbleif/shared";
import moment from "moment";
import CalendarPlaceholder from "@/assets/CalendarPlaceholder.svg?component";
import DeadlineTime from "@/features/Deadline/DeadlineTime.vue";

interface Props {
  modelValue: any | null
  timeSensitive: boolean
  disabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: null,
  timeSensitive: false,
  disabled: false,
});

const emit = defineEmits<{
  (e: "update:modelValue", value: any, timeSensitive: boolean): void
}>();

const { t } = useI18n();
const { theme } = useTheme();

const { locale } = useI18n();

const datepicker = ref<DatePickerInstance>(null);

function getDate(dateVal: string) {
  const newDate = moment(dateVal);
  if (!dateVal) {
    return "";
  }

  return `${newDate.format(
    props.timeSensitive ? "D MMMM YYYY HH:mm" : "D MMMM YYYY",
  )}`;
}

function selectDate() {
  datepicker.value?.selectDate();
}
</script>

<template>
  <VPopper
    trigger="clickToToggle"
    stop-propagation
    :disabled="disabled"
    append-to-body
  >
    <template #reference>
      <VueDatePicker
        ref="datepicker"
        :dark="theme === Theme.DARK"
        :locale="locale"
        auto-apply
        teleport
        :model-value="modelValue"
        @update:model-value="(v: string | null) => emit('update:modelValue', v, timeSensitive)"
      >
        <template #dp-input>
          <div class="calendar-placeholder-container" :class="[{ 'is-disabled': disabled }]">
            <CalendarPlaceholder class="icon" />
          </div>
        </template>
        <template #action-row="{ value }">
          {{ getDate(value) }}
        </template>
        <template #time-picker="{ time, updateTime }">
          <DeadlineTime
            :hours="time.hours"
            :minutes="time.minutes"
            :seconds="time.seconds"
            @update:hours="updateTime($event)"
            @update:minutes="updateTime($event, false)"
            @update:seconds="updateTime($event, false, true)"
          />
        </template>
        <template #action-buttons>
          <VButton variant="is-primary" @click="selectDate">
            {{ t('base.save') }}
          </VButton>
        </template>
      </VueDatePicker>
    </template>
  </VPopper>
</template>

<style scoped lang="scss">
.calendar-placeholder-container {
  &:not(.is-disabled) {
    cursor: pointer;
    > {
      :hover {
        opacity: 0.7;
      }
    }
  }

  &.is-disabled {
    opacity: 0.7;
  }

}
</style>
