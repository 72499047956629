import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16",
  viewBox: "0 0 512 512"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      fill: "none",
      "fill-rule": "evenodd"
    }, [
      _createElementVNode("path", { d: "M0 0h512v512H0z" }),
      _createElementVNode("path", {
        fill: "#4F4F4F",
        "fill-rule": "nonzero",
        d: "M111.475 477.5c0 30.675 36.992 46.038 58.626 24.345l220.931-221.5c13.412-13.444 13.412-35.246 0-48.691l-220.931-221.5c-21.634-21.69-58.626-6.33-58.626 24.346z"
      })
    ], -1)
  ])))
}
export default { render: render }