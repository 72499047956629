<script setup lang="ts">
import { Form } from "vee-validate";
import moment from "moment";
import type { Activity, HydraItem, User } from "@verbleif/lib";
import AvatarWithName from "../AvatarWithName/AvatarWithName.vue";
import Pencil from "./pencil.svg?component";
import Trash from "./trash.svg?component";
import Ellipsis from "@/assets/ellipsis.svg?component";
import { useSystemStore } from "@/features/Store/useSystemStore";
import VField from "@/features/FormField/VField.vue";
import api from "@/core/api";

const props = defineProps<{
  activity: HydraItem<Activity>
  time: string
}>();

const emit = defineEmits(["update:modelValue"]);

const systemStore = useSystemStore();
const { t } = useI18n();

const isEditing = ref(false);

async function submit(values: any) {
  const previousMessage = props.activity.meta?.message;
  const previousUpdated = props.activity.updatedAt;
  emit("update:modelValue", {
    ...props.activity,
    updatedAt: (new Date()).toISOString(),
    meta: {
      message: values.message,
    },
  });
  isEditing.value = false;
  await api.patch(props.activity["@id"], { meta: { message: values.message } })
    .catch(() => {
      emit("update:modelValue", {
        ...props.activity,
        updatedAt: previousUpdated,
        meta: {
          message: previousMessage,
        },
      });
    });
}

async function remove() {
  emit("update:modelValue", {
    ...props.activity,
    deletedAt: (new Date()).toISOString(),
  });
  await api.delete(props.activity["@id"]).catch(() => {
    emit("update:modelValue", {
      ...props.activity,
      deletedAt: null,
    });
  });
}

function startEdit(resetForm: any) {
  isEditing.value = true;
  resetForm();
}

const initialValues = computed(() => {
  return {
    message: props.activity?.meta?.message,
  };
});

function mapToIri(item: string | HydraItem<User>) {
  return typeof item === "string" ? item : item["@id"];
}

const createdBy = computed(() => {
  const createdBy = props.activity.createdBy;
  if (!createdBy) {
    return null;
  }
  const iri = mapToIri(createdBy);

  let user = systemStore.usersIndexedByIri.value?.[iri];
  if (!user) {
    user = systemStore.systemUsersIndexedByIri.value?.[iri];
  }

  if (!user) {
    return null;
  }

  return user;
});

const extraTime = computed(() => {
  if (props.activity.deletedAt) {
    const date = moment(props.activity.deletedAt);
    return `${t("base.deletedAt")} ${date.fromNow()}`;
  }

  if (props.activity.updatedAt === props.activity.createdAt) {
    return false;
  }

  const date = moment(props.activity.updatedAt);
  return `${t("base.editedAt")} ${date.fromNow()}`;
});
</script>

<template>
  <div class="activity-comment-item">
    <AvatarWithName
      size="is-medium"
      :name="createdBy ? createdBy.fullName : t('activities.unknown_user')"
      :avatar-iri="createdBy?.avatar"
      :active="true"
    />
    <Form v-slot="{ resetForm }" :initial-values="initialValues" class="content" :class="{ 'is-editing': isEditing }" @submit="submit">
      <template v-if="isEditing">
        <VField v-slot="{ field, errorMessage }" name="message">
          <VTextArea
            v-bind="field"
            :error-message="errorMessage"
            name="message"
            has-new-style
            variant="is-white"
            resize="is-vertical"
          />
        </VField>

        <div class="buttons">
          <VButton
            variant="is-light"
            size="is-small"
            @click="isEditing = false"
          >
            {{ t('base.cancel') }}
          </VButton>
          <VButton
            size="is-small"
            type="submit"
          >
            {{ t('base.save') }}
          </VButton>
        </div>
      </template>
      <template v-else>
        <div class="message" :class="{ 'is-deleted': !!activity.deletedAt }">
          <template v-if="activity.deletedAt">
            {{ t('activities.comment_deleted') }}
          </template>
          <template v-else>
            {{ activity.meta.message }}
          </template>
        </div>
        <div class="right">
          <div class="actions">
            <template v-if="!activity.deletedAt">
              <div class="edit" @click="startEdit(resetForm)">
                <Pencil />
                <span class="is-primary-text">
                  {{ t('base.table.edit') }}
                </span>
              </div>
              <VPopper
                class="right"
                root-class="is-default"
                trigger="clickToToggle"
                append-to-body
                transition="fade"
                stop-propagation
              >
                <template #reference>
                  <Ellipsis class="ellipsis" />
                </template>
                <div class="remove">
                  <VDropdownItem
                    :text="t('base.table.remove')"
                    close-on-click
                    @click="remove"
                  >
                    <template #icon>
                      <Trash class="icon" />
                    </template>
                  </VDropdownItem>
                </div>
              </VPopper>
            </template>
          </div>
          <div class="when">
            <span v-if="extraTime" class="edited">
              {{ extraTime }}&nbsp;
            </span>
            <span>{{ time }}</span>
          </div>
        </div>
      </template>
    </Form>
  </div>
</template>

<style scoped lang="scss">
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.activity-comment-item {
  &:hover {
    .content .right .actions {
      opacity: 1;
    }
  }

  .content {
    background-color: var(--background-modal);
    padding: 9px 12px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    margin-top: 5px;
    border-radius: 10px;

    &.is-editing {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr auto;
    }

    .buttons {
      display: flex;
      flex-direction: row;
      margin-left: auto;
      gap: 10px;
    }

    .message {
      color: var(--text);
      font-size: 14px;
      font-weight: 400;

      &.is-deleted {
        font-style: italic;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      gap: 15px;

      .actions {
        display: flex;
        flex-direction: row;
        align-self: flex-end;
        gap: 10px;
        opacity: 0;
        transition: opacity ease 150ms;
        color: var(--text);
        font-size: 12px;

        .edit {
          display: flex;
          align-items: center;
          gap: 7px;
          color: var(--primary);
          cursor: pointer;

          &:hover {
            color: var(--primary-dark);
          }
        }

        .ellipsis {
          cursor: pointer;
        }

        svg {
          width: 12px;
        }
      }

      .when {
        color: var(--text-2);
        font-size: 11px;
        font-weight: 400;
        align-self: flex-end;

        .edited {
          font-style: italic;
        }
      }
    }
  }

  .error-controls {
    display: flex;
    align-items: center;
    .error-icon {
      font-size: 14px;
      color: $danger;
    }

    .redo-icon {
      margin-right: 5px;
      font-size: 12px;
      cursor: pointer;
      color: var(--text);
    }
  }

  &.is-placeholder {
    opacity: 0.5;
    .when {
      position: relative;
      color: transparent !important;
      pointer-events: none;

      &:after {
        animation: loader 0.5s infinite linear;
        border: 2px solid black;
        border-radius: 290486px;
        border-right-color: transparent;
        border-top-color: transparent;
        content: "";
        display: block;
        height: 1em;
        position: absolute;
        left: calc(50% - 0.5em);
        top: calc(50% - 0.5em);
        width: 1em;
      }
    }
  }
}
</style>
