import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore, useLocationStore } from "@verbleif/shared";
import SettingsRoutes from "@/features/Settings/SettingsRoutes";
import AuthRoutes from "@/features/Auth/AuthRoutes";
import TaskRoutes from "@/features/Tasks/TasksRoutes";
import ReportRoutes from "@/features/Reports/ReportsRoutes";
import PublicRoutes from "@/features/Public/PublicRoutes";

const routes = [
  ...SettingsRoutes,
  ...AuthRoutes,
  ...TaskRoutes,
  ...ReportRoutes,
  ...PublicRoutes,
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});
export default router;

const authStore = useAuthStore();
const locationStore = useLocationStore();

router.beforeEach((to, _, next) => {
  const name = <string>to.name;
  consola.info("Route changed to:", name);
  if (to.meta.skipAuthChecks) {
    consola.info("Skipping auth checks.");

    return next();
  }

  console.log("authStore.hasToken.value", authStore.hasToken.value);
  console.log("authStore.isRemembered.value", authStore.isRemembered.value);
  console.log("to.meta.public", to.meta.public);
  if (!authStore.hasToken.value && !authStore.isRemembered.value && !to.meta.public) {
    const redirect = to.fullPath;
    consola.info("Force change to login with redirect");

    return next({ name: "login", query: { redirect }, params: { redirect } });
  }

  const fallback = to.params.redirect as string || to.query.redirect as string || { name: "tasks" };
  console.log("fallback", fallback);

  if (to.name === "choose-location" && !locationStore.hasMultipleLocations.value) {
    return next(fallback);
  }

  if ((authStore.hasToken.value || authStore.isRemembered.value) && to.meta.public) {
    consola.info("Already authenticated but wanted anonymous route. Force redirect to tasks. hasToken & isRemembered.", authStore.hasToken.value, authStore.isRemembered.value);

    return next(fallback);
  }

  next();
});
