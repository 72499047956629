<script setup>
import { provideModal } from "@verbleif/lib";
import Tasks from "./Overview/TasksView.vue";
import { provideTaskList } from "./Overview/useTaskList";
import CreateTask from "@/features/Tasks/CreateTask/CreateTask.vue";
import useCreateTask from "@/features/Tasks/CreateTask/useCreateTask";
import EditTask from "@/features/Tasks/EditTask/EditTask.vue";
import useEditTask from "@/features/Tasks/EditTask/useEditTask";
import useRemoveTask from "@/features/Tasks/RemoveTask/RemoveTask";
import { useTaskTransformer } from "@/features/Tasks/useTaskTransformer";
import useTaskInit from "@/features/Tasks/useTaskInit";
import TaskListSidebar from "@/features/Tasks/Overview/Sidebar/TaskListSidebar.vue";
import useTaskTemplate from "@/features/Tasks/TaskTemplate/TaskTemplate";
import api from "@/core/api";

const TRANSLATION_KEY = "tasks";
const modalManager = provideModal();
const { transformer } = useTaskTransformer();
provideTaskList();
const visible = ref(modalManager.visible);

let load = null;
function reload() {
  load();
}

function setLoadFunction(f) {
  load = f;
}

const { showCreate } = useCreateTask({
  component: markRaw(CreateTask),
  endpoint: "/tasks",
  translationKey: TRANSLATION_KEY,
  load: reload,
  modalManager,
  transformer,
});
const { showEdit } = useEditTask({
  component: markRaw(EditTask),
  endpoint: "/tasks",
  translationKey: TRANSLATION_KEY,
  load: reload,
  modalManager,
  transformer,
});
const { showRemove } = useRemoveTask(reload, "/tasks", TRANSLATION_KEY);
const { showTaskTemplate } = useTaskTemplate(`${TRANSLATION_KEY}.template_options`);

async function showEditInterceptor(item) {
  function iriToId(item) {
    return item.split("/").pop();
  }

  const mediaObjects = await api.get(`/locations/${iriToId(item.location)}/tasks/${item.id}/media_objects`)
    .then(r => r.data?.["hydra:member"] || []);

  showEdit(item, mediaObjects);
}

useTaskInit(showEditInterceptor);
</script>

<template>
  <div class="tasks-container">
    <TaskListSidebar />
    <Tasks
      :set-load-function="setLoadFunction"
      @show-create="showCreate"
      @show-edit="showEditInterceptor"
      @show-remove="showRemove"
      @show-template-options="showTaskTemplate"
    />
    <transition name="fade">
      <VModal v-if="visible" :headerless="true" size="is-large" />
    </transition>
  </div>
</template>

<style lang="scss" scoped>
.tasks-container {
  width: 100%;
  height: 100%;
  display: flex;
}
</style>
