import { computed, ref } from "vue";
import * as Sentry from "@sentry/vue";
import { useLocationStore } from "@verbleif/shared";
import UploadFailedError from "@/features/FileUpload/UploadFailedError";
import api from "@/core/api";

export function useMediaObject() {
  const locationStore = useLocationStore();

  const UPLOAD_STATUS_INITIAL = 0;
  const UPLOAD_STATUS_IN_PROGRESS = 1;
  const UPLOAD_STATUS_FAILED = 2;
  const UPLOAD_STATUS_SUCCESS = 3;

  const status = ref(UPLOAD_STATUS_INITIAL);

  const statusText = computed(() => {
    switch (status.value) {
      case UPLOAD_STATUS_IN_PROGRESS:
        return "dragAndDrop.uploadInProgress";
      case UPLOAD_STATUS_SUCCESS:
        return "dragAndDrop.uploadSuccess";
      case UPLOAD_STATUS_FAILED:
        return "dragAndDrop.uploadFailed";
      case UPLOAD_STATUS_INITIAL:
      default:
        return "dragAndDrop.uploadInitial";
    }
  });

  function setStatus(newStatus) {
    status.value = newStatus;
  }

  async function uploadFile(file) {
    setStatus(UPLOAD_STATUS_IN_PROGRESS);

    const formData = new FormData();
    formData.set("file", file);
    formData.set("location", locationStore.selectedLocation.value);
    const response = await api
      .post("/media_objects", formData, {
        headers: { "content-type": "multipart/form-data" },
      })
      .catch((e) => {
        Sentry.setContext("error", e);
        Sentry.captureException(e);
        return e;
      });

    if (!response) {
      setStatus(UPLOAD_STATUS_FAILED);
      throw new UploadFailedError();
    }

    if (response.status !== 201) {
      setStatus(UPLOAD_STATUS_FAILED);
      throw new UploadFailedError();
    }

    setStatus(UPLOAD_STATUS_SUCCESS);
    return response.data;
  }

  function deleteFile(mediaObject) {
    // When editing a report, new files added can be removed directly
    // But existing files must wait and will be deleted on save
    if (status.value !== UPLOAD_STATUS_SUCCESS) {
      return;
    }
    return api
      .delete(`/media_objects/${mediaObject.id}`)
      .then(() => true)
      .catch(() => false);
  }

  return { uploadFile, deleteFile, status, statusText };
}
