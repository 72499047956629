<script setup lang="ts">
import { TaskPriority } from "@verbleif/lib";
import { usePriority } from "./usePriority";
import ChangeableItem from "@/features/ChangeableItem/ChangeableItem.vue";

withDefaults(defineProps<{
  modelValue?: TaskPriority | null
  inline?: boolean
  isChangeable?: boolean
  disabled?: boolean
  loading?: boolean
  isClearable?: boolean
  singletonGroup?: string
}>(), {
  modelValue: null,
  inline: true,
  disabled: false,
  isChangeable: true,
  loading: true,
  isClearable: false,
  singletonGroup: undefined,
});

const emit = defineEmits<{
  "update:modelValue": [number | null]
}>();

const {
  priorityToClass,
  priorityToText,
  priorityToInlineIcon,
  priorityToStrokedIcon,
} = usePriority();

const items = computed(() => {
  return Object.values(TaskPriority).filter(value => typeof value === "number").reverse() as number[];
});

function update(v: TaskPriority | null) {
  emit("update:modelValue", v);
}
</script>

<template>
  <ChangeableItem
    :item-to-text="priorityToText"
    :item-to-class="priorityToClass"
    :singleton-group="singletonGroup"
    :items="items"
    :disabled="disabled"
    :model-value="modelValue"
    :loading="loading"
    class="priority"
    :inline="inline"
    :is-changeable="isChangeable"
    :item-to-inline-icon="priorityToInlineIcon"
    :item-to-stroked-icon="priorityToStrokedIcon"
    :is-clearable="isClearable"
    @update:model-value="update"
  />
</template>

<style lang="scss">
$priorities: (
  is-none: $grey-1,
  is-low: $success,
  is-medium: $warning,
  is-high: $danger,
  is-urgent: $danger-darker,
);

@each $name, $color in $priorities {
  .priority .#{$name} {
    .preview .#{$name} {
      fill: $color;
    }
    .preview .#{$name}-stroke {
      stroke: $color;
    }
  }

  .changeable-item-container-popover.priority .#{$name} {
    &:hover {
      color: $color;
    }

    &.is-active {
      color: $color;
    }

    .preview .#{$name} {
      fill: $color;
    }
  }
}
</style>
