<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { ActivityChangeTypeEnum } from "@verbleif/lib";
import { ActivityEntityType } from "@/features/Activities/useActivities";

const props = withDefaults(defineProps<{
  propertyName?: string | null
  type: ActivityEntityType | null
  changeType: ActivityChangeTypeEnum
  createdBySelf: boolean
}>(), {
  createdBySelf: false,
  propertyName: null,
  entityName: null,
});

const changeTypeName = ActivityChangeTypeEnum[props.changeType];
const typeName = props?.type ? ActivityEntityType?.[props.type] : null;

const { t } = useI18n();
</script>

<template>
  <span class="other-text">
    <template v-if="propertyName">
      {{
        createdBySelf ? t(`activities.self_${propertyName}_changed`) : t(`activities.others_${propertyName}_changed`)
      }}
    </template>
    <template v-if="!propertyName && typeName">
      {{
        createdBySelf
          ? t(`activities.self_${typeName}_${changeTypeName}`)
          : t(`activities.others_${typeName}_${changeTypeName}`)
      }}
    </template>
  </span>
</template>

<style scoped lang="scss">
span {
  display: flex;
  align-items: center;
}
.item-with-text {
  gap: 5px;
}
.other-text {
  color: var(--text-2);
}
</style>
