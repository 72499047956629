<script setup lang="ts">
import { useAuthStore } from "@verbleif/shared";
import type { Activity, HydraItem, User } from "@verbleif/lib";
import { ActivityOriginContextEnum } from "@verbleif/lib";
import { useActivityEntity } from "@/features/Activities/useActivityEntity";
import { useSystemStore } from "@/features/Store/useSystemStore";
import type { ActivityEntityType } from "@/features/Activities/useActivities";

const props = defineProps<{
  type: ActivityEntityType
  activity: Activity
  time: string
}>();

const { changeMap } = useActivityEntity({
  activityEntityType: props.type,
  activity: props.activity,
});
const systemStore = useSystemStore();
const authStore = useAuthStore();
const { t } = useI18n();
const originContextInfo = computed(() => {
  return t("activities.via", {
    via: t(`activities.originContext_${ActivityOriginContextEnum[props.activity.originContext]}`),
  });
});

function resolveUser(iri: string | null | HydraItem<User>): null | User {
  let user: User | null = null;

  if (!iri) {
    return null;
  }

  iri = typeof iri === "string" ? iri : iri["@id"];

  if (systemStore.usersIndexedByIri.value?.[iri]) {
    user = systemStore.usersIndexedByIri.value?.[iri];
  } else if (systemStore.systemUsersIndexedByIri.value?.[iri]) {
    user = systemStore.systemUsersIndexedByIri.value?.[iri];
  }

  return user;
}

const originContextIcon = computed(() => {
  switch (props.activity.originContext) {
    case ActivityOriginContextEnum.MOBILE_APP: return ["far", "mobile"];
    case ActivityOriginContextEnum.WEB: return ["far", "desktop"];
    case ActivityOriginContextEnum.BULK_UPDATE: return ["far", "layer-group"];
    case ActivityOriginContextEnum.AUTOMATION: return ["far", "robot"];
    case ActivityOriginContextEnum.APPSTORE_APP_SYNC: return ["far", "file-import"];
    case ActivityOriginContextEnum.APPSTORE_APP_WEBHOOK: return ["far", "webhook"];
    case ActivityOriginContextEnum.APPSTORE_APP_API_CALL: return ["far", "webhook"];
    default: return null;
  }
});

function isSameUser(iri: string | null | HydraItem<User>): boolean {
  if (!iri) {
    return false;
  }

  iri = typeof iri === "string" ? iri : iri["@id"];

  return iri === authStore.user.value?.["@id"];
}
</script>

<template>
  <div v-if="changeMap?.length" class="activity-event">
    <div class="message">
      <div class="what">
        <div v-for="(change, index) in changeMap" :key="index" class="subject">
          <span class="who" :class="{ 'is-self': isSameUser(activity.createdBy) }">
            <VTooltip :content="originContextInfo" :disabled="!originContextInfo">
              <template #reference>
                <FontAwesomeIcon v-if="originContextIcon" class="origin-context-icon" :icon="originContextIcon" />
              </template>
            </VTooltip>
            <template v-if="isSameUser(activity.createdBy)">
              {{ t('activities.you') }}
            </template>
            <template v-else-if="activity.createdBy && resolveUser(activity.createdBy)">
              {{ resolveUser(activity.createdBy)?.fullName }}
            </template>
            <template v-else>
              [{{ t('activities.unknown_user') }}]
            </template>
          </span>
          <component
            v-bind="change.props"
            :is="change.component"
            :created-by-self="isSameUser(activity.createdBy)"
          />
          <div class="when" :title="activity.createdAt">
            {{ time }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.activity-event {
  color: var(--text);
  font-size: 13px;
  display: flex;
  width: 100%;

  .message {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .what {
      display: flex;
      flex-direction: column;
      gap: 15px;
      width: 100%;

      .subject {
        display: flex;
        gap: 5px;
        flex-wrap: wrap;
        width: 100%;

        .when {
          color: var(--text-2);
          margin-left: auto;
        }
      }

      .who {
        display: flex;
        align-items: center;

        .origin-context-icon {
          width: 16px;
          text-align: center;
          margin-right: 3px;
        }

        &.is-self {
          color: var(--primary);
          font-weight: bold;
        }
      }
    }
  }
}
</style>
