<script setup lang="ts">
import moment from "moment";
import type { HydraItemContext, Reservation } from "@verbleif/lib";
import api from "@/core/api";

const props = defineProps({
  reservationIri: {
    type: String,
    required: true,
  },
});
const { t } = useI18n();
const reservation = ref<HydraItemContext<Reservation> | null>(null);

api.get<HydraItemContext<Reservation>>(props.reservationIri).then((r) => {
  reservation.value = r.data;
});

const calendarOptions = {
  sameDay: `[${t("base.today")}] HH:mm:ss`,
  nextDay: `[${t("base.tomorrow")}] HH:mm:ss`,
  nextWeek: "ll HH:mm:ss",
  lastDay: "ll HH:mm:ss",
  lastWeek: "ll HH:mm:ss",
  sameElse: "ll HH:mm:ss",
};

const confirmedAt = computed(() => {
  if (!reservation.value?.confirmedAt) {
    return t("base.not_yet");
  }
  return moment(reservation.value.confirmedAt).calendar(calendarOptions);
});
const startAt = computed(() => {
  if (!reservation.value) {
    return t("base.not_yet");
  }
  const date = moment(reservation.value.startAt).format("YYYY-MM-DD");
  const time = moment(reservation.value.checkInTimeAt).format("HH:mm:ss");
  return moment(`${date}T${time}`).calendar(calendarOptions);
});
const checkedInAt = computed(() => {
  if (!reservation.value?.checkedInAt) {
    return t("base.not_yet");
  }
  return moment(reservation.value.checkedInAt).calendar(calendarOptions);
});
const endAt = computed(() => {
  if (!reservation.value) {
    return t("base.not_yet");
  }
  const date = moment(reservation.value.endAt).format("YYYY-MM-DD");
  const time = moment(reservation.value.checkOutTimeAt).format("HH:mm:ss");
  let str = moment(`${date}T${time}`).calendar(calendarOptions);
  if (reservation.value.lateCheckout) {
    str = `${t("reservations.lateCheckout")} - ${str}`;
  }
  return str;
});
const checkedOutAt = computed(() => {
  if (!reservation.value?.checkedOutAt) {
    return t("base.not_yet");
  }
  return moment(reservation.value.checkedOutAt).calendar(calendarOptions);
});
</script>

<template>
  <div class="reservation-container">
    <template v-if="reservation">
      <div class="block timeline-item" :class="{ 'is-completed': reservation.confirmedAt }">
        <div class="left">
          <div class="icon">
            <FontAwesomeIcon :icon="['fad', 'check-double']" />
          </div>
          <div class="title">
            {{ t('reservations.confirmedAt') }}
          </div>
        </div>
        <div class="right">
          <div>{{ confirmedAt }}</div>
        </div>
      </div>
      <div class="block timeline-item" :class="{ 'is-completed': reservation.confirmedAt }">
        <div class="left">
          <div class="icon">
            <FontAwesomeIcon :icon="['fad', 'clock-ten']" />
          </div>
          <div class="title">
            {{ t('reservations.arriveAt') }}
          </div>
        </div>
        <div class="right">
          {{ reservation.humanAmount }} {{ t('base.persons', reservation.humanAmount) }} - {{ startAt }}
        </div>
      </div>
      <div class="block timeline-item" :class="{ 'is-completed': reservation.checkedInAt }">
        <div class="left">
          <div class="icon">
            <FontAwesomeIcon :icon="['fad', 'right-to-bracket']" />
          </div>
          <div class="title">
            {{ t('reservations.checkedInAt') }}
          </div>
        </div>
        <div class="right">
          {{ checkedInAt }}
        </div>
      </div>
      <div class="block-icon timeline-item is-step-icon" :class="{ 'is-completed': reservation.checkedInAt }">
        <FontAwesomeIcon class="icon" :icon="['fad', reservation.checkedInAt ? 'check-circle' : 'clock']" />
        {{ t('reservations.guestsArrived') }}
      </div>
      <div class="block timeline-item" :class="{ 'is-completed': reservation.checkedOutAt }">
        <div class="left">
          <div class="icon">
            <FontAwesomeIcon :icon="['fad', 'clock-three']" />
          </div>
          <div class="title">
            {{ t('reservations.leaveAt') }}
          </div>
        </div>
        <div class="right">
          {{ endAt }}
        </div>
      </div>
      <div class="block timeline-item" :class="{ 'is-completed': reservation.checkedOutAt }">
        <div class="left">
          <div class="icon">
            <FontAwesomeIcon :icon="['fad', 'right-from-bracket']" />
          </div>
          <div class="title">
            {{ t('reservations.checkedOutAt') }}
          </div>
        </div>
        <div class="right">
          {{ checkedOutAt }}
        </div>
      </div>
      <div class="block-icon timeline-item is-step-icon" :class="{ 'is-completed': reservation.checkedOutAt }">
        <FontAwesomeIcon class="icon" :icon="['fad', reservation.checkedOutAt ? 'check-circle' : 'clock']" />
        {{ t('reservations.guestsLeft') }}
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss">
.reservation-container {
  $timelineTopPadding: 20px;
  $timelineBottomPadding: 0px;
  $timelineGap: 30px;
  $timelineLineWidth: 3px;

  flex: 1;
  width: 100%;
  display: grid;
  background-color: var(--background);
  grid-template-columns: 1fr;
  grid-template-rows: repeat(auto-fill, 40px);
  gap: $timelineGap;
  padding: $timelineTopPadding 10px $timelineBottomPadding 10px;
  color: var(--text);

  .timeline-item {
    position: relative;
    opacity: 0.35;

    &:first-child::before {
      top: -$timelineTopPadding;
      height: $timelineTopPadding;
    }

    &:last-child::after {
      bottom: -$timelineBottomPadding;
      height: $timelineBottomPadding;
    }

    &.is-completed {
      opacity: 1;

      &::before, &::after {
        background-color: $primary;
      }

      &.is-step-icon {
        color: $primary;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: -(calc($timelineGap / 2));
      left: 50%;
      height: (calc($timelineGap / 2));
      width: $timelineLineWidth;
      background-color: var(--grey-3);
    }

    &::after {
      content: "";
      position: absolute;
      bottom: -(calc($timelineGap / 2));
      left: 50%;
      height: (calc($timelineGap / 2));
      width: $timelineLineWidth;
      background-color: var(--grey-3);
    }
  }

  .timeline-item.block-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    gap: 10px;
  }

  .timeline-item.block {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
    background-color: var(--white);
    justify-content: space-between;

    .left {
      display: flex;
      gap: 10px;
    }
    .right {
      display: flex;
      gap: 10px;
    }
  }

  .activities-wrapper {
    padding: 12px 23px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    .queue-activities {
      display: flex;
      flex-direction: column;
      margin-top: 15px;
      gap: 15px;
    }

    .activities {
      flex: 1;
      gap: 15px;
      display: flex;
      flex-direction: column;
      &:not(:first-child) {
        margin-top: 20px;
      }
    }
  }
  .message-form {
    width: 100%;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    height: calc(36px + 30px);
    display: flex;
    background: var(--background);
    padding: 15px;

    .form {
      width: 100%;
      display: flex;
      align-items: center;
    }

    .button-container {
      margin-left: 10px;
    }
  }
}
</style>
