<script setup>
import { useI18n } from "vue-i18n";
import { computed } from "vue";
import { useStatus } from "@/features/Status/useStatus";

const props = defineProps({
  groupKey: {
    default: false,
    type: [Boolean, Number, String],
  },
  groupedBy: {
    type: String,
    default: "",
  },
});

const { t } = useI18n();
const { statusToClass, statusToText } = useStatus();

const loading = computed(() => {
  return props.groupKey === "false";
});
const classes = computed(() => {
  if (loading.value) {
    return [];
  }

  if (props.groupedBy === "status") {
    return [statusToClass(Number.parseInt(props.groupKey))];
  }

  return [];
});
const text = computed(() => {
  if (loading.value) {
    return "";
  }

  if (props.groupedBy === "status") {
    return t(statusToText(Number.parseInt(props.groupKey)));
  }

  return props.groupKey;
});
</script>

<template>
  <div class="group-container">
    <div class="group-badge">
      <VSkeleton v-if="loading" :height="20" />
      <div v-else class="text" :class="[...classes, groupedBy]">
        {{ text }}
      </div>
    </div>
    <div class="grouped-items-container">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.group-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 13px;
  width: 100%;

  .grouped-items-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 13px;
    width: 100%;
    gap: 5px;
  }

  .group-badge {
    display: flex;
    height: 24px;
    margin-left: 20px;
  }

  .status {
    display: flex;
    align-items: center;
    padding-left: 10px;
    width: 200px;
    height: 24px;
    color: white;
    font-size: 14px;
    border-radius: 3px 3px 0 0;
    font-weight: 500;
    background-color: black;

    &.is-open {
      background-color: #32c5ff;
    }

    &.is-in_progress {
      background-color: #979797;
    }

    &.is-waiting {
      background-color: #ff003c;
    }

    &.is-done {
      background-color: var(--primary);
    }

    &.is-completed {
      background-color: var(--primary);
    }
  }
}
</style>
