<script setup lang="ts">
import { useI18n } from "vue-i18n";

withDefaults(defineProps<{
  propertyName: string
  fromValue: string
  toValue: string
  createdBySelf: boolean
}>(), {
  createdBySelf: false,
});

const { t } = useI18n();
</script>

<template>
  <span class="other-text">
    {{
      createdBySelf ? t(`activities.self_${propertyName}_changed`) : t(`activities.others_${propertyName}_changed`)
    }}
  </span>
  <span class="other-text">{{ t('activities.from') }}</span>
  <span class="item-with-text">
    {{ t(`activities.${propertyName}_${fromValue}_changed`) }}
  </span>
  <span class="other-text">
    {{ t('activities.to') }}
  </span>
  <span class="item-with-text">
    {{ t(`activities.${propertyName}_${toValue}_changed`) }}
  </span>
</template>

<style scoped lang="scss">
span {
  display: flex;
  align-items: center;
}
.item-with-text {
  gap: 5px;
}
.other-text {
  color: var(--text-2);
}
</style>
