import moment from "moment";
import type { HydraItem, Task } from "@verbleif/lib";
import { TaskStatus } from "@verbleif/lib";

export function useTaskTransformer() {
  function mapToIri(item: string | HydraItem<any>) {
    return typeof item === "string" ? item : item["@id"];
  }

  async function transformer(input: Task & { assignTo: string[] }) {
    const output: Partial<Task> = {
      status: input.status,
      sort: mapToIri(input.sort),
      object: null,
      space: null,
      mean: null,
      inside: input.inside,
      description: input.description,
      priority: input.priority,
      timeSensitive: input.timeSensitive,
      quantity: input.quantity,
      departments: [],
      teams: [],
      users: [],
      location: input.location,
      blocking: input.blocking,
      isTemplate: input.isTemplate,
      deadlineAt: null,
    };

    if (input.object) {
      output.object = mapToIri(input.object);
    }

    if (input.space) {
      output.space = mapToIri(input.space);
    }

    if (input.mean) {
      output.mean = mapToIri(input.mean);
    }

    if (input.deadlineAt) {
      output.deadlineAt = moment(input.deadlineAt).toISOString(true).replace(/\.\d+/, "");
    }

    if (input.mediaObjects) {
      output.mediaObjects = input.mediaObjects.map(mapToIri);
    }

    if (!input.status) {
      output.status = TaskStatus.OPEN;
    }

    if (!input.quantity) {
      output.quantity = null;
    }

    for (const assignment of input.assignTo) {
      if (assignment.includes("/departments")) {
        if (!output.departments) {
          output.departments = [];
        }
        if (output.departments.includes(assignment)) {
          break;
        }
        output.departments.push(assignment);
      }
      if (assignment.includes("/users")) {
        if (!output.users) {
          output.users = [];
        }
        if (output.users.includes(assignment)) {
          break;
        }
        output.users.push(assignment);
      }
    }

    return output;
  }

  return {
    transformer,
  };
}
