<script setup lang="ts">
import { Field, Form as VForm } from "vee-validate";
import { useI18n } from "vue-i18n";
import { Feature } from "@verbleif/shared";
import { toRefs } from "vue";
import type { HydraItem, MediaObject, Report, useModal } from "@verbleif/lib";
import { useCount } from "../../FileUpload/useCount";
import { useRightsStore } from "@/core/store/RightStore";
import DragArea from "@/features/FileUpload/DragArea.vue";
import EditReportInformation from "@/features/Reports/EditReport/EditReportInformation.vue";
import useReportSchema from "@/features/Reports/useReportSchema";
import { provideSort } from "@/features/Sort/useSort";
import PriorityItem from "@/features/Priority/PriorityItem.vue";
import InsideItem from "@/features/Inside/InsideItem.vue";
import ActivitiesView from "@/features/Activities/ActivitiesView.vue";

const props = defineProps<{
  item: HydraItem<Report>
  mediaObjects: HydraItem<MediaObject>[]
  showTransferToTask: (item: HydraItem<Report>) => any
  manager: ReturnType<typeof useModal>
}>();
const { hasFeature } = useRightsStore();
const { item } = toRefs(props);
const TRANSLATION_KEY = "reports";
const { t } = useI18n();
const { sortField } = provideSort();
const { schema } = useReportSchema(sortField);
const mediaObjects = ref(props.mediaObjects);

const { attachmentTitle, setCount } = useCount(
  TRANSLATION_KEY,
  mediaObjects,
);

const initialValues = computed<HydraItem<Report>>(() => {
  return {
    ...item.value,
    mediaObjects: mediaObjects.value.map(m => m["@id"]),
  };
});
</script>

<template>
  <div class="edit-report-container">
    <VForm
      v-slot="{ errors }"
      :initial-values="initialValues"
      :validation-schema="schema"
      class="form"
      @submit="manager.options.onOk"
    >
      <div class="report-content">
        <div class="report-info">
          <div class="report-info-header">
            <VButton
              :icon-left="['far', 'exchange']"
              variant="is-light"
              rounded
              @click="() => showTransferToTask(initialValues)"
            >
              {{ t("reports.actions.transfer_to_task") }}
            </VButton>
            <Field v-slot="{ field }" name="inside">
              <InsideItem
                v-if="!hasFeature(Feature.HIDE_INSIDE)"
                singleton-group="report-edit"
                :loading="false"
                :inline="false"
                :model-value="field.value"
                is-clearable
                @update:model-value="field.onChange"
              />
            </Field>
            <Field v-slot="{ field }" name="priority">
              <PriorityItem
                v-if="!hasFeature(Feature.HIDE_PRIORITY)"
                singleton-group="report-edit"
                :loading="false"
                :inline="false"
                :model-value="field.value"
                is-clearable
                @update:model-value="field.onChange"
              />
            </Field>
          </div>
          <EditReportInformation :item="item" />
          <!-- SUBMIT -->
          <div class="error-list">
            <div
              v-for="(value, key) in errors"
              :key="key + value"
              class="error-item is-danger"
            >
              {{ t(`${TRANSLATION_KEY}.information.${key}_label`) }}:
              {{ value }}
            </div>
          </div>
          <div class="buttons">
            <VButton
              :loading="manager.loading.value"
              class="submit-button"
              type="submit"
            >
              {{ manager.options.okText }}
            </VButton>
          </div>
        </div>
        <div class="report-side">
          <VTabs>
            <VTabItem :title="t(`${TRANSLATION_KEY}.information.tab_title`)">
              <ActivitiesView :type="ActivityEntityType.REPORT" :item="item" />
            </VTabItem>
            <VTabItem :title="attachmentTitle">
              <DragArea
                :initial-value="mediaObjects"
                :editable="true"
                class="drag-area"
                @count-changed="setCount"
              />
            </VTabItem>
          </VTabs>
        </div>
      </div>
    </VForm>
  </div>
</template>

<style lang="scss" scoped>
.edit-report-container {
  display: flex;
  flex-direction: column;
  width: 1000px;
  flex: 1;
  z-index: 2; // modal header has 1
  padding-top: 15px;
  height: 100%;

  @media (max-width: 768px) {
    width: auto;

    .report-content {
      display: flex;
      max-height: none;
      flex-direction: column;
      height: 100%;

      .report-info {
        min-height: 500px;
        min-width: 100%;
      }

      .report-side {
        max-height: 600px;
      }
    }
  }

  .report-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    box-sizing: border-box;
    gap: 10px;
    height: 100%;
    width: 100%;
    position: relative;

    .report-info {
      display: flex;
      flex-direction: column;
      gap: 10px;
      flex: 1 1 100%;
      padding-left: 15px;
      padding-bottom: 15px;
      height: 100%;

      .report-info-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
      }

    }

    .report-side {
      padding-top: 15px;
      width: 50%;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;

      .drag-area {
        padding-right: 15px;
        padding-bottom: 15px;
      }
    }
  }
  .form {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: calc(100% - 35px);
  }

  .error-list {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    gap: 5px;

    .error-item {
      &.is-danger {
        color: var(--secondary);
      }
    }
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    bottom: 0;
    margin-top: 10px;

    .submit-button {
      align-self: flex-start;
    }
  }
  .tabs-container {
    display: flex;
    width: 100%;
    flex: 1;
    height: 100%;

    .tab-container:nth-child(1) {
      height: 100%;
      gap: 100px;
    }
  }
}

.icon_holder {
  flex: 1;
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
</style>
