import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink",
  width: "32",
  height: "32",
  viewBox: "0 0 512 512"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<defs><path id=\"a\" d=\"M256 512c141.385 0 256-114.615 256-256S397.385 0 256 0 0 114.615 0 256s114.615 256 256 256\"></path><mask id=\"b\" width=\"512\" height=\"512\" x=\"0\" y=\"0\" fill=\"#fff\" maskContentUnits=\"userSpaceOnUse\" maskUnits=\"objectBoundingBox\"><use xlink:href=\"#a\"></use></mask></defs><g fill=\"none\" fill-rule=\"evenodd\"><use xlink:href=\"#a\" stroke=\"#CCC\" stroke-dasharray=\"67\" stroke-width=\"60\" mask=\"url(#b)\"></use><path fill=\"#CCC\" fill-rule=\"nonzero\" d=\"M204 118.5c9.541 0 17.25 7.709 17.25 17.25V153h69v-17.25c0-9.541 7.709-17.25 17.25-17.25s17.25 7.709 17.25 17.25V153h25.875c14.285 0 25.875 11.59 25.875 25.875v25.875H135v-25.875C135 164.59 146.59 153 160.875 153h25.875v-17.25c0-9.541 7.709-17.25 17.25-17.25M135 222h241.5v146.625c0 14.285-11.59 25.875-25.875 25.875h-189.75C146.59 394.5 135 382.91 135 368.625zm43.125 34.5c-4.744 0-8.625 3.881-8.625 8.625v51.75c0 4.744 3.881 8.625 8.625 8.625h51.75c4.744 0 8.625-3.881 8.625-8.625v-51.75c0-4.744-3.881-8.625-8.625-8.625z\"></path><g transform=\"translate(286.423 286.423)\"><circle cx=\"112.788\" cy=\"112.788\" r=\"112.788\" fill=\"#4CAF50\"></circle><path fill=\"#FFF\" fill-rule=\"nonzero\" d=\"M174.007 113.039a9.4 9.4 0 0 1-9.399 9.402h-42.295v42.296c0 5.196-4.21 9.396-9.4 9.396s-9.398-4.2-9.398-9.396V122.44H61.219a9.4 9.4 0 0 1-9.399-9.402c0-5.196 4.209-9.396 9.399-9.396h42.296V61.347a9.4 9.4 0 0 1 9.399-9.402 9.4 9.4 0 0 1 9.399 9.402v42.296h42.295a9.385 9.385 0 0 1 9.4 9.396\"></path></g></g>", 2)
  ])))
}
export default { render: render }