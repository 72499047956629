import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16",
  viewBox: "0 0 512 512"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      fill: "none",
      "fill-rule": "evenodd"
    }, [
      _createElementVNode("path", { d: "M0 0h512v512H0z" }),
      _createElementVNode("path", {
        fill: "#4CAF50",
        "fill-rule": "nonzero",
        d: "M80 368H16c-8.831 0-16 7.17-16 16v64c0 8.831 7.169 16 16 16h64c8.83 0 16-7.169 16-16v-64c0-8.83-7.17-16-16-16m0-319.999H16c-8.831 0-16 7.169-16 16v64c0 8.83 7.169 16 16 16h64c8.83 0 16-7.17 16-16V64c0-8.831-7.17-16-16-16m0 160H16c-8.831 0-16 7.168-16 16v64c0 8.83 7.169 16 16 16h64c8.83 0 16-7.17 16-16v-64c0-8.832-7.17-16-16-16m415.999 176h-320c-8.83 0-16 7.168-16 16V432c0 8.831 7.17 16 16 16h320c8.83 0 16-7.169 16-16v-32c0-8.83-7.17-16-16-16m0-320h-320c-8.83 0-16 7.169-16 16v32c0 8.83 7.17 16 16 16h320c8.83 0 16-7.17 16-16V80c0-8.831-7.17-16-16-16m0 160h-320c-8.83 0-16 7.168-16 16v32c0 8.83 7.17 16 16 16h320c8.83 0 16-7.17 16-16v-32c0-8.832-7.17-16-16-16"
      })
    ], -1)
  ])))
}
export default { render: render }