import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16",
  viewBox: "0 0 512 512"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      fill: "none",
      "fill-rule": "evenodd"
    }, [
      _createElementVNode("path", { d: "M0 0h512v512H0z" }),
      _createElementVNode("path", {
        fill: "#4CAF50",
        "fill-rule": "nonzero",
        d: "M173.898 439.404c9.996 9.998 26.205 9.998 36.203.001l294.4-294.4c9.997-9.998 9.997-26.207 0-36.204l-36.203-36.204c-9.997-9.997-26.207-9.997-36.204 0L192 312.69 79.905 200.596c-9.997-9.998-26.207-9.998-36.204 0L7.498 236.8c-9.997 9.998-9.997 26.207 0 36.204z"
      })
    ], -1)
  ])))
}
export default { render: render }