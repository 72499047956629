<script setup lang="ts">
import { watch } from "vue";
import { useI18n } from "vue-i18n";
import { usePaginate } from "@verbleif/lib";
import { useTaskOverviewSettings } from "@/features/Tasks/Overview/useTaskOverviewSettings";

const { t } = useI18n();
const { overviewSettings } = useTaskOverviewSettings();
const { resetAndLoad } = usePaginate();

watch(overviewSettings, () => {
  resetAndLoad();
});
</script>

<template>
  <VPopper
    class="overview-settings"
    root-class="is-default"
    trigger="clickToToggle"
    append-to-body
    visible-arrow
    :options="{ placement: 'bottom' }"
    transition="fade"
  >
    <div class="overviewSettings">
      <div class="title">
        {{ t("tasks.overview_options") }}
      </div>
      <div class="option">
        <div class="text">
          {{ t("tasks.overview_option_show_completed_tasks") }}
        </div>
        <div class="control">
          <VSwitch
            v-model="overviewSettings.showStatusCompleted"
            size="is-small"
          />
        </div>
      </div>
      <div class="option">
        <div class="text">
          {{ t("tasks.overview_option_show_done_tasks") }}
        </div>
        <div class="control">
          <VSwitch v-model="overviewSettings.showStatusDone" size="is-small" />
        </div>
      </div>
      <div class="option">
        <div class="text">
          {{ t("tasks.overview_option_hide_search_and_sort") }}
        </div>
        <div class="control">
          <VSwitch
            v-model="overviewSettings.hideSearchAndSort"
            size="is-small"
          />
        </div>
      </div>
    </div>
    <template #reference>
      <FontAwesomeIcon class="settings icon" :icon="['fas', 'cog']" />
    </template>
  </VPopper>
</template>

<style lang="scss" scoped>
::v-deep(.icon) {
  color: var(--text);
  opacity: 0.5;
}

.overviewSettings {
  padding: 5px;
  display: flex;
  flex-direction: column;
  width: 225px;

  .title {
    width: 190px;
    font-size: 10px;
    text-transform: uppercase;
    color: var(--text-2);
    margin-bottom: 10px;
  }

  .option {
    display: flex;
    flex-direction: row;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .text {
      font-size: 11px;
      font-weight: normal;
      color: var(--text);
    }

    .control {
      margin-left: auto;
    }
  }
}
</style>
