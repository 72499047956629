<script>
export default {
  name: "NoReports",
  props: {
    text: {
      type: String,
      default: "No reports yet. Nothing to see here.",
    },
  },
};
</script>

<template>
  <div class="no-tasks-container">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 708.66 708.66"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            class="cls-1"
            d="M550,349.32s-3.35-98.94-44.42-128.85S458.47,199.36,434,199.36s-119.5-1.21-119.5-1.21l.72,1.6c-8.25-.28-15.51-.39-21.27-.39-43.66,0-69.64,31.81-82.76,75a77.32,77.32,0,0,0-3.49,20.22h0l-2.55,54.77s-26.43,48.16-30.77,67.2-9,45.19,33.32,51.86,92.68-25.29,92.68-25.29,46.22-40.8,58.42-58.21a277.85,277.85,0,0,0,19.68-32.8c1.46-.45,2.9-.91,4.33-1.39h0V592.17h41.54V350.35Z"
          />
        </clipPath>
      </defs>
      <g id="meldingen">
        <circle class="cls-2" cx="450.66" cy="249.38" r="207.88" />
        <circle class="cls-2" cx="189.06" cy="162.54" r="138.94" />
        <circle class="cls-2" cx="332.89" cy="432.75" r="252.3" />
        <polygon
          class="cls-3"
          points="444.72 180.44 382.75 180.44 382.75 144.64 444.72 144.64 433.43 162.54 444.72 180.44"
        />
        <path
          class="cls-4"
          d="M550,349.32s-3.35-98.94-44.42-128.85S458.47,199.36,434,199.36s-119.5-1.21-119.5-1.21l.72,1.6c-8.25-.28-15.51-.39-21.27-.39-43.66,0-69.64,31.81-82.76,75a77.32,77.32,0,0,0-3.49,20.22h0l-2.55,54.77s-26.43,48.16-30.77,67.2-9,45.19,33.32,51.86,92.68-25.29,92.68-25.29,46.22-40.8,58.42-58.21a277.85,277.85,0,0,0,19.68-32.8c1.46-.45,2.9-.91,4.33-1.39h0V592.17h41.54V350.35Z"
        />
        <path
          class="cls-5"
          d="M205.09,349.32l177.66,1.37s-.95-82-19.67-109.45-37.48-42.45-71.61-41.88S231.1,230.3,231.1,230.3,203.08,273.72,205.09,349.32Z"
        />
        <g class="cls-6">
          <rect
            class="cls-7"
            x="381.91"
            y="350.68"
            width="73.47"
            height="73.47"
          />
        </g>
        <polyline
          class="cls-8"
          points="382.75 349.32 382.75 592.17 424.29 592.17 424.29 349.32"
        />
        <line class="cls-8" x1="549.96" y1="349.32" x2="205.09" y2="349.32" />
        <line class="cls-8" x1="461.13" y1="199.36" x2="293.92" y2="199.36" />
        <path
          class="cls-8"
          d="M205.09,349.32c0-114.84,39.77-150,88.83-150s88.83,33.77,88.83,150"
        />
        <path
          class="cls-8"
          d="M205.09,349.32c-56,91.77-33.37,119.84,15.68,119.84s105.31-27,162-119.84"
        />
        <path class="cls-8" d="M461.13,199.36c49.06,0,88.83,67.14,88.83,150" />
        <circle class="cls-8" cx="403.52" cy="241.24" r="20.77" />
        <circle class="cls-8" cx="403.52" cy="241.24" r="1.6" />
        <polygon
          class="cls-9"
          points="450.66 180.44 382.75 180.44 382.75 144.64 450.66 144.64 433.43 162.54 450.66 180.44"
        />
        <circle class="cls-10" cx="135.45" cy="130.29" r="3.55" />
        <circle class="cls-10" cx="185.52" cy="130.29" r="3.55" />
        <circle class="cls-10" cx="236.51" cy="130.29" r="3.55" />
        <path
          class="cls-11"
          d="M479.76,648.92c25.8,0,25.78-25.78,25.78-25.78,0,25.78,25.79,25.78,25.79,25.78-25.79,0-25.79,25.79-25.79,25.79C505.54,649.87,479.76,648.92,479.76,648.92Z"
        />
        <path
          class="cls-12"
          d="M89.78,492.3c25.8,0,25.79-25.78,25.79-25.78,0,25.78,25.79,25.78,25.79,25.78-25.79,0-25.79,25.79-25.79,25.79C115.57,493.25,89.78,492.3,89.78,492.3Z"
        />
        <path
          class="cls-13"
          d="M568.17,51.07C594,51.07,594,25.28,594,25.28c0,25.79,25.79,25.79,25.79,25.79C594,51.07,594,76.86,594,76.86,594,52,568.17,51.07,568.17,51.07Z"
        />
        <path
          class="cls-14"
          d="M30.29,126.74c19.84,0,19.83-19.83,19.83-19.83,0,19.83,19.83,19.83,19.83,19.83-19.83,0-19.83,19.83-19.83,19.83C50.12,127.47,30.29,126.74,30.29,126.74Z"
        />
        <path
          class="cls-15"
          d="M510.3,399c19.84,0,19.83-19.83,19.83-19.83C530.13,399,550,399,550,399c-19.83,0-19.83,19.84-19.83,19.84C530.13,399.75,510.3,399,510.3,399Z"
        />
        <line class="cls-8" x1="382.75" y1="241.24" x2="382.75" y2="144.64" />
      </g>
    </svg>
    <div class="text">
      {{ text }}
    </div>
    <div class="slot">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.no-tasks-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 100px;

  svg {
    width: 200px;
  }

  .text {
    margin-top: 25px;
    color: var(--text);
  }

  .slot {
    margin-top: 25px;
  }
}

.cls-1,
.cls-10,
.cls-11,
.cls-12,
.cls-13,
.cls-8 {
  fill: none;
}

.cls-2 {
  fill: var(--primary-lighter);
}

.cls-3 {
  fill: var(--primary-light);
}

.cls-4 {
  fill: #fff;
}

.cls-5 {
  fill: var(--primary-light);
}

.cls-6 {
  clip-path: url(#clip-path);
}

.cls-7 {
  fill: var(--primary-lighter);
}

.cls-10,
.cls-8 {
  stroke: var(--primary-dark);
  stroke-miterlimit: 10;
}

.cls-10,
.cls-11,
.cls-12,
.cls-13,
.cls-8 {
  stroke-linecap: round;
}

.cls-8 {
  stroke-width: 7px;
}

.cls-9 {
  fill: var(--primary-light);
}

.cls-10 {
  stroke-width: 10px;
}

.cls-11 {
  fill: var(--secondary-light);
}

.cls-11,
.cls-12,
.cls-13 {
  stroke-linejoin: round;
  stroke-width: 4px;
}

.cls-12 {
  stroke: var(--secondary);
}

.cls-13 {
  stroke: var(--secondary-dark);
}

.cls-14 {
  fill: var(--secondary-light);
}

.cls-15 {
  fill: var(--secondary);
}
</style>
