import { computed } from "vue";
import { useI18n } from "vue-i18n";
import * as yup from "yup";
import {
  TASK_TYPE_OBJECT,
} from "../Tasks/useTaskType";
import useSystemStore from "@/features/Store/useSystemStore";

export default function useReportSchema(sortField, isPublicReport = false) {
  const { t } = useI18n();
  const systemStore = useSystemStore();

  const schema = computed(() => {
    const baseSchema = {
      sort: yup
        .string()
        .typeError(t("validation.required"))
        .required(t("validation.required")),
      object: yup.string().nullable(),
      description: yup.string().required(t("validation.required")),
      priority: yup.string().typeError(t("validation.required")).nullable(),
      inside: yup.boolean().nullable().typeError(t("validation.required")),
    };

    if (isPublicReport) {
      baseSchema.meta = yup.object().shape({
        name: yup.string().required(t("validation.required")),
        phone: yup
          .string()
          .matches(
            /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
            t("validation.phone_invalid"),
          ) // https://stackoverflow.com/questions/52483260/validate-phone-number-with-yup
          .required(t("validation.required")),
      });
    }

    /**
     * ITS REALLY UGLY THE .value.value.value, explanation:
     * sortField is a ref, so the first .value is for this.
     * The second .value is because vee-validate has named their current field value "value" so the second .value
     * Because the current field value is a computed, we also need a third .value.
     */

    if (!sortField.value?.value) {
      return yup.object().shape(baseSchema);
    }

    const sort = systemStore.sorts.value.find(s => s["@id"] === sortField.value);
    if (
      sort
      && [
        TASK_TYPE_OBJECT,
      ].includes(sortField.value?.value?.type)
    ) {
      baseSchema.object = yup
        .object()
        .typeError(t("validation.required"))
        .required(t("validation.required"));
    }

    return yup.object().shape(baseSchema);
  });

  return {
    schema,
  };
}
