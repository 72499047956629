import { useLocationStore } from "@verbleif/shared";
import type { AxiosRequestConfig } from "axios";
import api from "@/core/api";

export function useTasks() {
  const locationStore = useLocationStore();
  let currentAbortController: null | AbortController = null;

  async function getTasks(options: AxiosRequestConfig, isExport: boolean = false) {
    if (currentAbortController) {
      currentAbortController.abort();
    }

    currentAbortController = new AbortController();
    options.signal = currentAbortController.signal;

    const locationIri = locationStore.selectedLocation.value;
    if (!locationIri) {
      throw new Error("No location selected");
    }

    if (isExport) {
      return await api.get(`${locationIri}/tasks/export`, options);
    }

    return await api.get(`${locationIri}/tasks`, options);
  }

  return {
    getTasks,
  };
}
