<script setup lang="ts">
import { computed, ref, toRefs, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import type {
  HydraItem, SkeletonItem,
  Task,
} from "@verbleif/lib";
import {
  LoopbackFilterLogicalOperatorEnum,
  LoopbackFilterOperatorEnum,
  providePaginate,
  useLazyLoadScroll,
} from "@verbleif/lib";
import * as Sentry from "@sentry/vue";
import { Feature, UserRole, useAuthStore } from "@verbleif/shared";
import TaskItem from "./TaskItem/TaskItem.vue";
import GroupedItem from "./GroupedItem.vue";
import { useTaskList } from "./useTaskList";
import { useTasks } from "./useTasks";
import VTaskFilter from "./VTaskFilter.vue";
import { useRightsStore } from "@/core/store/RightStore";
import TaskOverviewSettings from "@/features/Tasks/Overview/TaskOverviewSettings.vue";
import NoTasks from "@/assets/NoTasks.vue";
import TasksMultiSelect from "@/features/Tasks/Overview/TasksMultiSelect.vue";
import VDateSelector from "@/features/Tasks/Overview/VDateSelector.vue";
import { provideFilters } from "@/features/Tasks/Overview/useFilters";
import useExport from "@/features/Tasks/Overview/Export/useExport";
import { useGroupBy } from "@/composables/GroupBy/useGroupBy";
import { TaskListType } from "@/features/Tasks/TasksRoutes";
import api from "@/core/api";
import { provideTaskOverviewSettings } from "@/features/Tasks/Overview/useTaskOverviewSettings.js";
import { TaskParamHookApplyEnum, useTaskParams } from "@/features/Tasks/Overview/useTaskParams.js";
import { useTaskMercure } from "@/features/Tasks/Overview/useTaskMercure.ts";
import { provideMultiSelect } from "@/features/Tasks/Overview/useMultiSelect.ts";
import { useDateSelector } from "@/features/Tasks/Overview/useDateSelector.ts";

const props = defineProps({
  setLoadFunction: {
    default: () => {},
    type: Function,
  },
});

defineEmits(["showCreate", "showEdit", "showRemove", "showTemplateOptions"]);

const authStore = useAuthStore();
const { countFilters, isFilterActive, getParams: getFilterParams } = provideFilters();
const TRANSLATION_KEY = "tasks";
const { hasRole } = useRightsStore();
const { t } = useI18n();
const route = useRoute();
const taskContainer = ref(null);
const { getParams: getDateSelectorParams, dateSelector } = useDateSelector();
const { hasFeature } = useRightsStore();
const { getParams: getSettingsParams, overviewSettings } = provideTaskOverviewSettings();
const { setLoadFunction } = toRefs(props);
const { currentListId, currentListTranslation, currentListName, currentListType } = useTaskList();
const { getTasks } = useTasks();
const { getParams } = useTaskParams(currentListType, currentListId, [
  { when: TaskParamHookApplyEnum.BEFORE, fn: getSettingsParams },
  { when: TaskParamHookApplyEnum.BEFORE, fn: getDateSelectorParams },
  { when: TaskParamHookApplyEnum.AFTER, fn: getFilterParams },
]);
const { groupBy } = useGroupBy<HydraItem<Task> | SkeletonItem>();
const { exportTasks, loading: loadingExport } = useExport();
const {
  resetAndLoad,
  load,
  loading,
  items,
  cursor: { currentPage, hasNextPage, totalItems },
} = providePaginate<HydraItem<Task>>({
  cursorParams: { partial: true },
  initialPerPage: 25,
  sortableFields: {
    "blocking": t(`${TRANSLATION_KEY}.blocking.field`),
    "createdAt": t(`${TRANSLATION_KEY}.createdAt`),
    "deadlineAt": t(`${TRANSLATION_KEY}.deadline`),
    "group.name": t(`${TRANSLATION_KEY}.group`),
    "group.parentGroup.name": t(`${TRANSLATION_KEY}.parentGroup`),
    "inside": t(`${TRANSLATION_KEY}.inside.field`),
    "object.name": t(`${TRANSLATION_KEY}.object`),
    "priority": t(`${TRANSLATION_KEY}.priority.field`),
    "updatedAt": t(`${TRANSLATION_KEY}.updatedAt`),
  } as unknown as Partial<Record<keyof Task, string>>, // HACK HACK HACK HACK HACK HACK
  searchableFields: [
    {
      name: "object.name",
      operator: LoopbackFilterOperatorEnum.ILIKE,
      logicalOperator: LoopbackFilterLogicalOperatorEnum.OR,
    },
    {
      name: "description",
      operator: LoopbackFilterOperatorEnum.ILIKE,
      logicalOperator: LoopbackFilterLogicalOperatorEnum.OR,
    },
  ] as any, // HACK HACK HACK HACK HACK HACK,
  onLoad: options => getTasks(options),
  loadParams: params => getParams(params),
});
useTaskMercure(items, loading);
provideMultiSelect(items, resetAndLoad);
useLazyLoadScroll(
  taskContainer,
  (options) => {
    currentPage.value++;
    load(options);
  },
  hasNextPage,
  loading,
);

const amountOfTasks = computed(() => {
  return Object.keys(items.value).length;
});
const listHasTasks = computed(() => {
  return !!amountOfTasks.value;
});
const groupedTasks = computed(() => {
  return groupBy(items.value, "status");
});

function onExportTasks() {
  if (loading.value) {
    return;
  }
  console.log("before export");
  exportTasks(getTasks, getParams, t(currentListTranslation.value, {
    count: totalItems.value,
    list: currentListName.value,
  }));
}

// Count filter works, because when changing a value the condition or rightHand is being reset, causing the filter to be removed.
watch(countFilters, () => {
  resetAndLoad();
}, {
  deep: true,
});
watch(dateSelector, () => {
  resetAndLoad();
});

load();

setLoadFunction.value(resetAndLoad);

const showDateSelector = computed(() => {
  return (
    currentListType.value !== TaskListType.TYPE_TODAY
    && currentListType.value !== TaskListType.TYPE_ALL_TODAY
    && currentListType.value !== TaskListType.TYPE_OPEN_UNTIL_TODAY
    && currentListType.value !== TaskListType.TYPE_ALL_UNTIL_TODAY
  );
});

watch(
  () => route.params,
  () => {
    if (route.name !== "task-list") {
      return;
    }

    resetAndLoad();
  },
);

function updateTask(input: Partial<Task>, task: HydraItem<Task>) {
  const oldItem = Object.assign({}, task);
  Object.assign(task, input);
  api
    .patch(task["@id"], input)
    .then((r) => {
      Object.assign(task, r.data);
    })
    .catch((e) => {
      Sentry.setContext("error", e);
      Sentry.captureException(e);
      Object.assign(task, oldItem);
    });
}
</script>

<template>
  <TasksMultiSelect />
  <div ref="taskContainer" class="container">
    <div class="header">
      <div class="left-container">
        <div class="title">
          {{
            t(currentListTranslation, {
              count: totalItems,
              list: currentListName,
            })
          }}
        </div>
        <TaskOverviewSettings />
      </div>

      <div class="right-container">
        <FontAwesomeIcon
          title="De export is gebaseerd op je huidige filters"
          :icon="loadingExport ? ['fa', 'spinner'] : ['fal', 'file-excel']"
          :spin="loadingExport"
          class="export"
          @click="onExportTasks"
        />
        <VButton
          v-if="!hasFeature(Feature.HIDE_CREATE_TASK) || hasRole(UserRole.ROLE_DEPARTMENT_MANAGER)"
          class="button"
          icon-left="plus"
          @click="$emit('showCreate')"
        >
          {{ t(`${TRANSLATION_KEY}.create_title`) }}
        </VButton>
      </div>
    </div>
    <div v-if="!overviewSettings.hideSearchAndSort" class="table-controls">
      <VTaskFilter />
      <VSearch
        :clear-search-text="t('base.table.clear_search')"
        :placeholder-text="t('base.table.search_placeholder')"
      />
      <VSort
        :ascending-text="t('base.table.ascending')"
        :descending-text="t('base.table.descending')"
        :disabled-text="t('base.table.sort_disabled')"
        :placeholder-text="t('base.table.sort_placeholder')"
      />
      <VDateSelector v-if="showDateSelector" v-model="dateSelector" />
    </div>
    <div v-if="listHasTasks" class="tasks">
      <GroupedItem
        v-for="(tasks, groupKey) in groupedTasks"
        :key="groupKey"
        grouped-by="status"
        :group-key="groupKey"
      >
        <TaskItem
          v-for="(task, index) in tasks"
          :key="index"
          :task="task"
          :index="index"
          @update="(input) => !('skeleton' in task) && updateTask(input, task)"
          @on-row-click="$emit('showEdit', task)"
        >
          <template #actions>
            <VDropdownItem
              v-if="!hasRole(UserRole.ROLE_DEPARTMENT_MANAGER)"
              :icon="['fas', 'eye']"
              :text="t('base.table.view')"
              close-on-click
              @click="$emit('showEdit', task)"
            />
            <VDropdownItem
              v-if="
                !('skeleton' in task)
                  && (hasRole(UserRole.ROLE_DEPARTMENT_MANAGER)
                    || typeof task.createdBy !== 'string' && task.createdBy?.id === authStore.user?.value?.id
                    || typeof task.createdBy === 'string' && task.createdBy === authStore.user?.value?.['@id'])
              "
              :icon="['fas', 'pencil']"
              :text="t('base.table.edit')"
              close-on-click
              @click="$emit('showEdit', task)"
            />
            <VDropdownItem
              v-if="hasRole(UserRole.ROLE_PARK_MANAGER)"
              :icon="['fas', 'trash']"
              :text="t('base.table.remove')"
              class="is-danger"
              close-on-click
              @click="$emit('showRemove', task)"
            />
            <VDropdownItem
              v-if="hasRole(UserRole.ROLE_ADMIN)"
              :icon="['fad', 'pen-ruler']"
              :text="t('tasks.dropdown.view_template')"
              close-on-click
              @click="$emit('showTemplateOptions', task)"
            />
          </template>
        </TaskItem>
      </GroupedItem>
    </div>
    <div v-else class="tasks empty">
      <NoTasks
        :text="
          t(
            `${TRANSLATION_KEY}.${
              isFilterActive || dateSelector?.length
                ? 'no_tasks_filters'
                : 'no_tasks'
            }`,
          )
        "
      >
        <VButton
          v-if="!hasFeature(Feature.HIDE_CREATE_TASK) || hasRole(UserRole.ROLE_DEPARTMENT_MANAGER)"
          class="button"
          icon-left="plus"
          @click="$emit('showCreate')"
        >
          {{ t(`${TRANSLATION_KEY}.create_title`) }}
        </VButton>
      </NoTasks>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.loading {
  margin: 20px;
  opacity: 0.8;
}

.container {
  width: 100%;
  height: 100%;
  padding: 18px 25px 100px 5px;
  overflow-y: auto;

  .header {
    margin-left: 20px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 13px;

    .title {
      color: var(--text);
      font-size: 25px;
      font-weight: bold;
    }

    .left-container {
      display: flex;
      gap: 15px;
      justify-content: space-between;
      align-items: center;

      .overview-settings {
        color: var(--grey-3);
        cursor: pointer;

        &:hover {
          cursor: pointer;
          color: var(--primary);
        }
      }
    }

    .right-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 15px;

      .export {
        color: var(--grey-3);
        font-size: 20px;

        &:hover {
          cursor: pointer;
          color: var(--primary);
        }
      }

      .button {
        white-space: nowrap;
      }
    }
  }
}

.table-controls {
  align-items: center;
  display: flex;
  margin-bottom: 13px;
  margin-left: 20px;
}

.v-name-profile-pic {
  .image {
    display: inline-flex;
    font-size: 17px;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background: darkgrey;
    align-items: center;
    justify-content: center;
    color: white;
    margin-right: 10px;
  }
}
</style>
