<script setup lang="ts">
import { useI18n } from "vue-i18n";
import DeadlineItem from "@/features/Tasks/Overview/TaskItem/DeadlineItem.vue";

const props = defineProps({
  propertyName: {
    type: String,
    required: true,
  },
  fromValue: {
    type: String,
    required: true,
  },
  toValue: {
    type: String,
    required: true,
  },
  timeSensitiveFrom: {
    type: Boolean,
    default: null,
  },
  timeSensitiveTo: {
    type: Boolean,
    default: null,
  },
  createdBySelf: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();

const hasTimesensitiveChange = !!props.timeSensitiveFrom && !!props.timeSensitiveTo;
</script>

<template>
  <span class="other-text">
    {{ createdBySelf ? t('activities.self_deadline_changed') : t('activities.others_deadline_changed') }}
  </span>
  <span class="other-text">{{ t('activities.from') }}</span>
  <span class="item-with-text">
    <template v-if="!fromValue">
      {{ t('activities.nothing') }}
    </template>
    <DeadlineItem
      v-else
      class="deadline-item"
      :time-sensitive="hasTimesensitiveChange ? timeSensitiveFrom : true"
      :deadline-at="fromValue"
      :loading="false"
      single-line
    />
  </span>
  <span class="other-text">
    {{ t('activities.to') }}
  </span>
  <span class="item-with-text">
    <template v-if="!toValue">
      {{ t('activities.nothing') }}
    </template>
    <DeadlineItem
      v-else
      :time-sensitive="hasTimesensitiveChange ? timeSensitiveTo : true"
      :deadline-at="toValue"
      :loading="false"
      single-line
    />
  </span>
</template>

<style scoped lang="scss">
::v-deep(.deadline-item .deadline div) {
  line-height: 1 !important;
}
span {
  display: flex;
  align-items: center;
}
.item-with-text {
  gap: 5px;
}
.other-text {
  color: var(--text-2);
}
</style>
