<script setup lang="ts">
import { useI18n } from "vue-i18n";
import InsideItem from "@/features/Inside/InsideItem.vue";
import { useInside } from "@/features/Inside/useInside";

withDefaults(defineProps<{
  propertyName: string
  fromValue: number | null
  toValue: number | null
  createdBySelf: boolean
}>(), {
  createdBySelf: false,
});

const { t } = useI18n();
const { insideToText } = useInside();
</script>

<template>
  <span class="other-text">
    {{ createdBySelf ? t('activities.self_inside_changed') : t('activities.others_inside_changed') }}
  </span>
  <span class="other-text">{{ t('activities.from') }}</span>
  <span class="item-with-text">
    <InsideItem
      :model-value="fromValue"
      :is-changeable="false"
      :loading="false"
      inline
    />
    <span>
      {{ fromValue === null ? t('activities.nothing_c') : t(insideToText(fromValue)) }}
    </span>
  </span>
  <span class="other-text">
    {{ t('activities.to') }}
  </span>
  <span class="item-with-text">
    <InsideItem
      :model-value="toValue"
      :is-changeable="false"
      :loading="false"
      inline
    />
    <span>
      {{ toValue === null ? t('activities.nothing_c') : t(insideToText(toValue)) }}
    </span>
  </span>
</template>

<style scoped lang="scss">
span {
  display: flex;
  align-items: center;
}
.item-with-text {
  gap: 5px;
}
.other-text {
  color: var(--text-2);
}
</style>
