import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 512 512"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#F44336",
      "fill-rule": "nonzero",
      d: "M463.956 80.128h-82.392l-33.996-56.694C339.176 9.45 323.866.084 306.384.084c-.014 0 .003.052-.011.052H205.539c-17.482 0-32.76 9.315-41.152 23.298L130.39 80.128H47.998C39.168 80.128 32 87.296 32 96.127v15.998c0 8.83 7.168 15.998 15.998 15.998h15.999L85.195 467.09c1.57 25.074 22.43 44.996 47.894 44.996h245.776c25.466 0 46.325-19.922 47.895-44.996l21.198-338.966h15.998c8.83 0 15.999-7.168 15.999-15.998V96.127c0-8.83-7.169-15.999-15.999-15.999M205.582 48.131h100.79l19.198 31.997H186.385zM378.865 464.09H133.1l-20.998-335.966h287.771z"
    }, null, -1)
  ])))
}
export default { render: render }