import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 512 512"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#979797",
      "fill-rule": "nonzero",
      d: "M393.846 256.077c0-32.615 26.462-59.077 59.077-59.077S512 223.462 512 256.077s-26.462 59.077-59.077 59.077-59.077-26.462-59.077-59.077m-196.923 0C196.923 223.462 223.385 197 256 197s59.077 26.462 59.077 59.077-26.462 59.077-59.077 59.077-59.077-26.462-59.077-59.077m-78.77 0c0 32.615-26.448 59.077-59.076 59.077S0 288.692 0 256.077 26.45 197 59.077 197c32.628 0 59.077 26.462 59.077 59.077"
    }, null, -1)
  ])))
}
export default { render: render }