<script>
export default {
  name: "SidebarItem",
  props: {
    icon: {
      type: [String, Array],
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    to: {
      type: [String, Object],
      default: "",
    },
  },
};
</script>

<template>
  <router-link v-slot="{ isExactActive }" class="nav-link" :to="to">
    <div class="nav-item" :class="{ active: isExactActive }">
      <div class="icon">
        <FontAwesomeIcon :icon="icon" />
      </div>
      <div class="text">
        {{ text }}
      </div>
    </div>
  </router-link>
</template>

<style lang="scss" scoped>
.nav-link {
  text-decoration: none;
}
.nav-item {
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  padding-left: 20px;
  transition: ease background-color 100ms;
  user-select: none;

  .icon {
    width: 16px;
    display: inline-flex;
    justify-content: center;
    font-size: 13px;
    color: var(--text);
    margin-right: 10px;
  }
  .text {
    font-size: 14px;
    color: var(--text);
  }

  &:hover {
    background-color: var(--grey-1);
  }
  &:hover,
  &.active {
    border-left: 5px var(--text) solid;
    padding-left: 15px;
    background-color: var(--grey-1);
  }
  &.active {
    .text {
      font-weight: bold;
    }
  }
}
</style>
