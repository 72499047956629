import { useRoute } from "vue-router";
import { watch } from "vue";
import type { HydraItemContext, Task } from "@verbleif/lib";
import { api } from "@/core/api";

function useTaskInit(showEdit: (arg: HydraItemContext<Task>) => void) {
  const route = useRoute();

  watch(
    () => route.params,
    (params) => {
      console.log(route.name);
      const taskId = params?.taskId;
      if (!taskId) {
        return;
      }

      api.get<HydraItemContext<Task>>(`/api/v1/tasks/${taskId}`)
        .then(({ data }) => {
          showEdit(data);
        });
    },
    { immediate: true },
  );
}

export default useTaskInit;
