import { UserRole, useAuthStore, useConfigStore, useLocationStore } from "@verbleif/shared";
import type { HydraItem, Revoke, SkeletonItem, Task } from "@verbleif/lib";
import { useMercure } from "@verbleif/lib";
import type { Ref } from "vue";
import refreshToken from "@/core/refreshToken.ts";
import useRightsStore from "@/core/store/RightStore.ts";

export function useTaskMercure(items: Ref<Array<HydraItem<Task> | SkeletonItem>>, loading: Ref<boolean>) {
  const authStore = useAuthStore();
  const locationStore = useLocationStore();
  const { hasRole } = useRightsStore();
  const configStore = useConfigStore();

  let once = false;
  watchEffect(() => {
    if (once) {
      return;
    }
    if (!authStore.user.value) {
      return;
    }

    once = true;
    const encoded = encodeURIComponent("/api/v1/tasks/{id}");
    let topic = `/locations/${locationStore.getSelectedLocationId.value}/users/${authStore.user.value.id}/?topic=${encoded}`;
    if (hasRole(UserRole.ROLE_FRONT_DESK)) {
      topic = `/locations/${locationStore.getSelectedLocationId.value}/?topic=${encoded}`;
    }

    useMercure<Task>({
      hubUrlGetter: () => configStore.config.value?.mercureUrl || "",
      refreshToken,
      tokenGetter: () => authStore.token.value || "",
      topic,
      onAddOrUpdate: async (task) => {
        if (loading.value) {
          return;
        }

        const indexToUpdate = items.value.findIndex(v => !("skeleton" in v) && v["@id"] === task["@id"]);
        if (indexToUpdate === -1) {
          return;
        }
        items.value[indexToUpdate] = task;
      },
      onDelete: async (task) => {
        if (loading.value) {
          return;
        }

        const indexToUpdate = items.value.findIndex(v => !("skeleton" in v) && v["@id"] === task["@id"]);
        if (indexToUpdate === -1) {
          return;
        }
        items.value.splice(indexToUpdate, 1);
      },
    });

    const revokeSubtopic = encodeURIComponent("/api/v1/revokes/{id}");
    const revokeTopic = `/locations/${locationStore.getSelectedLocationId.value}/users/${authStore.user.value.id}/?topic=${revokeSubtopic}`;
    useMercure<Revoke>({
      hubUrlGetter: () => configStore.config.value?.mercureUrl || "",
      refreshToken,
      tokenGetter: () => authStore.token.value || "",
      topic: revokeTopic,
      onAddOrUpdate: async (revoke) => {
        if (loading.value) {
          return;
        }

        const indexToUpdate = items.value.findIndex(v => !("skeleton" in v) && v["@id"] === revoke.task);
        if (indexToUpdate === -1) {
          return;
        }
        items.value.splice(indexToUpdate, 1);
      },
    });
  });
}
