import moment from "moment";
import { useToast } from "vue-toastification";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { Feature } from "@verbleif/shared";
import type { AxiosRequestConfig, AxiosResponse } from "axios";
import type { HydraItem, Task } from "@verbleif/lib";
import { useRightsStore } from "@/core/store/RightStore";
import { useSystemStore } from "@/features/Store/useSystemStore";
import { useStatus } from "@/features/Status/useStatus";

interface TaskExport extends HydraItem<Task> {
  deadlineCurrentReservation: string | null
  deadlineCurrentReservationEndAt: string | null
  deadlineNextReservation: string | null
  deadlineNextReservationStartAt: string | null
  objectCurrentReservation: string | null
  objectNextReservation: string | null
  deadlineIsContinous: boolean
  todayIsContinuous: boolean
  comments: string[]
}

function mapToIri<T>(item: string | HydraItem<T>): string {
  return typeof item === "string" ? item : item["@id"];
}

function useExport() {
  const loading = ref(false);
  const { t } = useI18n();
  const TRANSLATION_KEY = "tasks.exports";
  const { hasFeature } = useRightsStore();
  const systemStore = useSystemStore();

  function convertToCSV(objArray: any) {
    const array
            = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str = "";

    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (const index in array[i]) {
        if (line !== "") {
          line += ",";
        }

        line += array[i][index];
      }

      str += `${line}\r\n`;
    }

    return str;
  }

  async function exportCSVFile(headers: any, items: any[], fileTitle: string) {
    if (headers) {
      items.unshift(headers);
    }

    // Convert Object to JSON
    const jsonObject = JSON.stringify(items);

    const csv = convertToCSV(jsonObject);

    const exportedFilenmae = `${fileTitle}.csv` || "export.csv";

    const blob = new Blob([`sep=,\n${csv}`], {
      type: "text/csv;charset=utf-8;",
    });
    if (((navigator as any) as {
      msSaveBlob: (blob: any, name: string) => void
    }).msSaveBlob) {
      // IE 10+
      ((navigator as any) as {
        msSaveBlob: (blob: any, name: string) => void
      }).msSaveBlob(blob, exportedFilenmae);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilenmae);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  async function exportTasks(
    getTasks: (options: AxiosRequestConfig<any>, isExport: boolean) => Promise<AxiosResponse<any, any>>,
    getParams: (params: URLSearchParams) => any,
    title = "",
  ) {
    const { statusToText } = useStatus();
    const { success, error } = useToast();
    loading.value = true;
    try {
      let headers: Record<string, string> = {
        id: "id",
        status: "Status",
        priority: "Prioriteit",
        inside: "Naar binnen",
        sort: "Soort",
        group: "Groep",
        object: "Object",
        description: "Omschrijving",
        quantity: "Aantal",
        departments: "Afdelingen",
        users: "Gebruikers",
        deadlineAt: "Deadline",
        deadlineNextReservationStartAt: "Volgende checkin",
        deadlineIsContinous: "Wissel?",
        comments: "Opmerkingen",
      };
      if (hasFeature(Feature.HAS_ADVANCED_TASKS_EXPORT)) {
        headers = {
          parentGroup: "Park / Adres",
          object: "Accommodatie",
          quantity: "Aantal",
          description: "Omschrijving",
          departments: "Takenlijst",
          users: "Naam",
          deadlineAt: "Deadline",
          nextReservationStartAt: "Volgende checkin",
          deadlineIsContinuous: "Wissel",
          sort: "VOLGORDE",
        };
      }

      const params = new URLSearchParams();
      params.append("export", "true");
      params.append("perPage", "100");

      getParams(params);
      let tasks: TaskExport[] = [];

      async function fetch(page = 1) {
        params.set("page", `${page}`);
        const { items, hasNext } = await getTasks({ params }, true).then(
          r => ({ items: r.data["hydra:member"], hasNext: !!r.data["hydra:view"]?.["hydra:next"] }),
        );
        tasks = [...tasks, ...items];
        if (hasNext) {
          await fetch(page + 1);
        }
      }
      await fetch();

      await exportCSVFile(
        headers,
        tasks.map((task: TaskExport) => {
          const id = task.id;
          const status = t(statusToText(task.status));
          const priority = task.priority ? t(`tasks.information.priority_option_${task.priority}`) : "-";
          const inside = task?.inside === 1 ? "Ja" : "Nee";

          const sortObject = systemStore.sorts.value.find(item => item["@id"] === mapToIri(task.sort));
          const sort = sortObject?.name || "-";

          let parentGroup = "-";
          let group = "-";
          let object = "-";
          if (task.object !== null && task.object !== undefined) {
            const objectIri = mapToIri(task.object);
            const objectObject = systemStore.objects.value.find(item => item["@id"] === objectIri);
            object = objectObject?.name || "-";

            if (objectObject?.group !== null && objectObject?.group !== undefined) {
              const groupIri = mapToIri(objectObject.group);
              const groupObject = systemStore.groups.value.find(item => item["@id"] === groupIri);
              group = groupObject?.name || "-";

              if (groupObject?.parent !== null && groupObject?.parent !== undefined) {
                const groupIri = mapToIri(groupObject.parent);
                const parentGroupObject = systemStore.groups.value.find(item => item["@id"] === groupIri);
                parentGroup = parentGroupObject?.name || "-";
              }
            }
          }

          const description = task.description ? `"${task.description}"` : "-";
          const quantity = task.quantity || "-";
          const deadlineIsContinuous = task.deadlineIsContinous ? "Ja" : "Nee";

          let departments = "-";
          if (task.departments !== undefined) {
            departments = task.departments
              .map((department) => {
                const departmentObject = systemStore.departments.value.find(
                  item => item["@id"] === mapToIri(department),
                );
                return departmentObject?.name || "-";
              })
              .join(" & ");
          }

          let users = "-";
          if (task.users !== undefined) {
            users = task.users
              .map((user) => {
                const userObject = systemStore.users.value.find(
                  item => item["@id"] === mapToIri(user),
                );
                return userObject?.fullName || "-";
              })
              .join(" & ");
          }

          const nextReservationStartAt = task.deadlineNextReservation
            ? moment(task.deadlineNextReservationStartAt).format("DD-MM-YYYY HH:mm")
            : "-";

          const deadlineAt = task.deadlineAt
            ? moment(task.deadlineAt).format(task.timeSensitive ? "DD-MM-YYYY HH:mm" : "DD-MM-YYYY")
            : "-";

          if (hasFeature(Feature.HAS_ADVANCED_TASKS_EXPORT)) {
            return {
              parentGroup,
              id,
              object,
              quantity,
              description,
              departments,
              users,
              deadlineAt,
              nextReservationStartAt,
              deadlineIsContinuous,
              sort: "",
            };
          }

          const csvObject: Record<keyof typeof headers, any> = {
            id,
            status,
            priority,
            inside,
            sort: `"${sort}"`,
            group: `"${group}"`,
            object: `"${object}"`,
            description,
            quantity,
            departments: `"${departments}"`,
            users: `"${users}"`,
            deadlineAt,
            nextReservationStartAt,
            deadlineIsContinuous,
            comments: `"${task.comments.join("\n")}"`,
          };

          return csvObject;
        }),
                `${title} ${moment().format("YYYY-MM-DD HH:mm:ss")}`,
      );
      success(t(`${TRANSLATION_KEY}.export_success`));
      loading.value = false;
    } catch (e) {
      console.log(e);
      error(t(`${TRANSLATION_KEY}.export_failed`));
      loading.value = false;
    }
  }

  return {
    exportTasks,
    loading,
  };
}

export default useExport;
