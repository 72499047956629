import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink",
  width: "32",
  height: "32",
  viewBox: "0 0 512 512"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<defs><path id=\"a\" d=\"M256.742 512c141.795 0 256.742-114.615 256.742-256S398.537 0 256.742 0 0 114.615 0 256s114.947 256 256.742 256\"></path><mask id=\"b\" width=\"513.484\" height=\"512\" x=\"0\" y=\"0\" fill=\"#fff\" maskContentUnits=\"userSpaceOnUse\" maskUnits=\"objectBoundingBox\"><use xlink:href=\"#a\"></use></mask></defs><g fill=\"none\" fill-rule=\"evenodd\"><use xlink:href=\"#a\" stroke=\"#CCC\" stroke-dasharray=\"67\" stroke-width=\"60\" mask=\"url(#b)\"></use><path fill=\"#CCC\" fill-rule=\"nonzero\" d=\"M285.787 286.47H224.73c-61.822 0-111.94 50.02-111.94 111.721 0 11.217 9.114 20.313 20.352 20.313h244.234c11.238 0 20.353-9.096 20.353-20.313 0-61.7-50.12-111.721-111.94-111.721m-141.84 101.565c5.025-40.055 39.313-71.096 80.782-71.096h61.058c41.444 0 75.75 31.073 80.775 71.096zM255.258 256c44.96 0 81.411-36.38 81.411-81.252 0-44.873-36.45-81.252-81.411-81.252s-81.411 36.379-81.411 81.252c0 44.879 36.444 81.252 81.411 81.252m0-132.035c28.055 0 50.882 22.783 50.882 50.783s-22.827 50.782-50.882 50.782-50.882-22.788-50.882-50.782c0-28 22.833-50.783 50.882-50.783\"></path><g transform=\"translate(287.907 286.423)\"><circle cx=\"112.788\" cy=\"112.788\" r=\"112.788\" fill=\"#4CAF50\"></circle><path fill=\"#FFF\" fill-rule=\"nonzero\" d=\"M174.007 113.039a9.4 9.4 0 0 1-9.399 9.402h-42.295v42.296c0 5.196-4.21 9.396-9.4 9.396s-9.398-4.2-9.398-9.396V122.44H61.219a9.4 9.4 0 0 1-9.399-9.402c0-5.196 4.209-9.396 9.399-9.396h42.296V61.347a9.4 9.4 0 0 1 9.399-9.402 9.4 9.4 0 0 1 9.399 9.402v42.296h42.295a9.385 9.385 0 0 1 9.4 9.396\"></path></g></g>", 2)
  ])))
}
export default { render: render }