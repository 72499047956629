<script setup lang="ts">
import { ref } from "vue";
import type { Activity, HydraItem, User } from "@verbleif/lib";
import { Form } from "vee-validate";
import moment from "moment";
import AvatarWithName from "../AvatarWithName/AvatarWithName.vue";
import Pencil from "./pencil.svg?component";
import Trash from "./trash.svg?component";
import api from "@/core/api";
import useSystemStore from "@/features/Store/useSystemStore.ts";
import VField from "@/features/FormField/VField.vue";
import Ellipsis from "@/assets/ellipsis.svg?component";

const props = defineProps<{
  activity: Activity
  queue: boolean
}>();

const emit = defineEmits<{
  (e: "commentCreated", activity: HydraItem<Activity>): void
  (e: "update:modelValue", activity: Activity): void
  (e: "remove"): void
}>();
const { t } = useI18n();
const systemStore = useSystemStore();
const error = ref(false);
const isEditing = ref(false);

if (!props.queue) {
  postComment(props.activity);
}

async function submit(values: any) {
  emit("update:modelValue", {
    ...props.activity,
    meta: {
      message: values.message,
    },
  });
  isEditing.value = false;
}

function startEdit(resetForm: any) {
  isEditing.value = true;
  resetForm();
}

function mapToIri(item: string | HydraItem<User>) {
  return typeof item === "string" ? item : item["@id"];
}

const initialValues = computed(() => {
  return {
    message: props.activity?.meta?.message,
  };
});

const createdBy = computed(() => {
  const createdBy = props.activity.createdBy;
  if (!createdBy) {
    return null;
  }
  const iri = mapToIri(createdBy);

  let user = systemStore.usersIndexedByIri.value?.[iri];
  if (!user) {
    user = systemStore.systemUsersIndexedByIri.value?.[iri];
  }

  if (!user) {
    return null;
  }

  return user;
});

function postComment(activity: Activity) {
  const clone: any = { ...activity };
  delete clone.id;
  error.value = false;
  api
    .post<HydraItem<Activity>>("/activities", clone)
    .then(r => emit("commentCreated", r.data))
    .catch(postFailed);
}

function postFailed() {
  error.value = true;
}

const time = moment(props.activity.createdAt)
  .format("HH:mm");

function redo() {
  postComment(props.activity);
}
</script>

<template>
  <div
    class="activity-comment-item"
    :class="{ 'is-placeholder': !error && !queue, 'has-error': error }"
  >
    <AvatarWithName
      size="is-medium"
      :name="createdBy ? createdBy.fullName : t('activities.unknown_user')"
      :avatar-iri="createdBy?.avatar"
      :active="true"
    />
    <Form v-slot="{ resetForm }" :initial-values="initialValues" class="content" :class="{ 'is-editing': isEditing }" @submit="submit">
      <template v-if="isEditing">
        <VField v-slot="{ field, errorMessage }" name="message">
          <VTextArea
            v-bind="field"
            :error-message="errorMessage"
            name="message"
            has-new-style
            variant="is-white"
            resize="is-vertical"
          />
        </VField>

        <div class="buttons">
          <VButton
            variant="is-light"
            size="is-small"
            @click="isEditing = false"
          >
            {{ t('base.cancel') }}
          </VButton>
          <VButton
            size="is-small"
            type="submit"
          >
            {{ t('base.save') }}
          </VButton>
        </div>
      </template>
      <template v-else>
        <div class="message">
          {{ activity.meta.message }}
        </div>
        <div class="right">
          <div class="actions">
            <div class="edit" @click="startEdit(resetForm)">
              <Pencil />
              <span class="is-primary-text">
                {{ t('base.table.edit') }}
              </span>
            </div>
            <VPopper
              class="right"
              root-class="is-default"
              trigger="clickToToggle"
              append-to-body
              transition="fade"
              stop-propagation
            >
              <template #reference>
                <Ellipsis class="ellipsis" />
              </template>
              <div class="remove">
                <VDropdownItem
                  :text="t('base.table.remove')"
                  close-on-click
                  @click="() => emit('remove')"
                >
                  <template #icon>
                    <Trash class="icon" />
                  </template>
                </VDropdownItem>
              </div>
            </VPopper>
          </div>
          <div class="when">
            <span>{{ time }}</span>
          </div>
        </div>
      </template>
      <div v-if="error" class="error-controls">
        <FontAwesomeIcon
          title="Retry"
          class="redo-icon"
          :icon="['fas', 'redo']"
          @click="redo"
        />
        <FontAwesomeIcon
          class="error-icon"
          :icon="['far', 'exclamation-circle']"
        />
      </div>
    </Form>
  </div>
</template>

<style scoped lang="scss">
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.activity-comment-item {
  &:hover {
    .content .right .actions {
      opacity: 1;
    }
  }

  .content {
    background-color: var(--background-modal);
    padding: 9px 12px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    margin-top: 5px;
    border-radius: 10px;

    &.is-editing {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr auto;
    }

    .buttons {
      display: flex;
      flex-direction: row;
      margin-left: auto;
      gap: 10px;
    }

    .message {
      color: var(--text);
      font-size: 14px;
      font-weight: 400;
    }

    .right {
      display: flex;
      flex-direction: column;
      gap: 15px;

      .actions {
        display: flex;
        flex-direction: row;
        align-self: flex-end;
        gap: 10px;
        opacity: 0;
        transition: opacity ease 150ms;
        color: var(--text);
        font-size: 12px;

        .edit {
          display: flex;
          align-items: center;
          gap: 7px;
          color: var(--primary);
          cursor: pointer;

          &:hover {
            color: var(--primary-dark);
          }
        }

        .ellipsis {
          cursor: pointer;
        }

        svg {
          width: 12px;
        }
      }

      .when {
        color: var(--text-2);
        font-size: 11px;
        font-weight: 400;
        align-self: flex-end;

        .edited {
          font-style: italic;
        }
      }
    }
  }

  .error-controls {
    display: flex;
    align-items: center;

    .error-icon {
      font-size: 14px;
      color: $danger;
    }

    .redo-icon {
      margin-right: 5px;
      font-size: 12px;
      cursor: pointer;
      color: var(--text);
    }
  }

  &.is-placeholder {
    opacity: 0.5;
    .when {
      position: relative;
      pointer-events: none;
      .error-controls {
        .error-icon,
        .redo-icon {
          color: transparent !important;
        }
      }

      &:after {
        animation: loader 0.5s infinite linear;
        border: 2px solid black;
        border-radius: 290486px;
        border-right-color: transparent;
        border-top-color: transparent;
        content: "";
        display: block;
        height: 1em;
        position: absolute;
        left: calc(50% - 0.5em);
        top: calc(50% - 0.5em);
        width: 1em;
      }
    }
  }
}
</style>
