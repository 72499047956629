<script setup lang="ts">
import { Field, Form as VForm } from "vee-validate";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import * as yup from "yup";
import type { Activity, HydraItem, Report, Task } from "@verbleif/lib";
import { ScrollDirection, providePaginate, useLazyLoadScroll } from "@verbleif/lib";
import ActivityCreatingCommentItem from "@/features/Activities/ActivityCreatingCommentItem.vue";
import ActivityDateItem from "@/features/Activities/ActivityDateItem.vue";
import ActivityItem from "@/features/Activities/ActivityItem.vue";
import type { ActivityEntityType } from "@/features/Activities/useActivities";
import { useActivities } from "@/features/Activities/useActivities";
import { useScrollToBottom } from "@/features/Activities/useScrollToBottom";

const props = defineProps<{
  item?: HydraItem<Task> | HydraItem<Report> | null
  type: ActivityEntityType
}>();

const emit = defineEmits(["queueChange"]);

const { t } = useI18n();
const container = ref<HTMLElement | null>(null);
useScrollToBottom(container);
const {
  optimisticUpdateActivity,
  grouped,
  getActivities,
  queuePostComment,
  setParams,
  queued,
  updateQueued,
  removeQueued,
  commentCreated,
  setItems,
} = useActivities({ type: props.type, item: props.item });

const schema = yup.object().shape({
  message: yup.string().required(t("validation.not_empty")),
});

const {
  resetAndLoad,
  load,
  loading,
  items,
  sortable,
  cursor: { currentPage, hasNextPage },
} = providePaginate<Activity>({
  cursorParams: { partial: true },
  tableFields: {},
  sortableFields: {},
  searchableFields: [],
  initialPerPage: 50,
  onLoad: (options: any) => getActivities(options),
  loadParams: (params: URLSearchParams) => setParams(params),
});
sortable.sort.value = "createdAt";

watchEffect(() => {
  if (loading.value) {
    return;
  }
  const newItems: HydraItem<Activity>[] = [];
  // Ik heb ff geen zin om dit goed te fixen dus dan maar ff zo
  for (const item of items.value) {
    if ("skeleton" in item) {
      continue;
    }
    newItems.push(item);
  }
  setItems(newItems);
});

useLazyLoadScroll(
  container,
  (options: any) => {
    currentPage.value++;
    load(options);
  },
  hasNextPage,
  loading,
  ScrollDirection.TOP,
);

if (props.item) {
  resetAndLoad();
}

watch(
  queued,
  () => {
    emit("queueChange", queued.value);
  },
  { deep: true },
);

function submit(values: any, actions: any) {
  queuePostComment(values.message);
  actions.resetForm();
}
</script>

<template>
  <div class="activities-container">
    <div ref="container" class="activities-wrapper">
      <div
        v-for="(activities, isoDate) in grouped"
        :key="isoDate"
        class="activities"
      >
        <ActivityDateItem :date="isoDate" />
        <ActivityItem
          v-for="activity in activities"
          :key="activity.id"
          :activity="activity"
          :type="type"
          @update:model-value="optimisticUpdateActivity"
        />
      </div>
      <div class="queue-activities">
        <ActivityCreatingCommentItem
          v-for="(activity, index) in queued"
          :key="activity.id"
          :activity="activity"
          :queue="!item"
          @comment-created="(a) => commentCreated({ index, activity: a })"
          @update:model-value="(value: Activity) => updateQueued(index, value)"
          @remove="() => removeQueued(index)"
        />
      </div>
    </div>
    <div class="message-form">
      <VForm
        v-slot="{ errors }"
        :validation-schema="schema"
        class="form"
        validate-on-mount
        @submit="submit"
      >
        <Field v-slot="{ field }" name="message">
          <VInput
            has-new-style
            variant="is-white"
            autocomplete="off"
            placeholder="Typ een bericht..."
            :model-value="field.value"
            text-align="is-text-align-left"
            @update:model-value="field.onChange"
          />
        </Field>
        <VButton
          :disabled="!!errors?.message"
          type="submit"
          class="comment-send-button"
        >
          <FontAwesomeIcon :icon="['fas', 'paper-plane']" />
        </VButton>
      </VForm>
    </div>
  </div>
</template>

<style scoped lang="scss">
.activities-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;

  .activities-wrapper {
    height: calc(100% - 36px);
    background-color: var(--background);
    overflow-y: auto;
    padding: 12px 23px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    .queue-activities {
      display: flex;
      flex-direction: column;
      margin-top: 15px;
      gap: 15px;
    }

    .activities {
      flex: 1;
      gap: 15px;
      display: flex;
      flex-direction: column;
      &:not(:first-child) {
        margin-top: 20px;
      }
    }
  }
  .message-form {
    width: 100%;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    height: calc(36px + 30px);
    display: flex;
    background: var(--background);
    padding: 15px;

    .form {
      width: 100%;
      display: flex;
      align-items: center;
    }

    .button-container {
      margin-left: 10px;
    }
  }
}
</style>
