import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16",
  viewBox: "0 0 512 512"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      fill: "none",
      "fill-rule": "evenodd"
    }, [
      _createElementVNode("path", { d: "M0 0h512v512H0z" }),
      _createElementVNode("path", {
        fill: "#408EC6",
        "fill-rule": "nonzero",
        d: "M344.5 160C330 70.702 295.8 8.002 256 8.002s-74 62.7-88.5 152zM160 256c0 22.2 1.2 43.5 3.3 64h185.299c2.1-20.5 3.3-41.8 3.3-64s-1.2-43.5-3.3-64h-185.3c-2.1 20.5-3.3 41.8-3.3 64m324.7-96c-28.601-67.9-86.5-120.4-158-141.599 24.399 33.8 41.2 84.7 50 141.6zM185.2 18.402c-71.4 21.2-129.4 73.7-157.9 141.6h108c8.7-56.9 25.5-107.8 49.9-141.6m310.2 173.6H380.7c2.099 21 3.3 42.5 3.3 64s-1.201 43-3.3 64h114.599c5.5-20.5 8.6-41.8 8.6-64s-3.1-43.5-8.5-64M128 256c0-21.5 1.2-43 3.3-64H16.6C11.2 212.5 8 233.8 8 256s3.2 43.5 8.6 64h114.6c-2-21-3.2-42.5-3.2-64m39.5 96C182 441.3 216.2 504 256 504s74-62.7 88.5-152zM326.799 493.6c71.4-21.2 129.4-73.7 158-141.6h-108c-8.8 56.9-25.6 107.8-50 141.6M27.299 352c28.601 67.9 86.5 120.4 158 141.6-24.399-33.8-41.2-84.7-50-141.6z"
      })
    ], -1)
  ])))
}
export default { render: render }