<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { computed } from "vue";
import { diff_match_patch as DiffMatchPatch } from "diff-match-patch";

const props = defineProps({
  propertyName: {
    type: String,
    required: true,
  },
  fromValue: {
    type: String,
    required: true,
  },
  toValue: {
    type: String,
    required: true,
  },
  createdBySelf: {
    type: Boolean,
    default: false,
  },
});

const dmp = new DiffMatchPatch();
const { t } = useI18n();
const textWithDiff = computed(() => {
  const diffs: Array<any> = dmp.diff_main(props.fromValue, props.toValue);
  dmp.diff_cleanupSemantic(diffs);
  return diffs.map(([type, segment]) => {
    switch (type) {
      case -1: return `<span class="text-rm">${segment.replace(" ", "&nbsp;")}</span>`;
      case 1: return `<span class="text-add">${segment.replace(" ", "&nbsp;")}</span>`;
      default: return `<span>${segment.replace(" ", "&nbsp;")}</span>`;
    }
  }).join("");
});
</script>

<template>
  <span class="other-text">
    {{ createdBySelf ? t('activities.self_description_changed') : t('activities.others_description_changed') }}
  </span>
  <span class="item-with-text" v-html="textWithDiff" />
</template>

<style scoped lang="scss">
span {
  display: flex;
  align-items: center;
}
.item-with-text {
}
.other-text {
  color: var(--text-2);
}
</style>

<style lang="scss">
.text-rm {
  color: var(--text-2);
  text-decoration: line-through;
}
.text-add {
  font-weight: bold;
}
</style>
