<script>
import { ref } from "vue";
import { useFileItem } from "@/features/FileUpload/useFileItem";

export default {
  name: "FileItem",
  props: {
    mediaObject: {
      type: Object,
      default: null,
    },
  },
  emits: ["delete", "upload", "showImage"],
  setup(props) {
    const imagePreviewError = ref(false);
    const fileItem = useFileItem({ initialMediaObject: props.mediaObject });

    function setPreviewErr(bool) {
      imagePreviewError.value = bool;
    }

    return {
      ...fileItem,
      imagePreviewError,
      setPreviewErr,
    };
  },
};
</script>

<template>
  <li class="file-list-item">
    <div class="left">
      <div
        :class="[extension.class, { 'has-error': imagePreviewError }]"
        class="icon-wrapper"
        @click="mediaObject.mimeType.includes('image') && $emit('showImage')"
      >
        <img
          v-if="extension.ext === 'image' && !imagePreviewError"
          class="preview-image"
          alt="preview-of-uploaded-image"
          :src="mediaObject.url"
          :srcset="mediaObject.url"
          @error="() => setPreviewErr(true)"
        >
        <FontAwesomeIcon v-else :icon="extension.icon" />
      </div>
      <div class="file-info">
        <div class="name" :title="mediaObject.originalName">
          {{ mediaObject.originalName }}
        </div>
        <div class="details">
          <div class="size">
            {{ size }}
          </div>
          <div class="date">
            {{ date }}
          </div>
        </div>
      </div>
    </div>
    <div class="actions">
      <FontAwesomeIcon
        v-if="mediaObject.isUploaded"
        :icon="['fas', 'check']"
        class="check-icon"
      />
      <FontAwesomeIcon
        v-else
        :icon="['fas', 'download']"
        class="download-icon"
        @click="download"
      />
      <FontAwesomeIcon
        :icon="['far', 'trash']"
        class="delete-icon"
        @click="$emit('delete')"
      />
    </div>
  </li>
</template>

<style lang="scss" scoped>
.file-list-item {
  display: flex;
  gap: 15px;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  .left {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    min-width: 0;

    .icon-wrapper {
      width: 46px;
      height: 46px;
      min-width: 46px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 26px;
      flex: 1;

      &.icon-image.has-error {
        color: RGB(213, 37, 134);
        background-color: RGBA(213, 37, 134, 0.35);
      }

      &.icon-pdf {
        color: RGB(255, 0, 60);
        background-color: RGBA(255, 0, 60, 0.35);
      }

      &.icon-word {
        color: RGB(0, 168, 198);
        background-color: RGBA(0, 168, 198, 0.35);
      }

      &.icon-excel {
        color: RGB(0, 189, 137);
        background-color: RGBA(0, 189, 137, 0.35);
      }

      &.icon-video {
        color: RGB(213, 37, 134);
        background-color: RGBA(213, 37, 134, 0.35);
      }

      &.icon-archive {
        color: RGB(255, 138, 0);
        background-color: RGBA(255, 138, 0, 0.35);
      }

      &.icon-file {
        color: RGB(79, 79, 79);
        background-color: RGBA(79, 79, 79, 0.35);
      }

      &.icon-image {
        border: 1px solid var(--grey-1);

        .preview-image {
          pointer-events: none;
          width: 100%;
          height: 100%;
        }
      }
    }

    .file-info {
      display: flex;
      flex-direction: column;
      gap: 3px;
      width: calc(100% - 56px);

      .name {
        color: var(--text);
        min-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .details {
        display: flex;
        gap: 10px;

        .size {
          font-size: 14px;
          color: var(--text-2);
        }

        .date {
          font-size: 14px;
          color: var(--text-2);
        }
      }
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;

    > svg {
      cursor: pointer;
      color: var(--text);

      &:hover {
        color: var(--primary);
      }

      &.error-icon {
        color: $danger;
        pointer-events: none;
      }

      &.check-icon {
        color: $success;
        pointer-events: none;
      }
    }
  }
}
</style>
