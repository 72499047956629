import type { NetworkError, ServerError } from "@verbleif/lib";
import { ClientError, HttpStatusCode, RefreshTokenResponseInterceptor } from "@verbleif/lib";
import { AuthPath, createAuthenticatePlugin, useAuthStore } from "@verbleif/shared";
import api, { installInterceptor } from "./api";
import { onAuthenticateSuccess } from "@/core/onAuthenticateSuccess";
import { useLogout } from "@/features/Auth/Logout/useLogout.js";

const authStore = useAuthStore();
const { logout } = useLogout();

const { authenticate } = createAuthenticatePlugin({
  axios: api,
  path: AuthPath.JSON_REFRESH_V1,
  onAuthenticateSuccess,
  onAuthenticateFailed,
});

async function onAuthenticateFailed(e: ClientError | ServerError | NetworkError) {
  if (e instanceof ClientError && e.status === HttpStatusCode.HTTP_UNAUTHORIZED) {
    return logout();
  }

  throw e;
}

export async function refreshToken() {
  if (!authStore.getRefreshToken.value) {
    throw new Error("No refresh token set.");
  }

  await authenticate({
    refresh_token: authStore.getRefreshToken.value,
  });
}

const refreshTokenInterceptorPlugin = RefreshTokenResponseInterceptor(
  api,
  refreshToken,
  ["/api/v1/token/login", "/api/v1/token/refresh"],
);
installInterceptor(refreshTokenInterceptorPlugin);

export default refreshToken;
