import { computed } from "vue";

export function useObject(objectField) {
  const hasObject = computed(() => {
    return !!objectField.value.value;
  });

  return {
    hasObject,
  };
}
