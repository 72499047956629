<script setup>
import { computed, getCurrentInstance, nextTick, onMounted, ref, toRefs } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import CaretRight from "./caret-right.svg?component";

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: null,
    required: true,
  },
  iconActive: {
    type: null,
    default: false,
  },
  text: {
    type: String,
    default: "",
  },
  to: {
    type: [String, Object],
    default: "",
  },
  childrenPerListItem: {
    type: Object,
    default: () => {
      return {};
    },
  },
  listsKeyedByRouteId: {
    type: Object,
    default: () => {
      return {};
    },
  },
  children: {
    type: Array,
    default: () => [],
  },
  depth: {
    type: Number,
    default: 0,
  },
  isPlaceholder: {
    type: Boolean,
    default: false,
  },
  editMode: {
    type: Boolean,
    default: false,
  },
  editable: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:text", "update:modelValue", "toggleTree"]);
const { editMode, text } = toRefs(props);
const { t } = useI18n();
const instance = getCurrentInstance();
const editInput = ref(null);
const toggle = ref(false);
const isEditing = ref(editMode.value);
const editedText = ref(text.value);
const route = useRoute();
const router = useRouter();

const resolvedRoute = computed(() => {
  return router.resolve(props.to);
});

const isExactActive = computed(() => {
  return route.fullPath === resolvedRoute.value.fullPath;
});

onMounted(() => {
  if (isExactActive.value) {
    emit("toggleTree", true);
  }
});

function enableEditMode() {
  isEditing.value = true;
  nextTick(() => {
    editInput.value.focus();
  });
}

function disableEditMode() {
  isEditing.value = false;
  emit("update:text", editedText.value);
}

function toggleTree(forcedValue) {
  emit("toggleTree", forcedValue);
  toggleDropdown(forcedValue);
}

function toggleDropdown(forcedValue = null) {
  const finalValue = forcedValue !== null ? forcedValue : !props.modelValue;
  if (props.modelValue === finalValue) {
    return;
  }

  toggle.value = !toggle.value;
  emit("update:modelValue", finalValue);
}

const parentCount = computed(() => {
  let parentInstance = instance;
  let count = 0;

  while (
    parentInstance
    && parentInstance.parent
    && parentInstance.parent.type?.__name === "VSidebarItem"
  ) {
    parentInstance = parentInstance.parent;
    count++;
  }

  return count ? `${count * 10 + 10}px` : `${10}px`;
});
</script>

<template>
  <router-link
    v-slot="{ isActive }"
    class="nav-link"
    :class="{ 'is-placeholder': isPlaceholder }"
    :to="to"
    :title="editable ? t('tasks.click_to_edit') : $attrs.title"
    @dblclick.prevent.stop="
      editable ? enableEditMode() : children.length ? toggleDropdown() : null
    "
  >
    <div
      :style="{ paddingLeft: parentCount }"
      class="nav-item"
      :class="{ 'is-active': isActive, 'is-collapsable': children.length }"
    >
      <div v-if="children.length" class="collapse-controls">
        <div
          class="toggle"
          :class="{ 'is-collapsed': toggle }"
          @click.prevent="() => toggleDropdown()"
        >
          <CaretRight />
        </div>
      </div>
      <div class="nav-content">
        <div class="icon">
          <slot name="icon">
            <component
              :is="icon"
              v-if="!isActive || iconActive === false"
              :is-active="isActive"
            />
            <component :is="iconActive" v-else :is-active="isActive" />
          </slot>
        </div>
        <div v-if="!isEditing" class="text">
          {{ text }}
        </div>
        <div v-else class="text">
          <input
            ref="editInput"
            v-model="editedText"
            @focusout="disableEditMode"
            @keydown.enter="disableEditMode"
          >
        </div>
      </div>
      <div class="actions">
        <slot name="actions" />
      </div>
    </div>
  </router-link>
  <div v-show="toggle" class="children">
    <VSidebarItem
      v-for="routeId in children"
      v-bind="listsKeyedByRouteId[routeId]"
      :key="routeId"
      :children="childrenPerListItem[routeId]"
      :children-per-list-item="childrenPerListItem"
      :lists-keyed-by-route-id="listsKeyedByRouteId"
      @toggle-tree="toggleTree"
    />
  </div>
</template>

<style lang="scss" scoped>
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.nav-link.is-placeholder {
  display: flex;
  align-items: center;
  opacity: 0.5;
  pointer-events: none;
  position: relative;

  &:after {
    animation: loader 0.5s infinite linear;
    border: 2px solid var(--text);
    border-radius: 290486px;
    border-right-color: transparent;
    border-top-color: transparent;
    content: "";
    display: block;
    height: 1em;
    position: absolute;
    top: calc(50% - 0.5em);
    right: 8px;
    width: 1em;
  }
}

.nav-link {
  display: flex;
  width: 100%;
  height: 100%;
  text-decoration: none;
}

.children {
  overflow: hidden;
  transition: all 0.5s;
}

.nav-item {
  width: 100%;
  height: 35px;
  max-height: 35px;
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  box-sizing: border-box;
  transition: ease background-color 100ms;
  user-select: none;

  &:not(.is-collapsable) {
    .nav-content {
      padding-left: 16px;
    }
  }

  .nav-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
    min-width: 0;
    gap: 5px;

    .icon {
      position: relative;
      width: 16px;
      display: inline-flex;
      justify-content: center;
      font-size: 13px;
      color: var(--text);
    }

    .text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 14px;
      line-height: 16px;
      height: 16px;
      color: var(--text);
    }
  }

  &:hover {
    &::v-deep(.actions) {
      .menu {
        visibility: visible;
      }
    }
  }

  &::v-deep(.actions) {
    .menu {
      visibility: hidden;
    }
  }

  .collapse-controls {
    width: 10px;
    margin-right: 5px;

    .toggle {
      color: var(--text);
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: ease 100ms;

      &.is-collapsed {
        transform: rotate(90deg);
      }
    }
  }

  &:hover {
    background-color: var(--grey-1);
  }

  &.is-active {
    .text,
    .icon {
      font-weight: bold;
    }
  }
}
</style>
