<script setup lang="ts">
import { computed } from "vue";
import { Feature } from "@verbleif/shared";
import type { HydraItem, Report, SingleThirdPartyAwareInterface, SkeletonItem } from "@verbleif/lib";
import Actions from "./Actions.vue";
import { useRightsStore } from "@/core/store/RightStore";
import InsideItem from "@/features/Inside/InsideItem.vue";
import Priority from "@/features/Priority/PriorityItem.vue";
import ConnectIcon from "@/features/Auth/OAuth/Thirdparties/ConnectIcon.vue";

const props = defineProps<{
  report: HydraItem<Report & SingleThirdPartyAwareInterface> | SkeletonItem
  index: number
  translations: Record<string, string>
}>();

defineEmits(["update", "onRowClick", "toggleSelected"]);

const { hasFeature } = useRightsStore();
const systemStore = useSystemStore();

function mapToIri(item: string | HydraItem<any>) {
  return typeof item === "string" ? item : item["@id"];
}

const objectName = computed(() => {
  if ("skeleton" in props.report) {
    return null;
  }

  const object = props.report.object;
  if (!object) {
    return null;
  }

  const objectIri = mapToIri(object);
  const objectData = systemStore.objectsIndexedByIri.value?.[objectIri];
  if (!objectData) {
    return null;
  }

  return objectData.name;
});

const createdAt = computed(() => {
  if ("skeleton" in props.report) {
    return null;
  }

  return new Date(props.report.createdAt).toLocaleString("nl", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
});
</script>

<template>
  <div
    class="report-item-container"
    :class="{
      'is-clickable': !('skeleton' in report),
      'has-connect-icon': !('skeleton' in report) && !!report?.thirdPartyName,
    }"
    @click="!('skeleton' in report) && $emit('onRowClick')"
  >
    <Priority
      v-if="!hasFeature(Feature.HIDE_PRIORITY)"
      :loading="'skeleton' in report"
      :model-value="'skeleton' in report ? null : report.priority"
      is-clearable
      singleton-group="report-item"
      @update:model-value="(d) => $emit('update', { priority: d })"
    />
    <InsideItem
      v-if="!hasFeature(Feature.HIDE_INSIDE)"
      :loading="'skeleton' in report"
      :model-value="'skeleton' in report ? null : report.inside"
      is-clearable
      singleton-group="report-item"
      @update:model-value="(d) => $emit('update', { inside: d })"
    />
    <div class="text">
      <div class="sub-text">
        <VSkeleton v-if="'skeleton' in report" :width="25" :height="12" />
        <template v-else>
          Gemaakt op: {{ createdAt }}
        </template>
      </div>
      <div class="main-text">
        <VSkeleton v-if="'skeleton' in report" :width="75" :height="14" />
        <template v-else>
          <template v-if="objectName">
            {{ objectName }}
            -
          </template>
          {{ report.description }}
        </template>
      </div>
    </div>
    <div class="right">
      <VSkeleton
        v-if="'skeleton' in report"
        :dynamic-width="false"
        :width="16"
        width-unit="px"
        :height="10"
      />
      <template v-else>
        <slot name="actions" />

        <Actions class="right-item">
          <slot name="dropdown-actions" />
        </Actions>
      </template>
    </div>
    <ConnectIcon
      v-if="!('skeleton' in report) && report.thirdPartyName"
      :third-party-name="report.thirdPartyName"
    />
  </div>
</template>

<style lang="scss" scoped>
.report-item-container {
  position: relative;
  border: 1px solid var(--grey-1);
  background-color: var(--input-background);
  border-radius: 0 4px 4px 4px;
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 15px;
  user-select: none;
  transition: none;
  gap: 10px;

  &.is-clickable {
    cursor: pointer;

    &:hover {
      background-color: var(--input-background-hover);
      .text {
        color: var(--primary);
      }
    }
  }

  &.has-connect-icon {
    padding-right: 25px;
  }

  .right {
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: 10px;
    color: var(--text);
  }

  .right-item:not(:last-child) {
    margin-right: 20px;
  }

  .text {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;

    .sub-text {
      color: var(--text-2);
      font-size: 12px;
    }

    .main-text {
      width: 100%;
      font-size: 14px;
      color: var(--text);
      display: flex;
      flex: 1 1;
      letter-spacing: 0.5px;
      word-break: break-word;
    }
  }
}
</style>
