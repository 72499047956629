<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useLocationStore } from "@verbleif/shared";
import type { SingleThirdPartyAwareInterface } from "@verbleif/lib";
import ConnectIcon from "@/features/Auth/OAuth/Thirdparties/ConnectIcon.vue";

const props = defineProps<{
  item: SingleThirdPartyAwareInterface
}>();
const { t } = useI18n();
const locationStore = useLocationStore();
const { item } = toRefs(props);

function openInThirdParty() {
  if (!locationStore.selectedLocationObject.value?.thirdPartyReference) {
    throw new Error("No thirdPartyReference exists on the selectedLocation.");
  }

  const locationThirdPartyReference = locationStore.selectedLocationObject.value?.thirdPartyReference;
  if (!locationThirdPartyReference) {
    console.error("selectedLocationObject has no thirdPartyReference.");
    return;
  }
  const itemThirdPartyReference = item.value?.thirdPartyReference;
  if (!itemThirdPartyReference) {
    console.error("props.item has no thirdPartyReference.");
    return;
  }

  const url = `https://app.bookingexperts.nl/parks/${locationThirdPartyReference}/todos/${itemThirdPartyReference}`;
  (window as any).open(url).focus();
}
</script>

<template>
  <VTooltip
    v-if="item.thirdPartyName"
    class="popper-container"
    :content="t(`thirdParty.${item.thirdPartyName}_open`)"
    hover
    :arrow="true"
    placement="auto-end"
    :disabled="!item.thirdPartyReference"
  >
    <template #reference>
      <ConnectIcon
        has-border
        non-absolute
        no-background
        :third-party-name="item.thirdPartyName"
        size="is-medium"
        @click="openInThirdParty"
      />
    </template>
  </VTooltip>
</template>

<style lang="scss" scoped>
.popper-container {
  &::v-deep(.popper) {
    font-size: 11px;
    --popper-theme-background-color: #333333;
    --popper-theme-background-color-hover: #333333;
    --popper-theme-text-color: #fff;
    --popper-theme-border-width: 0px;
    --popper-theme-border-style: solid;
    --popper-theme-border-radius: 6px;
    --popper-theme-padding: 5px 10px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
  }
}
</style>
