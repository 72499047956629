<script>
import { useI18n } from "vue-i18n";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useUploadingFileItem } from "@/features/FileUpload/useUploadingFileItem";

export default {
  name: "UploadingFileItem",
  components: { FontAwesomeIcon },
  props: {
    file: {
      type: Object,
      default: null,
      required: true,
    },
  },
  emits: ["delete", "upload"],
  setup(props, ctx) {
    const { t } = useI18n();
    return {
      t,
      ...useUploadingFileItem(props, ctx),
    };
  },
};
</script>

<template>
  <li class="file-list-item">
    <div class="left">
      <div
        :class="[extension.class, { 'has-error': status === 2 }]"
        class="icon-wrapper"
      >
        <img
          v-if="extension.ext === 'image'"
          :src="previewUrl"
          class="preview-image"
          alt="#"
        >
        <template v-else>
          <FontAwesomeIcon
            v-if="status === 1"
            :icon="['fal', 'spinner']"
            class="loading-icon"
            spin
          />
          <FontAwesomeIcon
            v-else-if="status === 2"
            :icon="['fal', 'exclamation-triangle']"
          />
          <FontAwesomeIcon v-else :icon="extension.icon" />
        </template>
      </div>
      <div class="file-info">
        <div class="name" :title="file.name">
          {{ file.name }}
        </div>
        <div class="details">
          <div class="size">
            {{ size }}
          </div>
          <div class="date">
            {{ t(statusText) }}
          </div>
        </div>
      </div>
    </div>
    <div class="actions">
      <FontAwesomeIcon
        v-if="status !== 1"
        :icon="['far', 'trash']"
        class="delete-icon"
        @click="$emit('delete')"
      />
      <FontAwesomeIcon
        v-if="status === 1"
        :icon="['fad', 'spinner']"
        class="loading-icon"
        spin
      />
      <FontAwesomeIcon
        v-if="status === 2"
        :icon="['fas', 'redo']"
        class="redo-icon"
        @click="retry"
      />
      <FontAwesomeIcon
        v-if="status === 3"
        :icon="['fas', 'check']"
        class="check-icon"
      />
      <FontAwesomeIcon
        v-if="status === 2"
        :icon="['far', 'exclamation-triangle']"
        class="error-icon"
      />
    </div>
  </li>
</template>

<style lang="scss" scoped>
.file-list-item {
  display: flex;
  gap: 15px;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  .left {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    min-width: 0;

    .icon-wrapper {
      width: 46px;
      height: 46px;
      min-width: 46px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 26px;
      flex: 1;

      &.has-error {
        color: $danger !important;
        background-color: lighten($danger, 40%) !important;
      }

      &.icon-image.has-error {
        color: #d52586ff;
        background-color: #d52586b3;
      }

      &.icon-pdf {
        color: #ff003cff;
        background-color: #ff003c59;
      }

      &.icon-word {
        color: #00a8c6ff;
        background-color: #00a8c669;
      }

      &.icon-excel {
        color: #00bd89ff;
        background-color: #00bd8959;
      }

      &.icon-video {
        color: #d52586ff;
        background-color: #d5258659;
      }

      &.icon-archive {
        color: #ff8a00ff;
        background-color: #ff8a0059;
      }

      &.icon-file {
        color: #4f4f4fff;
        background-color: #4f4f4f59;
      }

      &.icon-image {
        border: 1px solid var(--grey-1);

        .preview-image {
          pointer-events: none;
          width: 100%;
          height: 100%;
        }
      }
    }

    .file-info {
      display: flex;
      flex-direction: column;
      gap: 3px;
      width: calc(100% - 56px);

      .name {
        min-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .details {
        display: flex;
        gap: 10px;

        .size {
          font-size: 14px;
          color: var(--text-2);
        }

        .date {
          font-size: 14px;
          color: var(--text-2);
        }
      }
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;

    > svg {
      cursor: pointer;
      color: var(--text);

      &.error-icon {
        color: #ff003c;
        pointer-events: none;
      }

      &.check-icon {
        color: lightgreen;
        pointer-events: none;
      }
    }
  }
}
</style>
