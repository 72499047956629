<script>
import SidebarHeader from "./VSidebarHeader.vue";
import { provideSidebar } from "@/features/Tasks/Sidebar/useSidebar";

export default {
  name: "VSidebar",
  components: { SidebarHeader },
  props: {
    title: {
      type: String,
      default: "Title",
    },
  },
  setup() {
    const { sidebarOpacity, sidebarMinWidth } = provideSidebar();

    return {
      sidebarOpacity,
      sidebarMinWidth,
    };
  },
};
</script>

<template>
  <div class="sidebar" :style="sidebarMinWidth">
    <SidebarHeader :title="title" />
    <div class="sections" :style="sidebarOpacity">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.sections {
  transition: all 0.5s;
  overflow-y: auto;
  height: 100%;
  padding-bottom: 150px;
  margin-top: 25px;
}

.sidebar {
  width: 50px;
  white-space: nowrap;
  overflow: hidden;
  transition: all 0.5s;
  z-index: 3;
  height: 100%;
  background-color: var(--background-dark);
  padding-top: 18px;
}
</style>
