// The "pseudocode" for the built-in Error class defined by JavaScript itself
export default class UploadFailedError extends Error {
  constructor() {
    const message = "request.upload_failed_error";
    super(message);
    this.message = message;
    this.name = "UploadFailedError"; // (different names for different built-in error classes)
    this.stack = Error.captureStackTrace(this, UploadFailedError); // non-standard, but most environments support it
  }
}
