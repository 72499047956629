import {RouteRecordRaw} from "vue-router";

export const routes = <RouteRecordRaw[]>[
  {
    path: "/p",
    name: "public",
    component: () => import("@/features/Public/PublicContainer.vue"),
    redirect: () => {
      return {
        name: "login",
      };
    },
    children: [
      {
        path: "report/:locationId",
        name: "public-report-overview",
        component: () => import("@/features/Public/Report/PublicReportOverview.vue"),
        meta: {
          skipAuthChecks: true,
          public: true,
          hideNavbar: true,
        },
      },
      {
        path: "report/:locationId/create",
        name: "public-report-create",
        component: () => import("@/features/Public/Report/PublicReportCreate.vue"),
        meta: {
          skipAuthChecks: true,
          public: true,
          hideNavbar: true,
        },
      },
      {
        path: "report/:locationId/view/:reportId",
        name: "public-report-view",
        component: () => import("@/features/Public/Report/PublicReportView.vue"),
        meta: {
          skipAuthChecks: true,
          public: true,
          hideNavbar: true,
        },
      },
      {
        path: "report/:locationId/sent",
        name: "public-report-sent",
        component: () => import("@/features/Public/Report/PublicReportSucces.vue"),
        meta: {
          skipAuthChecks: true,
          public: true,
          hideNavbar: true,
        },
      },
    ],
  },
];

export default routes;
