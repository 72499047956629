import localforage from "localforage";
import { Feature, UserRole, useAuthStore } from "@verbleif/shared";
import { computed, reactive, ref } from "vue";
import type { Department, Group, HydraItem, MediaObject, Objects, Sort, User } from "@verbleif/lib";
import { useRightsStore } from "@/core/store/RightStore";

const systemStoreIndexedDb: LocalForage = localforage.createInstance({
  driver: localforage.INDEXEDDB, // Force WebSQL; same as using setDriver()
  name: "verbleif_system_store",
  version: 1.0,
  storeName: "verbleif_system_store", // Should be alphanumeric, with underscores.
  description: "verbleif storage",
});

function mapToIri(item: string | HydraItem<any>) {
  return typeof item === "string" ? item : item["@id"];
}

function createSystemStore() {
  const users = ref<HydraItem<User>[]>([]);
  const systemUsers = ref<HydraItem<User>[]>([]);
  const departments = ref<HydraItem<Department>[]>([]);
  const sorts = ref<HydraItem<Sort>[]>([]);
  const groups = ref<HydraItem<Group>[]>([]);
  const objects = ref<HydraItem<Objects>[]>([]);
  const avatars = ref<HydraItem<MediaObject>[]>([]);
  const searchValue = ref<string>("");
  const searchTypes = reactive<Record<"Department" | "User", boolean>>({ Department: true, User: true });
  const debugMode = ref<boolean>(false);
  const taskSidebar = reactive<Record<string, any>>({});
  const reportSidebar = reactive<Record<string, any>>({});
  const { hasRole, hasRoleStrict, hasFeature } = useRightsStore();

  // Hydrate from external store
  watch(debugMode, () => systemStoreIndexedDb.setItem("debugMode", debugMode.value));
  systemStoreIndexedDb.getItem("debugMode", (err, value: any) => {
    if (err) {
      debugMode.value = false;
      return;
    }
    debugMode.value = !!value;
  });
  watch(taskSidebar, () => systemStoreIndexedDb.setItem("taskSidebar", JSON.stringify(taskSidebar)));
  systemStoreIndexedDb.getItem("taskSidebar", (err, value: any) => {
    if (err) {
      return;
    }
    if (typeof value !== "string") {
      value = "{}";
    }
    Object.assign(taskSidebar, JSON.parse(value));
  });
  watch(reportSidebar, () => systemStoreIndexedDb.setItem("reportSidebar", JSON.stringify(reportSidebar)));
  systemStoreIndexedDb.getItem("reportSidebar", (err, value: any) => {
    if (err) {
      return;
    }
    if (typeof value !== "string") {
      value = "{}";
    }
    Object.assign(reportSidebar, JSON.parse(value));
  });
  watch(searchTypes, () => systemStoreIndexedDb.setItem("searchTypes_1", JSON.stringify(searchTypes)));
  systemStoreIndexedDb.getItem("searchTypes_1", (err, value: any) => {
    if (err) {
      return;
    }
    if (typeof value !== "string") {
      value = "{}";
    }
    Object.assign(searchTypes, JSON.parse(value));
  });

  // Computed Properties
  const avatarsUrlIndexedByIri = computed<Record<string, string>>(() => {
    return avatars.value.reduce((acc, obj) => {
      const { "@id": key, url } = obj;
      acc[key] = url;
      return acc;
    }, {} as Record<string, string>);
  });
  const avatarsMediaObjectIndexedByIri = computed<Record<string, HydraItem<MediaObject>>>(() => {
    return avatars.value.reduce((acc, obj) => {
      const { "@id": key } = obj;
      acc[key] = obj;
      return acc;
    }, {} as Record<string, HydraItem<MediaObject>>);
  });
  const usersIndexedByIri = computed<Record<string, HydraItem<User>>>(() => {
    return users.value.reduce((acc, obj) => {
      const { "@id": key } = obj;
      acc[key] = obj;
      return acc;
    }, {} as Record<string, HydraItem<User>>);
  });
  const systemUsersIndexedByIri = computed<Record<string, HydraItem<User>>>(() => {
    return systemUsers.value.reduce((acc, obj) => {
      const { "@id": key } = obj;
      acc[key] = obj;
      return acc;
    }, {} as Record<string, HydraItem<User>>);
  });
  const departmentsIndexedByIri = computed<Record<string, HydraItem<Department>>>(() => {
    return departments.value.reduce((acc, obj) => {
      const { "@id": key } = obj;
      acc[key] = obj;
      return acc;
    }, {} as Record<string, HydraItem<Department>>);
  });

  const departmentIris = computed<string[]>(() => {
    return departments.value ? departments.value.map(department => department["@id"]) : [];
  });
  const sortsIndexedByIri = computed<Record<string, HydraItem<Sort>>>(() => {
    return sorts.value.reduce((acc, obj) => {
      const { "@id": key } = obj;
      acc[key] = obj;
      return acc;
    }, {} as Record<string, HydraItem<Sort>>);
  });
  const groupsIndexedByIri = computed<Record<string, HydraItem<Group>>>(() => {
    return groups.value.reduce((acc, obj) => {
      const { "@id": key } = obj;
      acc[key] = obj;
      return acc;
    }, {} as Record<string, HydraItem<Group>>);
  });
  const objectsIndexedByIri = computed<Record<string, HydraItem<Objects>>>(() => {
    return objects.value.reduce((acc, obj) => {
      const { "@id": key } = obj;
      acc[key] = obj;
      return acc;
    }, {} as Record<string, HydraItem<Objects>>);
  });

  const frontdeskUsers = computed(() => {
    return users.value.filter((user) => {
      return hasRole(UserRole.ROLE_FRONT_DESK, user);
    });
  });

  const usersBasedOnRole = computed(() => {
    const authStore = useAuthStore();

    if (!authStore.user.value) {
      return [];
    }

    const currentUser = authStore.user.value;

    if (
      hasRole(UserRole.ROLE_PARK_MANAGER)
        || hasRoleStrict(UserRole.ROLE_FRONT_DESK)
        || hasRoleStrict(UserRole.ROLE_DEPARTMENT_MANAGER)
    ) {
      return users.value;
    }

    const requiredDepartments: string[] = [];
    for (const userGroup of currentUser.departments) {
      const iri: string = typeof userGroup !== "string" ? userGroup["@id"] : userGroup;
      requiredDepartments.push(iri);
    }
    if (hasRoleStrict(UserRole.ROLE_DEPARTMENT_MANAGER)) {
      for (const userGroup of currentUser.managerDepartments) {
        const iri: string = typeof userGroup !== "string" ? userGroup["@id"] : userGroup;
        requiredDepartments.push(iri);
      }
    }

    return users.value.filter((user) => {
      if (hasRole(UserRole.ROLE_PARK_MANAGER, user)) {
        return true;
      }

      let inDepartment = false;
      for (const userGroup of user.departments) {
        const iri: string = mapToIri(userGroup);
        if (requiredDepartments.includes(iri)) {
          inDepartment = true;
          break;
        }
      }

      let inManagerDepartment = false;
      for (const userGroup of user.managerDepartments) {
        const iri: string = mapToIri(userGroup);
        if (requiredDepartments.includes(iri)) {
          inManagerDepartment = true;
          break;
        }
      }

      const isCurrentUser = currentUser?.id === user.id;
      return (
        inDepartment
          || inManagerDepartment
          || isCurrentUser
      );
    });
  });
  const departmentsBasedOnRole = computed(() => {
    const authStore = useAuthStore();

    if (!authStore.user.value) {
      return [];
    }

    const currentUser = authStore.user.value;
    const { hasRole, hasRoleStrict } = useRightsStore();
    if (
      hasRole(UserRole.ROLE_PARK_MANAGER)
        || hasRole(UserRole.ROLE_FRONT_DESK)
        || hasRoleStrict(UserRole.ROLE_DEPARTMENT_MANAGER)
    ) {
      return departments.value;
    }

    if (hasRole(UserRole.ROLE_DEPARTMENT_MANAGER)) {
      return departments.value.filter((t) => {
        let hasUserGroup = false;
        for (const userGroup of currentUser.managerDepartments) {
          const iri: string = typeof userGroup !== "string" ? userGroup["@id"] : userGroup;
          if (iri === t["@id"]) {
            hasUserGroup = true;
            break;
          }
        }

        return hasUserGroup;
      });
    }

    if (hasRole(UserRole.ROLE_USER)) {
      return departments.value.filter((t) => {
        let hasUserGroup = false;
        for (const userGroup of currentUser.departments) {
          const iri: string = typeof userGroup !== "string" ? userGroup["@id"] : userGroup;
          if (iri === t["@id"]) {
            hasUserGroup = true;
            break;
          }
        }

        return hasUserGroup;
      });
    }

    return [];
  });
  const assignableUsersTeamsDepartments = computed(() => {
    const defaultAssignableAssignees = [
      ...departmentsBasedOnRole.value,
      ...usersBasedOnRole.value,
    ];

    if (hasFeature(Feature.ANY_CAN_ASSIGN_TO_FRONTDESK)) {
      defaultAssignableAssignees.push(...frontdeskUsers.value);
    }

    return defaultAssignableAssignees.sort((a, b) => {
      const nameA = ("fullName" in a ? a.fullName : a.name).toUpperCase();
      const nameB = ("fullName" in b ? b.fullName : b.name).toUpperCase();

      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
  });
  const filteredAssignableUsersTeamsDepartments = computed(() => {
    return assignableUsersTeamsDepartments.value.filter((item) => {
      const name: "Department" | "User" = item["@type"] as "Department" | "User";
      const isTypeEnabled: boolean = searchTypes[name];

      if (!isTypeEnabled) {
        return false;
      }

      if (searchValue.value === "") {
        return true;
      }

      if ("fullName" in item) {
        return item.fullName.toLowerCase().includes(searchValue.value.toLowerCase());
      } else if ("name" in item) {
        return item.name.toLowerCase().includes(searchValue.value.toLowerCase());
      }

      return true;
    });
  });

  // Methods
  function reset() {
    users.value = [];
    departments.value = [];
    sorts.value = [];
    groups.value = [];
    objects.value = [];
    avatars.value = [];
    searchValue.value = "";
  }

  function setUsers(newUsers: HydraItem<User>[]) {
    users.value = newUsers;
  }
  function setSystemUsers(newUsers: HydraItem<User>[]) {
    systemUsers.value = newUsers;
  }
  function setGroups(newGroups: HydraItem<Group>[]) {
    groups.value = newGroups;
  }
  function setObjects(newObjects: HydraItem<Objects>[]) {
    objects.value = newObjects;
  }
  function setSorts(newSorts: HydraItem<Sort>[]) {
    sorts.value = newSorts;
  }
  function setDepartments(newDepartments: HydraItem<Department>[]) {
    // const naturalSort = createNewSortInstance({
    //   comparer: new Intl.Collator(undefined, { numeric: true, sensitivity: "base" }).compare,
    // });
    // departments.value = naturalSort(newDepartments).asc(d => d.name);
    departments.value = newDepartments;
  }
  function setSearchValue(value: string) {
    searchValue.value = value;
  }
  function toggleSearchType(type: "Department" | "User") {
    searchTypes[type] = !searchTypes[type];
  }
  function setAvatars(newAvatars: HydraItem<MediaObject>[]) {
    avatars.value = newAvatars;
  }

  const hasOneSort = computed(() => {
    return sorts.value.length === 1;
  });

  return {
    // State
    users,
    departments,
    sorts,
    objects,
    groups,
    departmentIris,
    avatars,
    searchValue,
    searchTypes,
    debugMode,
    taskSidebar,
    reportSidebar,
    hasOneSort,

    // Computed Properties
    avatarsUrlIndexedByIri,
    avatarsMediaObjectIndexedByIri,
    usersIndexedByIri,
    systemUsersIndexedByIri,
    departmentsIndexedByIri,
    sortsIndexedByIri,
    groupsIndexedByIri,
    objectsIndexedByIri,
    usersBasedOnRole,
    departmentsBasedOnRole,
    assignableUsersTeamsDepartments,
    filteredAssignableUsersTeamsDepartments,

    // Methods
    reset,
    setUsers,
    setSystemUsers,
    setGroups,
    setObjects,
    setSorts,
    setDepartments,
    setSearchValue,
    toggleSearchType,
    setAvatars,
  };
}

type SystemStoreInterface = ReturnType<typeof createSystemStore>;
let backwardsCompatibleInstance: null | SystemStoreInterface = null;
export function useSystemStore(): SystemStoreInterface {
  if (backwardsCompatibleInstance === null) {
    backwardsCompatibleInstance = createSystemStore();
  }

  return backwardsCompatibleInstance;
}
export default useSystemStore;
