import { useI18n } from "vue-i18n";
import { useToast } from "vue-toastification";
import * as Sentry from "@sentry/vue";
import type { Component } from "vue";
import type { HydraItem, MediaObject, ModalManager } from "@verbleif/lib";
import { api } from "@/core/api";
import { useMediaObject } from "@/features/FileUpload/useMediaObject";

interface UseEditArguments {
  component: Component
  endpoint: string
  translationKey: string
  modalManager: ModalManager
  transformer: ((input: any, item: any) => Promise<any>)
  componentProps?: Record<string, any>
  load?: (() => Promise<any>) | null
}

export function useEditReport({
  component,
  endpoint,
  translationKey,
  modalManager,
  transformer,
  componentProps = {},
}: UseEditArguments) {
  const { t } = useI18n();
  const { deleteFile } = useMediaObject();
  const { success, error } = useToast();

  function showEdit(item: Record<string, any>, initialMediaObjects: HydraItem<MediaObject>[]) {
    // item.mediaObjects was also a ref, which we don't want. There is no 'deep unref/toRaw'.
    const unreactiveItem = JSON.parse(JSON.stringify(item));

    modalManager.open({
      component,
      opts: {
        okIcon: ["fas", "plus"],
        okText: t("base.save"),
        cancelText: t("base.cancel"),
        onOk: input => edit(input, item, initialMediaObjects),
      },
      componentProps: {
        item: unreactiveItem,
        mediaObjects: initialMediaObjects,
        ...componentProps,
      },
    });
  }

  async function edit(
    input: Record<string, any>,
    item: Record<string, any>,
    initialMediaObjects: HydraItem<MediaObject>[],
  ) {
    const newMediaObjects = input.mediaObjects.map((m: any) => m.id);
    const filesToBeDeleted = initialMediaObjects.filter((mediaObject) => {
      return !newMediaObjects.includes(mediaObject.id);
    });

    modalManager.setLoading(true);

    const output = await transformer(input, item);

    const oldItem = Object.assign({}, item);
    modalManager.close();
    Object.assign(item, input);
    const toastId = success(t(`${translationKey}.edit_success`));

    api
      .patch(`${endpoint}/${item.id}`, output)
      .then((r) => {
        Object.assign(item, r.data);
      })
      .catch((e) => {
        let msg = "base.unknown_error";
        if (e.response) {
          msg = `base.${e.response.status}`;
        }
        error(t(msg, { errorCode: 0 }), { id: toastId });
        Object.assign(item, oldItem);
        Sentry.setContext("error", e);
        Sentry.captureException(e);
      })
      .finally(() => {
        modalManager.setLoading(false);
      });

    for (const fileToBeDeleted of filesToBeDeleted) {
      deleteFile(fileToBeDeleted);
    }
  }

  return { showEdit };
}

export default useEditReport;
