import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16",
  viewBox: "0 0 512 512"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      fill: "none",
      "fill-rule": "evenodd"
    }, [
      _createElementVNode("path", { d: "M0 0h512v512H0z" }),
      _createElementVNode("path", {
        fill: "#4F4F4F",
        "fill-rule": "nonzero",
        d: "M204.8 281.401c0 28.28 22.928 51.2 51.2 51.2 28.28 0 51.2-22.92 51.2-51.2s-22.92-51.2-51.2-51.2c-28.272 0-51.2 22.92-51.2 51.2m179.2 0c0 28.28 22.928 51.2 51.2 51.2 28.28 0 51.2-22.92 51.2-51.2s-22.92-51.2-51.2-51.2c-28.272 0-51.2 22.92-51.2 51.2m-307.2 51.2c28.28 0 51.2-22.92 51.2-51.2s-22.92-51.2-51.2-51.2c-28.272 0-51.2 22.92-51.2 51.2s22.928 51.2 51.2 51.2m384 25.6h-51.2c-28.272 0-51.2 22.92-51.2 51.2v25.6c0 14.136 11.464 25.6 25.6 25.6h102.4c14.136 0 25.6-11.464 25.6-25.6v-25.6c0-28.28-22.928-51.2-51.2-51.2m-179.2 0h-51.2c-28.272 0-51.2 22.92-51.2 51.2v25.6c0 14.136 11.464 25.6 25.6 25.6h102.4c14.136 0 25.6-11.464 25.6-25.6v-25.6c0-28.28-22.928-51.2-51.2-51.2m-179.2 0H51.2c-28.272 0-51.2 22.92-51.2 51.2v25.6c0 14.136 11.464 25.6 25.6 25.6H128c14.136 0 25.6-11.464 25.6-25.6v-25.6c0-28.28-22.928-51.2-51.2-51.2m-25.6-256h358.4v102.4c19.744 0 37.584 7.696 51.2 19.976V90.673C486.4 68.8 469.176 51 448 51H64c-21.176 0-38.4 17.8-38.4 39.672v133.904c13.616-12.28 31.464-19.976 51.2-19.976z"
      })
    ], -1)
  ])))
}
export default { render: render }