<script>
export default {
  name: "VSidebarItemNew",
  props: {
    text: {
      type: String,
      default: "Default text",
    },
  },
  emits: ["create"],
};
</script>

<template>
  <div class="nav-link" @click="$emit('create')">
    <div class="nav-item">
      <div class="nav-left">
        <div class="icon">
          <FontAwesomeIcon :icon="['far', 'plus-circle']" />
        </div>
        <div class="text">
          {{ text }}
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.nav-link {
  text-decoration: none;
}

.children {
  overflow: hidden;
  transition: all 0.5s;
}

.nav-item {
  .nav-left {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  max-height: 35px;
  .newList {
    margin-bottom: 0 !important;
  }
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  box-sizing: border-box;
  transition: ease background-color 100ms;
  user-select: none;
  padding-left: 10px;
  border-left: 5px rgba(0, 0, 0, 0) solid;

  .icon {
    width: 16px;
    display: inline-flex;
    justify-content: center;
    font-size: 13px;
    color: var(--text);
    margin-right: 10px;
  }

  .text {
    font-size: 14px;
    color: var(--text);
  }

  &:hover {
    background-color: var(--grey-1);
  }

  &.active {
    .text {
      font-weight: bold;
    }
  }
}
</style>
