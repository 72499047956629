import prettyBytes from "pretty-bytes";
import { computed, ref } from "vue";
import moment from "moment";
import { useExtensions } from "@/features/FileUpload/useExtensions";

export function useFileItem({ initialMediaObject = null }) {
  const { resolveExtension } = useExtensions();
  const date = ref(null);
  const mediaObject = ref(initialMediaObject);

  const size = computed(() => {
    return prettyBytes(mediaObject.value.size);
  });

  const isUploaded = computed(() => {
    return mediaObject.value !== null;
  });

  const extension = computed(() => {
    return mediaObject.value && resolveExtension(mediaObject.value.mimeType);
  });

  date.value = moment(mediaObject.value.createdAt).calendar();

  function download() {
    const link = document.createElement("a");
    fetch(mediaObject.value.url, { mode: "cors", credentials: "same-origin" })
      .then(r => r.blob())
      .then((blob) => {
        link.href = URL.createObjectURL(blob);
        link.download = mediaObject.value.originalName;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  }

  return {
    date,
    size,
    isUploaded,
    extension,
    download,
  };
}
