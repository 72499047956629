import type { Component } from "vue";
import { computed } from "vue";
import type { Activity } from "@verbleif/lib";
import { ActivityChangeTypeEnum } from "@verbleif/lib";
import InsideChange from "@/features/Activities/ChangeItems/InsideChange.vue";
import PriorityChange from "@/features/Activities/ChangeItems/PriorityChange.vue";
import StatusChange from "@/features/Activities/ChangeItems/StatusChange.vue";
import TextDiffChange from "@/features/Activities/ChangeItems/TextDiffChange.vue";
import DeadlineChange from "@/features/Activities/ChangeItems/DeadlineChange.vue";
import SimpleChange from "@/features/Activities/ChangeItems/SimpleChange.vue";
import UnknownChange from "@/features/Activities/ChangeItems/UnknownChange.vue";
import type { ActivityEntityType } from "@/features/Activities/useActivities";

const componentMap: Record<string, any> = {
  status: StatusChange,
  inside: InsideChange,
  timeSensitive: SimpleChange,
  deadline: DeadlineChange,
  deadlineAt: DeadlineChange,
  priority: PriorityChange,
  description: TextDiffChange,
  _default: UnknownChange,
};

interface Changes {
  component: Component
  props: Record<string, any>
}

const hiddenProperties: string[] = ["createdAt", "updatedAt", "deletedAt"];

interface UseActivityEntityArguments {
  activityEntityType: ActivityEntityType
  activity: Activity
}

export function useActivityEntity({ activityEntityType, activity }: UseActivityEntityArguments) {
  function getUpdateChanges(activity: Activity): Record<string, Changes> {
    const changes: Record<string, Changes> = {};

    const before = activity.changeSet?.before;
    const after = activity?.changeSet?.after;
    if (!before || !after) {
      return {};
    }
    const keys = [...new Set([...Object.keys(before), ...Object.keys(after)])];
    for (const keyName of keys) {
      if (hiddenProperties.includes(keyName)) {
        continue;
      }

      changes[keyName] = {
        component: componentMap?.[keyName] || componentMap._default,
        props: {
          type: activityEntityType,
          changeType: activity.changeType,
          propertyName: keyName,
          fromValue: before?.[keyName],
          toValue: after?.[keyName],
        },
      };
    }

    return changes;
  }

  function getCreateOrDeleteChanges(activity: Activity): Record<string, Changes> {
    return {
      unused: {
        component: componentMap._default,
        props: {
          type: activityEntityType,
          changeType: activity.changeType,
        },
      },
    };
  }

  const changeMap = computed<Changes[]>(() => {
    switch (activity.changeType) {
      case ActivityChangeTypeEnum.CREATED:
      case ActivityChangeTypeEnum.DELETED:
        return Object.values(getCreateOrDeleteChanges(activity));
      case ActivityChangeTypeEnum.UPDATED:
        return Object.values(getUpdateChanges(activity));
      default:
        return [];
    }
  });

  return {
    changeMap,
  };
}
