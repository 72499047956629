<script setup>
import { ref, watchEffect } from "vue";
import { useBlocking } from "./useBlocking";
import BlockedSvg from "./Blocking.svg?component";

const props = defineProps({
  blocking: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: true,
  },
});

const { blockingToClass } = useBlocking();
const blockedClass = ref("");

watchEffect(() => {
  if (props.loading) {
    blockedClass.value = "";
    return;
  }
  blockedClass.value = blockingToClass(props.blocking);
});
</script>

<template>
  <div class="blocking-container" :class="blockedClass">
    <VSkeleton
      v-if="loading"
      :width="100"
      :dynamic-width="false"
      no-background
      :pill="false"
    >
      <BlockedSvg />
    </VSkeleton>
    <template v-else>
      <BlockedSvg />
    </template>
  </div>
</template>

<style lang="scss" scoped>
.blocking-container {
  display: flex;
  color: #dbdbdb;
  pointer-events: none;
  width: 22px;
  height: 22px;

  &.is-blocking {
    &::v-deep(.blocking) {
      fill: var(--secondary);
    }
  }
}
</style>
