import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "orange",
  viewBox: "0 0 512 512"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M438.6 155.7c-7.2 11.5-15 22.6-22.6 32.7-7.5-10.1-15.4-21.2-22.6-32.7C375.7 127.3 368 106.3 368 96c0-26.5 21.5-48 48-48s48 21.5 48 48c0 10.3-7.7 31.3-25.4 59.7M427.4 251C452.9 221.1 512 146.2 512 96c0-53-43-96-96-96s-96 43-96 96c0 33.3 25.9 77.3 50.8 112H328c-48.6 0-88 39.4-88 88s39.4 88 88 88h96c22.1 0 40 17.9 40 40s-17.9 40-40 40H177.8c-2.7 3.8-5.5 7.6-8.2 11.2-10.7 14.2-21.3 26.9-30 36.8H424c48.6 0 88-39.4 88-88s-39.4-88-88-88h-96c-22.1 0-40-17.9-40-40s17.9-40 40-40h84.9c5.1 1.1 10.7-.6 14.5-5M118.9 408.4c-7.3 11-15.3 21.7-22.9 31.2-7.6-9.5-15.6-20.2-22.9-31.2C55.4 381.7 48 361.9 48 352c0-26.5 21.5-48 48-48s48 21.5 48 48c0 9.9-7.4 29.7-25.1 56.4m19 55.6c25.9-33.1 54.1-77.3 54.1-112 0-53-43-96-96-96S0 299 0 352c0 50.5 59.8 121 85 148.4 6 6.5 16 6.5 21.9 0l.3-.3c.3-.3.6-.7.9-1 2.8-3.1 6-6.7 9.5-10.7l.2-.2c6.1-7 13-15.2 20-24.2z" }, null, -1)
  ])))
}
export default { render: render }