<script setup lang="ts">
import { computed } from "vue";
import { Feature, UserRole, useAuthStore, useLocationStore } from "@verbleif/shared";
import type { HydraItem, SingleThirdPartyAwareInterface, SkeletonItem, Task } from "@verbleif/lib";
import Deadline from "./DeadlineItem.vue";
import Status from "@/features/Status/StatusBox.vue";
import { useRightsStore } from "@/core/store/RightStore";
import InsideItem from "@/features/Inside/InsideItem.vue";
import AssignmentList from "@/features/AssignTo/AssignmentList.vue";
import ConnectIcon from "@/features/Auth/OAuth/Thirdparties/ConnectIcon.vue";
import AssignTo from "@/features/AssignTo/AssignTo.vue";
import { useMultiSelect } from "@/features/Tasks/Overview/useMultiSelect";
import SelectBox from "@/features/Tasks/Overview/TaskItem/SelectBox.vue";
import TaskActions from "@/features/Tasks/Overview/TaskItem/TaskActions.vue";
import { useSystemStore } from "@/features/Store/useSystemStore";
import PriorityItem from "@/features/Priority/PriorityItem.vue";

const props = defineProps<{
  task: HydraItem<Task & SingleThirdPartyAwareInterface> | SkeletonItem
  index: number
}>();

const emit = defineEmits<{
  (e: "update", data: Partial<HydraItem<Task>>): void
  (e: "onRowClick"): void
}>();

const { hasFeature } = useRightsStore();
const { hasRole } = useRightsStore();
const authStore = useAuthStore();

const { onItemSelect, multiSelectIris } = useMultiSelect();
const systemStore = useSystemStore();
const locationStore = useLocationStore();

function multiSelect(event: MouseEvent) {
  if ("skeleton" in props.task) {
    return;
  }
  onItemSelect(props.task, event);
}

const groupName = computed(() => {
  if ("skeleton" in props.task) {
    return null;
  }

  const object = props.task.object;
  if (!object) {
    return null;
  }

  const objectIri = mapToIri(object);
  const objectData = systemStore.objectsIndexedByIri.value?.[objectIri];
  if (!objectData) {
    return null;
  }
  if (!objectData.group) {
    return null;
  }

  const groupIri = mapToIri(objectData.group);
  if (!systemStore.groupsIndexedByIri.value?.[groupIri]) {
    return null;
  }

  return systemStore.groupsIndexedByIri.value[groupIri].name;
});

const objectName = computed(() => {
  if ("skeleton" in props.task) {
    return null;
  }

  if (!props.task.object) {
    return null;
  }

  const objectIri = mapToIri(props.task.object);
  const objectData = systemStore.objectsIndexedByIri.value?.[objectIri];
  if (!objectData) {
    return null;
  }

  return objectData.name;
});

const description = computed(() => {
  if ("skeleton" in props.task) {
    return null;
  }

  if (hasFeature(Feature.HIDE_QUANTITY)) {
    return props.task.description;
  }

  if (!props.task?.quantity) {
    return props.task.description;
  }

  return `${props.task.quantity}x ${props.task.description}`;
});

const isSelected = computed(() => {
  if ("skeleton" in props.task) {
    return false;
  }
  return multiSelectIris.value.includes(props.task["@id"]);
});

function openInThirdParty() {
  if ("skeleton" in props.task) {
    return;
  }
  if (props.task?.thirdPartyName !== "booking_experts") {
    return;
  }

  const administrationId = locationStore.selectedLocationObject.value?.thirdPartyReference;
  if (!administrationId) {
    return;
  }

  const todoId = props?.task?.thirdPartyReference;
  if (!todoId) {
    return;
  }

  const url = `https://app.bookingexperts.nl/parks/${administrationId}/todos/${todoId}`;
  (window as any).open(url, "_blank").focus();
}

function mapToIri<T>(item: string | HydraItem<T>): string {
  return typeof item === "string" ? item : item["@id"];
}

const assignmentIris = computed(() => {
  if ("skeleton" in props.task) {
    return [];
  }

  let assignments: string[] = [];

  if (props.task?.users?.map) {
    const iris = props.task?.users?.map(mapToIri);
    assignments = [...assignments, ...iris];
  }
  if (props.task?.departments?.map) {
    const iris = props.task?.departments.map(mapToIri);
    assignments = [...assignments, ...iris];
  }

  return assignments;
});

function updateAssignment(items: string[]) {
  const users: string[] = [];
  const teams: string[] = [];
  const departments: string[] = [];

  for (const assignment of items) {
    if (assignment.includes("/departments")) {
      if (departments.includes(assignment)) {
        break;
      }
      departments.push(assignment);
    }
    if (assignment.includes("/teams")) {
      if (teams.includes(assignment)) {
        break;
      }
      teams.push(assignment);
    }
    if (assignment.includes("/users")) {
      if (users.includes(assignment)) {
        break;
      }
      users.push(assignment);
    }
  }

  emit("update", {
    departments,
    teams,
    users,
  });
}

const allowedToChange = computed(() => {
  if (hasRole(UserRole.ROLE_DEPARTMENT_MANAGER)) {
    return true;
  }
  if ("skeleton" in props.task) {
    return false;
  }
  if (typeof props.task?.createdBy === "string") {
    return false;
  }
  return props.task?.createdBy?.id === authStore.user.value?.id;
});
</script>

<template>
  <div
    class="task-item-container"
    :class="{
      'is-clickable': 'skeleton' in task ? false : allowedToChange,
      'has-connect-icon': 'skeleton' in task ? false : task.thirdPartyName,
    }"
    @contextmenu.prevent.capture="() => {}"
    @click="!('skeleton' in task) && emit('onRowClick')"
  >
    <SelectBox
      v-if="!('skeleton' in task)"
      class="multi-select-radio"
      :is-selected="isSelected"
      @toggle-selected="multiSelect"
      @click.stop="() => {}"
    />
    <Status
      :loading="'skeleton' in task"
      :model-value="'skeleton' in task ? undefined : task.status"
      singleton-group="task-item"
      @update:model-value="(d) => emit('update', { status: d })"
    />
    <PriorityItem
      v-if="!hasFeature(Feature.HIDE_PRIORITY)"
      :is-changeable="allowedToChange"
      :loading="'skeleton' in task"
      singleton-group="task-item"
      is-clearable
      :model-value="'skeleton' in task ? null : task.priority"
      @update:model-value="(d) => emit('update', { priority: d })"
    />
    <InsideItem
      v-if="!hasFeature(Feature.HIDE_INSIDE)"
      :loading="'skeleton' in task"
      :is-changeable="allowedToChange"
      is-clearable
      singleton-group="task-item"
      :model-value="'skeleton' in task ? null : task.inside"
      @update:model-value="(d) => emit('update', { inside: d })"
    />
    <div class="text">
      <div v-if="groupName" class="sub-text">
        Objectgroep: {{ groupName }}
      </div>
      <div class="main-text">
        <VSkeleton v-if="'skeleton' in task" :width="75" :height="14" />
        <template v-else>
          <template v-if="objectName">
            {{ objectName }}
            -
          </template>
          <template v-if="description">
            {{ description }}
          </template>
        </template>
      </div>
    </div>
    <div class="right">
      <AssignTo
        v-if="!('skeleton' in task)"
        :disabled="!allowedToChange"
        :model-value="assignmentIris"
        singleton-group="task-item"
        @update:model-value="(d) => updateAssignment(d)"
      >
        <AssignmentList
          :assignments="assignmentIris"
        />
      </AssignTo>
      <Deadline
        :loading="'skeleton' in task"
        :deadline-at="'skeleton' in task ? null : task.deadlineAt"
        :time-sensitive="'skeleton' in task ? undefined : task.timeSensitive"
        class="right-item"
      />
      <VSkeleton
        v-if="'skeleton' in task"
        :width="16"
        :dynamic-width="false"
        width-unit="px"
        :height="10"
      />
      <template v-else>
        <TaskActions class="right-item">
          <slot name="actions" />
        </TaskActions>
      </template>
    </div>
    <ConnectIcon
      v-if="!('skeleton' in task) && task.thirdPartyName"
      :third-party-name="task.thirdPartyName"
      @click.stop="openInThirdParty"
    />
  </div>
</template>

<style lang="scss" scoped>
.task-item-holder {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  &:hover {
    ::v-deep(.select-box) {
      cursor: pointer;
      display: flex;
    }
  }
}

.compare-bex {
  font-size: 12px;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.task-item-container {
  position: relative;
  border: 1px solid var(--grey-1);
  background-color: var(--input-background);
  border-radius: 0 4px 4px 4px;
  display: flex;
  align-items: center;
  margin-left: 20px;
  padding: 10px 10px 10px 15px;
  user-select: none;
  transition: none;
  gap: 10px;

  &.has-connect-icon {
    padding-right: 25px;
  }

  .multi-select-radio {
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: -15px;
  }

  &.is-clickable {
    cursor: pointer;

    &:hover {
      background-color: var(--input-background-hover);

      .multi-select-radio {
        display: flex;
      }

      .text {
        color: var(--primary);
      }
    }
  }

  .right {
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: 10px;
  }

  .text {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;

    .sub-text {
      color: var(--text-2);
      font-size: 12px;
    }

    .main-text {
      width: 100%;
      font-size: 14px;
      color: var(--text);
      display: flex;
      flex: 1 1;
      letter-spacing: 0.5px;
      word-break: break-word;
    }
  }
}
</style>
