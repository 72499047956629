import { TaskInside } from "@verbleif/lib";
import InsideFalseInlineIcon from "./InsideFalseInlineIcon.svg";
import InsideTrueInlineIcon from "./InsideTrueInlineIcon.svg";
import InsideFalseStrokedIcon from "./InsideFalseStrokedIcon.svg";
import InsideTrueStrokedIcon from "./InsideTrueStrokedIcon.svg";
import InsidePlaceholderIcon from "./InsidePlaceholderIcon.svg";

export const INSIDES_TEXTS = {
  [TaskInside.NO]: "tasks.inside.closed",
  [TaskInside.YES]: "tasks.inside.open",
};
export const INSIDES_CLASSES = {
  [TaskInside.NO]: "is-closed",
  [TaskInside.YES]: "is-open",
};

export function useInside() {
  function insideToStrokedIcon(v: TaskInside) {
    switch (v) {
      case TaskInside.NO:
        return InsideFalseStrokedIcon;
      case TaskInside.YES:
        return InsideTrueStrokedIcon;
      default:
        return InsidePlaceholderIcon;
    }
  }

  function insideToInlineIcon(v: TaskInside) {
    switch (v) {
      case TaskInside.NO:
        return InsideFalseInlineIcon;
      case TaskInside.YES:
        return InsideTrueInlineIcon;
      default:
        return InsideFalseInlineIcon;
    }
  }

  function insideToText(v: TaskInside) {
    return INSIDES_TEXTS?.[v] || "tasks.inside.choose";
  }

  function insideToClass(v: TaskInside) {
    return INSIDES_CLASSES?.[v] || "unknown";
  }

  return {
    insideToInlineIcon,
    insideToStrokedIcon,
    insideToText,
    insideToClass,
  };
}
