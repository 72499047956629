import { ref } from "vue";
import { useI18n } from "vue-i18n";

export function useCount(TRANSLATION_KEY: string, initialCount: Ref<any[]>) {
  const { t } = useI18n();
  const attachmentTitle = ref(
    t(`${TRANSLATION_KEY}.attachments.tab_title`, { count: initialCount.value }),
  );

  watchEffect(() => {
    setCount(initialCount.value.length);
  });

  function setCount(count: number) {
    attachmentTitle.value = t(`${TRANSLATION_KEY}.attachments.tab_title`, { count });
  }

  return {
    setCount,
    attachmentTitle,
  };
}
