export default function useParamsHelper() {
  function hasParamStartingWithAndContains(params: URLSearchParams, key: string, contains: string) {
    for (const k of params.keys()) {
      if (k.startsWith(key)) {
        return k.includes(contains);
      }
    }

    return false;
  }

  function removeParamStartingWithAndContains(params: URLSearchParams, key: string, contains: string | Array<string>) {
    for (const k of params.keys()) {
      // Check if the key starts with the specified key
      if (k.startsWith(key)) {
        // Determine if 'contains' is an array or a string and perform the appropriate check
        let shouldDelete = false;

        if (Array.isArray(contains)) {
          // If 'contains' is an array, check if any element in the array is included in the key
          shouldDelete = contains.some(substring => k.includes(substring));
        } else {
          // If 'contains' is a string, perform a simple inclusion check
          shouldDelete = k.includes(contains);
        }

        // Delete the key if the conditions are met
        if (shouldDelete) {
          params.delete(k);
        }
      }
    }
  }

  return {
    hasParamStartingWithAndContains,
    removeParamStartingWithAndContains,
  };
}
