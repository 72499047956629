function useDragAndDrop({ editable = true, onDrop }) {
  function drop(e) {
    if (!editable) {
      return;
    }
    e.currentTarget.classList.remove("drag-over");
    onDrop && onDrop(e.dataTransfer.files);
  }

  function dragOver(e) {
    if (!editable) {
      return;
    }
    e.currentTarget.classList.add("drag-over");
  }

  function dragLeave(e) {
    if (!editable) {
      return;
    }
    e.currentTarget.classList.remove("drag-over");
  }
  return { dragLeave, drop, dragOver };
}
export default useDragAndDrop;
