import type { Component } from "vue";
import { computed, inject, provide, ref } from "vue";
import type { RouteLocationRaw } from "vue-router";
import { useRoute, useRouter } from "vue-router";
import { Feature, UserRole, useAuthStore } from "@verbleif/shared";
import type { HydraItem, User } from "@verbleif/lib";
import UsersClass from "./Sidebar/users-class.svg?component";
import UserSvg from "./Sidebar/user.svg?component";
import { useRightsStore } from "@/core/store/RightStore";
import { api } from "@/core/api";
import { useSystemStore } from "@/features/Store/useSystemStore";
import { TaskListType } from "@/features/Tasks/TasksRoutes";

const TaskListKey = Symbol("TaskListKey");

interface TaskListRouteItem {
  id: number
  routeId: string
  text: string
  title: string
  icon: Component
  to: RouteLocationRaw
  parent: string | null
}

function mapToIri(item: string | HydraItem<any>): string {
  return typeof item === "string" ? item : item["@id"];
}

export function createTaskList() {
  const taskLists = ref<any>([]);
  const route = useRoute();
  const router = useRouter();
  const authStore = useAuthStore();
  const systemStore = useSystemStore();
  const { hasRole, hasRoleStrict } = useRightsStore();
  const { hasFeature } = useRightsStore();

  function getLists(
    departments: string[] | HydraItem<any>[],
    users: string[] | HydraItem<any>[],
  ) {
    const listItems: TaskListRouteItem[] = [];

    departments.forEach((departmentOrIri) => {
      const department = systemStore.departmentsIndexedByIri.value?.[mapToIri(departmentOrIri)];
      if (!department) {
        console.error("Department not found", mapToIri(departmentOrIri));
        return;
      }
      const routeId = `${TaskListType.TYPE_DEPARTMENT}_${department.id}`;

      const listItem: TaskListRouteItem = {
        id: department.id,
        routeId,
        text: department.name,
        title: department.name,
        icon: UsersClass,
        to: {
          name: "task-list",
          params: { type: TaskListType.TYPE_DEPARTMENT, id: department.id },
        },
        parent: null,
      };
      listItems.push(listItem);
    });

    users.forEach((userOrIri) => {
      const user = systemStore.usersIndexedByIri.value?.[mapToIri(userOrIri)];
      if (!user) {
        console.error("User not found", mapToIri(userOrIri));
        return;
      }
      if (!authStore.user.value) {
        throw new Error("Calling getLists before an authenticated user exists is not possible.");
      }
      const routeId = `${TaskListType.TYPE_USER}_${user.id}`;

      if (hasRoleStrict(UserRole.ROLE_DEPARTMENT_MANAGER)) {
        const { managerDepartments } = authStore.user.value;
        const iris = managerDepartments.map(mapToIri);
        if (
          !user.departments.find(i => iris.includes(mapToIri(i)))
          && !user.managerDepartments.find(i => iris.includes(mapToIri(i)))
        ) {
          return;
        }
      }

      const listItem = {
        id: user.id,
        routeId,
        text: user.fullName,
        title: user.fullName,
        icon: UserSvg,
        to: { name: "task-list", params: { type: TaskListType.TYPE_USER, id: user.id } },
        parent: null,
      };

      const parents = getParentsForUser(user);

      if (parents.length) {
        return parents.forEach(parent =>
          listItems.push({ ...listItem, parent }),
        );
      }

      // Don't show role manager or front desk or park manager in the sidebar.
      if (
        hasRole(UserRole.ROLE_MANAGER, user)
        || hasRole(UserRole.ROLE_FRONT_DESK, user)
        || hasRole(UserRole.ROLE_PARK_MANAGER, user)
      ) {
        return;
      }

      // Probably a manager because he is not member of any department/team.
      listItems.push(listItem);
    });

    return listItems;
  }

  const lists = computed(() => {
    if (!authStore.user.value) {
      return [];
    }
    if (hasRole(UserRole.ROLE_FRONT_DESK)) {
      return getLists(
        systemStore.departmentsBasedOnRole.value,
        systemStore.usersBasedOnRole.value,
      );
    }
    if (hasRole(UserRole.ROLE_DEPARTMENT_MANAGER)) {
      return getLists(
        authStore.user.value.managerDepartments,
        systemStore.usersBasedOnRole.value,
      );
    }
    if (hasRole(UserRole.ROLE_USER)) {
      return getLists(
        authStore.user.value.departments,
        [],
      );
    }

    return [];
  });

  const rootLists = computed(() => {
    return lists.value.filter(l => !l.parent);
  });

  const otherUserLists = computed(() => {
    return systemStore.usersBasedOnRole.value.filter(u => u?.roles?.some(role => ["ROLE_MANAGER", "ROLE_FRONT_DESK", "ROLE_PARK_MANAGER"].includes(role))).map(user => ({
      id: user.id,
      routeId: `${TaskListType.TYPE_USER}_${user.id}`,
      text: user.fullName,
      title: user.fullName,
      icon: UserSvg,
      to: { name: "task-list", params: { type: TaskListType.TYPE_USER, id: user.id } },
      parent: null,
    }));
  });

  const childrenPerListItem = computed(() => {
    const items: Record<string, string[]> = {};

    lists.value.forEach((listItem) => {
      const parent = listItem.parent;
      if (!parent) {
        return;
      }

      if (!items[parent]) {
        items[parent] = [];
      }

      items[parent].push(listItem.routeId);
    });

    return items;
  });

  const listsKeyedByRouteId = computed(() => {
    return lists.value.reduce((acc, i) => ({ ...acc, [i.routeId]: i }), {});
  });

  function getParentsForUser(userOrIri: string | HydraItem<User>) {
    const user = systemStore.usersIndexedByIri.value?.[mapToIri(userOrIri)];
    if (!user) {
      return [];
    }
    const parentRouteIds: string[] = [];

    if (user.managerDepartments.length) {
      user.managerDepartments.forEach((i) => {
        const department = systemStore.departmentsIndexedByIri.value?.[mapToIri(i)];
        if (!department) {
          return;
        }
        parentRouteIds.push(`${TaskListType.TYPE_DEPARTMENT}_${department.id}`);
      });
    }

    // If there are no parents, add departments as parent.
    if (!parentRouteIds.length && user.departments.length) {
      user.departments.forEach((i) => {
        const department = systemStore.departmentsIndexedByIri.value?.[mapToIri(i)];
        if (!department) {
          return;
        }
        parentRouteIds.push(`${TaskListType.TYPE_DEPARTMENT}_${department.id}`);
      });
    }

    return parentRouteIds;
  }

  const currentListType = computed<TaskListType | null>(() => {
    return route.params.type as TaskListType || null;
  });

  const currentListId = computed(() => {
    return route.params.id as string || null;
  });

  const currentListRouteId = computed(() => {
    if (!currentListType.value) {
      return null;
    }
    return `${currentListType.value}_${currentListId.value}`;
  });

  const currentList = computed(() => {
    switch (currentListType.value) {
      case TaskListType.TYPE_ALL:
      case TaskListType.TYPE_TODAY:
      case TaskListType.TYPE_OPEN_UNTIL_TODAY:
      case TaskListType.TYPE_ALL_UNTIL_TODAY:
      case TaskListType.TYPE_ALL_TODAY:
      case TaskListType.TYPE_ASSIGNED_TO_ME:
      case TaskListType.TYPE_UNASSIGNED:
      case TaskListType.TYPE_READY:
      case TaskListType.TYPE_FINISHED:
      case TaskListType.TYPE_CHANGEOVER_DAY:
      case TaskListType.TYPE_ROUTE:
      case TaskListType.TYPE_TEMPLATE:
        return null;
      case TaskListType.TYPE_DEPARTMENT:
        return systemStore.departmentsBasedOnRole.value.find((department) => {
          return (
            `${TaskListType.TYPE_DEPARTMENT}_${department.id}` === currentListRouteId.value
          );
        });
      case TaskListType.TYPE_USER:
        return systemStore.usersBasedOnRole.value.find((user) => {
          return `${TaskListType.TYPE_USER}_${user.id}` === currentListRouteId.value;
        });
      case TaskListType.TYPE_CUSTOM:
      default:
        return taskLists.value.find((taskList: any) => {
          return `${TaskListType.TYPE_CUSTOM}_${taskList.id}` === currentListRouteId.value;
        });
    }
  });

  const loading = computed(() => {
    return !!currentList.value;
  });

  const currentListName = computed(() => {
    if (currentList.value) {
      return "fullName" in currentList.value ? currentList.value.fullName : currentList.value.name;
    }

    return "";
  });

  const currentListTranslation = computed(() => {
    if (!currentListType.value) {
      return "unknown";
    }
    const map = {
      [TaskListType.TYPE_ALL]: "tasks.type.all",
      [TaskListType.TYPE_TODAY]: "tasks.type.today",
      [TaskListType.TYPE_OPEN_UNTIL_TODAY]: "tasks.type.open_until_today",
      [TaskListType.TYPE_ALL_UNTIL_TODAY]: "tasks.type.all_until_today",
      [TaskListType.TYPE_ALL_TODAY]: "tasks.type.all_today",
      [TaskListType.TYPE_ASSIGNED_TO_ME]: "tasks.type.assigned_to_me",
      [TaskListType.TYPE_UNASSIGNED]: "tasks.type.unassigned",
      [TaskListType.TYPE_READY]: "tasks.type.ready",
      [TaskListType.TYPE_FINISHED]: "tasks.type.finished",
      [TaskListType.TYPE_DEPARTMENT]: "tasks.type.department",
      [TaskListType.TYPE_TEAM]: "tasks.type.team",
      [TaskListType.TYPE_USER]: "tasks.type.user",
      [TaskListType.TYPE_CUSTOM]: "tasks.type.custom",
      [TaskListType.TYPE_CHANGEOVER_DAY]: "tasks.type.change_over_day",
      [TaskListType.TYPE_ROUTE]: "tasks.type.route",
      [TaskListType.TYPE_TEMPLATE]: "tasks.type.template",
    };
    return map[currentListType.value];
  });

  async function createTaskList(name: string) {
    const taskList = { name, active: 1 };
    const index = taskLists.value.push({
      ...taskList,
      id: Math.random(),
      isPlaceholder: true,
    });
    api.post("/task_lists", taskList).then((response) => {
      taskLists.value.splice(index - 1, 1, {
        ...response.data,
        editMode: false,
      });
    });
  }

  async function deleteTaskList(id: number, index: number) {
    const listToBeRemoved = taskLists.value[index];
    await router.push({ name: "task-list", params: { list: TaskListType.TYPE_TODAY } });
    taskLists.value.splice(index, 1);
    return api
      .delete(`/task_lists/${id}`)
      .then(() => true)
      .catch(() => {
        taskLists.value.splice(index, 0, listToBeRemoved);
        return false;
      });
  }

  async function updateTaskList(id: number, index: number, data: any) {
    const beforeEdit = { ...taskLists.value[index] };
    Object.assign(taskLists.value[index], data);
    return api
      .patch(`/task_lists/${id}`, data)
      .then(() => true)
      .catch(() => {
        Object.assign(taskLists.value[index], beforeEdit);
        return false;
      });
  }

  watchEffect(() => {
    if (!authStore.user.value) {
      return;
    }
    if (!hasFeature(Feature.TASK_LISTS)) {
      return;
    }
    api
      .get(`/users/${authStore.user.value.id}/created_task_lists`)
      .then(({ data }) => {
        taskLists.value = data["hydra:member"];
      });
  });

  return {
    rootLists,
    childrenPerListItem,
    listsKeyedByRouteId,
    currentListName,
    updateTaskList,
    currentList,
    currentListRouteId,
    otherUserLists,
    currentListId,
    currentListType,
    currentListTranslation,
    TaskListTypes: TaskListType,
    taskLists,
    loading,
    createTaskList,
    deleteTaskList,
  };
}

export function provideTaskList() {
  const inst = createTaskList();
  provide(TaskListKey, inst);
  return inst;
}

type UseTaskList = ReturnType<typeof createTaskList>;

export function useTaskList() {
  const inst = inject<UseTaskList>(TaskListKey);

  if (!inst) {
    throw new Error("Run provideTaskList before useTaskList");
  }

  return inst;
}
