<script>
import { computed } from "vue";
import { useStringToHsl } from "@verbleif/lib";
import { useSystemStore } from "@/features/Store/useSystemStore";

export default {
  name: "AvatarItem",
  props: {
    name: {
      type: String,
      default: "",
    },
    active: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: [Array, String, Boolean],
      default: false,
    },
    inactiveIcon: {
      type: [Array, String, Boolean],
      default: false,
    },
    size: {
      type: String,
      default: "is-large",
    },
    letterAmount: {
      type: Number,
      default: 2,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    avatarIri: {
      type: [Boolean, String, undefined, null],
      default: false,
    },
  },
  setup(props) {
    const { stringToHslColor } = useStringToHsl();
    const systemStore = useSystemStore();

    const avatarUrl = computed(() => {
      return props.avatarIri
        ? systemStore.avatarsUrlIndexedByIri.value?.[props.avatarIri]
        : null;
    });

    const letters = computed(() => {
      const name = props.name.split(" ").filter(n => n);

      if (name.length === 1) {
        return name[0].substr(0, props.letterAmount);
      }

      let letters = "";
      name.forEach((part) => {
        if (letters.length >= props.letterAmount) {
          return;
        }
        letters += part[0];
      });

      return letters;
    });

    const color = computed(() => {
      if (props.loading) {
        return false;
      }

      return props.active ? stringToHslColor(props.name) : "#CCCCCC";
    });

    return {
      color,
      letters,
      stringToHslColor,
      avatarUrl,
    };
  },
};
</script>

<template>
  <div class="v-name-profile-pic" :class="[size]">
    <span
      class="image"
      :style="{ 'background-color': color, 'is-loading': loading }"
    >
      <VSkeleton
        v-if="loading"
        pill
        :width="100"
        :height="100"
        height-unit="%"
        :dynamic-width="false"
      />
      <template v-else>
        <template v-if="avatarUrl">
          <img :src="avatarUrl" alt="Avatar" class="avatar-img">
        </template>
        <template v-else-if="icon">
          <FontAwesomeIcon class="icon" :icon="icon" />
        </template>
        <template v-else>{{ letters.toUpperCase() }}</template>
      </template>
    </span>
    <span v-if="!loading && !active && inactiveIcon" class="inactive">
      <FontAwesomeIcon :icon="inactiveIcon" />
    </span>
  </div>
</template>

<style lang="scss" scoped>
.v-name-profile-pic {
  display: flex;
  align-items: center;
  color: var(--text);

  .slot-content {
    display: inline-flex;
    flex-shrink: 1;
  }

  .avatar-img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  &.is-small {
    font-size: 12px;
    .image {
      flex: 1 1 24px;
      width: 24px;
      height: 24px;
      font-size: 10px;
      font-weight: 500;
    }
  }

  &.is-medium {
    font-size: 14px;
    .image {
      flex: 1 1 30px;
      width: 30px;
      height: 30px;
    }
  }

  &.is-large {
    font-size: 14px;
    .image {
      font-size: 17px;
      flex: 1 1 38px;
      width: 38px;
      height: 38px;
    }
  }

  .inactive {
    margin-left: 5px;
  }
  .icon {
    display: flex;
    font-size: 11px;
  }

  .image {
    display: inline-flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    color: white;
  }
}
</style>
