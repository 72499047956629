import {
  HeaderRequestInterceptor,
  JwtBearerTokenRequestInterceptor, createApi,
} from "@verbleif/lib";
import { useAuthStore, useConfigStore } from "@verbleif/shared";
import { LegacyUrlPrefixPlugin } from "@/core/api/legacyUrlPrefix.plugin";
import { ImpersonationPlugin } from "@/core/api/impersonation.plugin";

const authStore = useAuthStore();
const configStore = useConfigStore();

const AuthRoutes = ["/api/v1/token/login", "/api/v1/token/refresh"];

export const { api, installInterceptor } = createApi({
  enableErrorHandler: true,
  baseURL: () => configStore.config.value?.apiUrl || "",
  interceptors: [
    HeaderRequestInterceptor(() => ({ "Content-Type": "application/merge-patch+json" }), "patch"),
    HeaderRequestInterceptor(() => ({ "Content-Type": "application/ld+json" }), "post"),
    HeaderRequestInterceptor(() => ({ "Content-Type": "application/ld+json" }), "put"),
    JwtBearerTokenRequestInterceptor(
      () => authStore.token.value || "",
      AuthRoutes,
    ),
    LegacyUrlPrefixPlugin(),
    ImpersonationPlugin(AuthRoutes),
  ],
});

export default api;
