<script setup lang="ts">
import { TaskInside } from "@verbleif/lib";
import { useInside } from "./useInside";
import ChangeableItem from "@/features/ChangeableItem/ChangeableItem.vue";

withDefaults(defineProps<{
  modelValue?: number | null
  inline?: boolean
  isChangeable?: boolean
  loading?: boolean
  disabled?: boolean
  singletonGroup?: string
  isClearable?: boolean
}>(), {
  modelValue: null,
  inline: true,
  disabled: false,
  isChangeable: true,
  singletonGroup: undefined,
  loading: true,
  isClearable: false,
});

const emit = defineEmits<{
  "update:modelValue": [number | null]
}>();

const { insideToClass, insideToText, insideToStrokedIcon, insideToInlineIcon }
  = useInside();

function update(v: TaskInside | null) {
  emit("update:modelValue", v);
}
</script>

<template>
  <ChangeableItem
    :item-to-text="insideToText"
    :disabled="disabled"
    :item-to-class="insideToClass"
    :items="Object.values(TaskInside).filter(value => typeof value === 'number') as number[]"
    :singleton-group="singletonGroup"
    :model-value="modelValue"
    :loading="loading"
    class="inside"
    :item-to-inline-icon="insideToInlineIcon"
    :item-to-stroked-icon="insideToStrokedIcon"
    :inline="inline"
    :is-clearable="isClearable"
    :is-changeable="isChangeable"
    @update:model-value="update"
  />
</template>

<style lang="scss">
$insides: (
  is-open: $success,
  is-closed: $danger,
);

@each $name, $color in $insides {
  .inside .#{$name} {
    .preview .#{$name} {
      fill: $color;
    }
    .preview .#{$name}-stroke {
      stroke: $color;
    }
  }

  .changeable-item-container-popover.inside .#{$name} {
    &:hover {
      color: $color;
    }

    &.is-active {
      color: $color;
    }

    .preview .#{$name} {
      fill: $color;
    }
  }
}
</style>
