import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16",
  viewBox: "0 0 512 512"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      fill: "none",
      "fill-rule": "evenodd"
    }, [
      _createElementVNode("path", { d: "M0 0h512v512H0z" }),
      _createElementVNode("path", {
        fill: "#4F4F4F",
        "fill-rule": "nonzero",
        d: "M509.061 287.75c8.297-14.222-1.961-32.082-18.427-32.082H135.111c-20.973 0-39.274 11.313-49.14 28.223l-64.376 110.36c-8.296 14.223 1.962 32.083 18.428 32.083h355.523c20.972 0 39.274-11.313 49.138-28.224zm-373.95-60.527h291.556v-42.667c0-23.564-19.103-42.666-42.667-42.666H241.778L184.888 85H42.668C19.102 85 0 104.104 0 127.669v247.151l61.402-105.26c15.233-26.114 43.477-42.336 73.71-42.336"
      })
    ], -1)
  ])))
}
export default { render: render }